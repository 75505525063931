<template>
<div class="container">
    <!-- Gün seçim kutuları -->
    <div class="container text-center p-3">
        <div class="btn-group mx-auto px-auto" role="group" aria-label="Basic checkbox toggle button group">
            <div class="btn-group" v-for="(day, index) in days1" :key="index">
                <input type="checkbox" class="btn-check" :id="day.id" autocomplete="off" v-model="day.checked">
                <label style="min-width: 96px;" class="btn btn-outline-primary" :for="day.id">{{ day.name }}</label>
            </div>
        </div>
    </div>

    <!-- Saat seçim kutusu -->
    <div class="example-basic text-center p-3">
        <el-time-select v-model="startTime" style="width: 240px" :max-time="endTime" class="mr-4" placeholder="Başlangıç Saati" start="05:00" step="00:15" end="23:45" />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-time-select v-model="endTime" style="width: 240px" :min-time="startTime" placeholder="Bitiş Saati" start="05:00" step="00:15" end="24:45" />
    </div>

    <!-- Çalışma saatleri tablosu -->
    <div class="row m-3">
        <div class="col-md-8 offset-md-2">
            <div class="card p-3">
                <div class="card-body">
                    <h5 class="card-title text-center mb-4">Çalışma Saatlerini Özelleştir</h5>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Gün</th>
                                <th>Başlangıç Saati</th>
                                <th>Bitiş Saati</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(day, index) in days1" :key="index">
                                <td>{{ day.name }}</td>
                                <td>{{ day.startTime }}</td>
                                <td>{{ day.endTime }}</td>
                            </tr>
                        </tbody>

                    </table>
                    <!-- Kaydet butonu -->
                    <div class="container text-center">
                      <el-button @click=saveCalismaSaati() type="warning" >Kaydet</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import { ElNotification } from "element-plus";

export default {
    name: "CalismaSaatleri",
    data() {
        return {
            token: null,
            companyId: null,
            userId: null,
            startTime: null,
            endTime: null,
            days1: [
                { id: 1, checked: false, name: "Pazartesi", startTime: null, endTime: null },
                { id: 2, checked: false, name: "Salı", startTime: null, endTime: null },
                { id: 3, checked: false, name: "Çarşamba", startTime: null, endTime: null },
                { id: 4, checked: false, name: "Perşembe", startTime: null, endTime: null },
                { id: 5, checked: false, name: "Cuma", startTime: null, endTime: null },
                { id: 6, checked: false, name: "Cumartesi", startTime: null, endTime: null },
                { id: 7, checked: false, name: "Pazar", startTime: null, endTime: null }
            ]
        };
    },
    watch: {
        startTime(newVal) {
            this.updateCalismaSaati(newVal, 'start');
        },
        endTime(newVal) {
            this.updateCalismaSaati(newVal, 'end');
        }

    },
    mounted() {
        this.companyId = this.$route.params.id;
        this.userId = localStorage.getItem('userId');
        this.token = localStorage.getItem('token');
        if (this.token == null || this.token == "") {
            this.$router.push('/login');
        }
        this.getCalismaSaati().then(() => {
          ElNotification({
            message: "Çalışma saatleri başarıyla getirildi.",
            type: "success",
            position: "top-right",
          });
        }).catch(error => {
            if (error.response.status == 401) {
                ElNotification({
                    title: "Hata",
                    message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                    type: "warning",
                    position: "top-right",
                });
                this.$router.push('/company/login');
            } else {
                console.error(error);
                ElNotification({
                    title: "Hata",
                    message: "Bir hata ile karşılaşıldı." + error.response.data.message,
                    type: "warning",
                    position: "top-right",
                });
            }
        });
    },

    methods: {
        updateCalismaSaati(newVal, type) {
            this.days1.forEach(day => {
                if (day.checked) {
                    if (type === 'start') {
                        day.startTime = newVal;
                    } else {
                        day.endTime = newVal;
                    }
                }
            });
        },
        async saveCalismaSaati() {
            const dataWorkingHours = this.days1.reduce((acc, day) => {
                if (day.startTime !== null || day.endTime !== null) {
                    acc.push({
                        dayId: day.id,
                        startTime: day.startTime,
                        endTime: day.endTime
                    });
                }
                return acc;
            }, []);

            const params = {
                companyId: this.companyId,
                companyWorkingHours: dataWorkingHours
            };

            try {
                const response = await axios.post("/company/workingHours/set", params, {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                });
                if (response.data.status == "success") {
                    ElNotification({
                        message: "Çalışma saatleri başarıyla güncellendi.",
                        type: "success",
                        position: "top-right",
                    });
                } else {
                    ElNotification({
                        title: "Hata",
                        message: "Çalışma saatleri güncellenirken bir hata oluştu." + response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
            } catch (error) {
                if (error.response.status == 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                } else {
                    console.error(error);
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı." + error.response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        },
        async getCalismaSaati() {
            try {
                const response = await axios.get(`/company/workingHours/list?companyId=${this.companyId}`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`
                    }
                });
                if (response.data.status == "success") {
                    this.days1.forEach(day => {
                        response.data.data.forEach(wh => {
                            console.log(wh)
                            if (wh.dayId === day.id) {
                                day.checked = true;
                                day.startTime = wh.openTime.slice(0, -3);
                                day.endTime = wh.closeTime.slice(0, -3);
                            }
                        });
                    });
                } else {
                    ElNotification({
                        title: "Hata",
                        message: "Çalışma saatleri getirilirken bir hata oluştu." + response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
            } catch (error) {
                if (error.response.status == 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                } else {
                    console.error(error);
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı." + error.response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
            }

        }

    }
};
</script>

<style scoped>
.btn:hover {
    background-color: #ff914d;
    color: white;
}
</style>
