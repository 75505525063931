<template>
  <div class="container mt-3 mb-3">
    <div class="row justify-content-center">
      <div class="col-md-11">
        <div class="card genelgenislik">
          <div class="card-body media align-items-center">
            <img
              v-if="userAvatar"
              :src="userAvatar"
              alt="Kullanıcı Avatarı"
              class="d-block rounded-circle ui-w-80"
            />
            <div v-else>
              <i class="fas fa-user-circle fa-5x text-muted"></i>
            </div>
            <div class="media-body ml-4">
              <div class="mb-3">
                <label for="fileInput" class="form-label">Profil Fotoğrafı</label>
                <div class="input-group">
                  <input
                    type="file"
                    class="form-control account-settings-fileinput"
                    id="fileInput"
                    @change="handleFileUpload"
                  />
                  <button type="button" class="btn btn-outline-primary" @click="selectFile">
                    Dosya Seç
                  </button>
                </div>
              </div>
              <small class="text-muted d-block mt-1">
                JPG, GIF veya PNG formatlarına izin verilir. Maksimum 800K boyutunda.
              </small>
            </div>
          </div>
          <hr class="border-light m-0" />
          <div class="card-body">
            <div class="mb-3">
              <label for="username" class="form-label">Kullanıcı Adı</label>
              <input
                type="text"
                class="form-control"
                id="username"
                value="nmaxwell"
              />
            </div>
            <div class="mb-3">
              <label for="name" class="form-label">Ad</label>
              <input
                type="text"
                class="form-control"
                id="name"
                value="Nelle Maxwell"
              />
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">E-posta</label>
              <input
                type="email"
                class="form-control"
                id="email"
                value="nmaxwell@mail.com"
              />
              <div class="alert alert-warning mt-3" role="alert">
                E-postanız onaylanmamış. Lütfen gelen kutunuzu kontrol edin.
                <a href="#" class="alert-link">Onay e-postası gönder</a>
              </div>
            </div>
            <div class="mb-3">
              <label for="company" class="form-label">Şirket</label>
              <input
                type="text"
                class="form-control"
                id="company"
                value="Şirket A.Ş."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyAyarlarGenel",
  data() {
    return {
      userAvatar: null
    };
  },
  methods: {
    selectFile() {
      const fileInput = document.getElementById('fileInput');
      fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.userAvatar = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }
  }
};
</script>

<style scoped>
.card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ui-w-80 {
  width: 80px !important;
  height: 80px;
}

.account-settings-fileinput {
  display: none;
}

.btn-outline-primary {
  cursor: pointer;
}

.alert-warning {
  margin-top: 1rem;
}
</style>
