<template>
  <div>
    <div >

    </div>
    <div @mouseover="collapsed=false" @mouseleave="collapsed=true" class="sidebar" :style="{ width: sidebarWidth }" >
        <div class="logo">
          <h1>
                <span v-if="collapsed" >
                <div class="R">R</div>
                <div class="B">B</div>
                </span>
                <span v-else> <span class="R">Randevu</span> <span class="B">Burada</span></span>
            </h1>
        </div>
        
        <div class="row">
            <SidebarLink to="/company" icon="fas fa-home">Anasayfa</SidebarLink>
            <SidebarLink to="/company/companies" icon="fas fa-columns">İşletmeler</SidebarLink>
            <SidebarLink to="/company/appointments" icon="fas fa-chart-bar">Randevular</SidebarLink>
            <SidebarLink to="/company/messages" icon="fas fa-paper-plane">Mesajlar</SidebarLink>
            <SidebarLink to="/company/services" icon="fas fa-business-time">Hizmetler</SidebarLink>
            <SidebarLink to="/company/staffs" icon="fas fa-users">Personeller</SidebarLink>
            <SidebarLink to="/company/comments" icon="fas fa-comments">Yorumlar</SidebarLink>
            <SidebarLink to="/company/reports" icon="fas fa-list">Raporlar</SidebarLink>
            <SidebarLink to="/company/settings" icon="fas fa-gear">Ayarlar</SidebarLink>
        </div>
      
  
      <span
        class="collapse-icon"
        :class="{ 'rotate-180': collapsed }"
        @click="toggleSidebar"
      >
        <i class="fas fa-angle-double-left" />
      </span>
    </div>
  </div>
    
  </template>

<script>
import SidebarLink from './SidebarLink'
import { collapsed, toggleSidebar, sidebarWidth } from './state'

export default {
  props: {},
  components: { SidebarLink },
  setup() {
    return { collapsed, toggleSidebar, sidebarWidth }
  }
}
</script>

<style>
    :root{
        --sidebar-bg-color: #ff914d;
        --sidebar-item-hover: #f1af35;
        --sidebar-item-active: #d42a13;
    }
</style>

<style scoped>

    .logo{
        background-color: #ff914d;   
    }
    .R{
        color: white; 
    }
    .B{
        color: #464a53;  
    }
    .sidebar{
      color: #464a53;
      background-color: var(--sidebar-bg-color);
      min-height: 100vh;

  float: left;
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0em;    
  transition: 0.5s ease;

  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100rem;
}

.sidebar h1 {
  height: 2.5em;
  
}

.collapse-icon {
  position: absolute;
  bottom: 0;
  padding: 0.75em;

  color: rgba(255, 255, 255, 0.7);

  transition: 0.2s linear;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
</style>

