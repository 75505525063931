<template>
  <div>
    <div
      class="bg-image"
      style="
        background-image: url('https://img.freepik.com/premium-vector/hairdressing-barbershop-tools-seamless-pattern-beauty-salon_341076-314.jpg');
        min-height: 100vh;
      "
    >
    <navbar-component v-if="token===null" />
    <navbar-customer v-if="token!==null"/>      
    <div class="container">
        <div
          class="container rounded rounded-4 shadow bg-body shadow-sm border"
        >
          <div class="bg-body">
            <div class="row row-grid">
              <div
                class="col-12 m-2"
                v-for="(business, index) in businesses"
                :key="index"
              >
                <div class="card mb-4">
                  <div
                    class="card-header d-flex justify-content-between align-items-center"
                  >
                    <h4 class="w-100 m-1">{{ business.name }}</h4>
                  </div>
                  <div class="card-body">
                    <!-- Carousel Başlangıç-->
                    <div
                      :id="'carouselExampleIndicators' + index"
                      class="carousel slide carousel-inner w-50"
                      data-bs-ride="carousel"
                    >
                      <ul class="carousel-indicators">
                        <li
                          :data-bs-target="'#carouselExampleIndicators' + index"
                          :data-bs-slide-to="0"
                          class="active nav"
                        ></li>
                        <li
                          :data-bs-target="'#carouselExampleIndicators' + index"
                          :data-bs-slide-to="1"
                          class="nav"
                        ></li>
                        <li
                          :data-bs-target="'#carouselExampleIndicators' + index"
                          :data-bs-slide-to="2"
                          class="nav"
                        ></li>
                      </ul>
                      <div class="carousel-inner">
                        <div
                          class="carousel-item"
                          v-for="(image, imgIndex) in business.images"
                          :class="{ active: imgIndex === 0 }"
                          :key="imgIndex"
                        >
                        
                          <div class="container">
                            <img
                              :src="image"
                              class="mx-auto d-block carousel-inner img-fluid"
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        class="carousel-control-prev"
                        type="button"
                        :data-bs-target="'#carouselExampleIndicators' + index"
                        data-bs-slide="prev"
                      >
                        <span
                          class="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button
                        class="carousel-control-next"
                        type="button"
                        :data-bs-target="'#carouselExampleIndicators' + index"
                        data-bs-slide="next"
                      >
                        <span
                          class="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                    <!-- Carousel Bitiş-->
                    <div class="container text-center">
                      <router-link :to="{ path: `create` }" class="button-63 mt-3">Randevu Al</router-link>
                    </div>
                    <div class="col-12 my-4">
                      <!-- SegmentedComponent'e id prop'unu geçir -->
                      <!-- <segmented-component :id="business.id" /> -->
                                            </div>
                                            <div class="col-12">
                      <div
                        class="list-group list-group-horizontal account-settings-links my-4"
                      >
                        <router-link
                          to=""
                          @click="goToPage('/appointments/' + id + '/isletmebilgileri')"
                          class="router-link-active router-link-exact-active list-group-item list-group-item-action "
                          :class="{ 'active': $route.path === '/appointments/' + id + '/isletmebilgileri' }"
                          data-bs-toggle="list"
                          aria-current="page"
                          >İşletme Bilgileri</router-link
                        ><router-link
                          to=""
                          @click="goToPage('/appointments/' + id + '/hizmetbilgileri')"
                          class="router-link-active router-link-exact-active list-group-item list-group-item-action "
                          :class="{ 'active': $route.path === '/appointments/' + id + '/hizmetbilgileri' }"
                          data-bs-toggle="list"
                          aria-current="page"
                          >Hizmet Bilgileri</router-link
                        ><router-link
                          to=""
                          @click="goToPage('/appointments/' + id + '/konumbilgileri')"
                          class="list-group-item list-group-item-action"
                          :class="{ 'active': $route.path === '/appointments/' + id + '/konumbilgileri' }"
                          data-bs-toggle="list"
                          >Konum Bilgileri</router-link
                        ><router-link
                          to=""
                          @click="goToPage('/appointments/' + id + '/personelbilgileri')"
                          class="list-group-item list-group-item-action"
                          :class="{ 'active': $route.path === '/appointments/' + id + '/personelbilgileri' }"
                          data-bs-toggle="list"
                          >Personel Bilgileri</router-link
                        ><router-link
                          to=""
                          @click="goToPage('/appointments/' + id + '/yorumlar')"
                          class="list-group-item list-group-item-action"
                          :class="{ 'active': $route.path === '/appointments/' + id + '/yorumlar' }"
                          data-bs-toggle="list"
                          >Yorumlar</router-link
                        >
                      </div>
                    </div>
                    <div class="col-md-12 mx-auto" style="min-height: 60vh">
                      <router-view class="us__content" />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {
  Shop,
  Scissor,
  Location,
  Avatar,
  Comment,
} from '@element-plus/icons-vue';
import NavbarCustomer from "@/components/NavbarCustomer.vue";
import NavbarComponent from '@/components/NavbarComponent.vue';
import { ElNotification } from 'element-plus';
import axios from 'axios';

export default {

  name: "BusinessPage",
  components: {
    NavbarCustomer,
    NavbarComponent
  },
  data() {
    return {
      options: [
      {
        label: 'İşletme Bilgileri',
        value: 'Shop',
        icon: Shop,
        path: 'isletmebilgileri'
      },
      {
        label: 'Hizmet Bilgileri',
        value: 'Scissor',
        icon: Scissor,
        path: 'hizmetbilgileri'
      },
      {
        label: 'Konum Bilgileri',
        value: 'Location',
        icon: Location,
        path: 'konumbilgileri'
      },
      {
        label: 'Personel Bilgileri',
        value: 'Avatar',
        icon: Avatar,
        path: 'personelbilgileri'
      },
      {
        label: 'Yorumlar',
        value: 'Comment',
        icon: Comment,
        path: 'yorumlar'
      },
    ],
      businesses: [
        {
          id: 1,
          name: "Erkek Kuaförü",
          description: "İşletme 1 açıklaması.",
          services: ["Hizmet 1", "Hizmet 2", "Hizmet 3"],
          images: [
            "https://via.placeholder.com/200x200",
            "https://via.placeholder.com/200x200",
            "https://via.placeholder.com/200x200",
          ],
        },
      ],
      id: null,
      token: null,
      userId: null,
    };
  },
  methods: {
    RandevuAl() {
      this.$router.push({ path: '/randevu/:id' });
    },
    goToPage(e) {
        this.$router.push({ path: e });
      },

      getImages(){
            if(this.id == null){
               ElNotification({
                title: "Hata",
                message: "Şirket bilgileri alınamadı",
                type: "error",
                position: "top-right",
               });
                return;
            }
            axios.get(`/company/media/get?companyId=` + this.id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
    
        }).then(response => {
          this.businesses[0].images =  [
          response.data.data[0].mediaUrl,
          response.data.data[1].mediaUrl,
          response.data.data[2].mediaUrl,
          ],
          console.log(this.businesses)
        }).catch(() => {
          ElNotification({
            title: "Hata",
            message: "Resimler yüklenirken bir hata oluştu",
            type: "error",
            position: "top-right",
          });
        });
},
  },
  mounted() {
    this.token = localStorage.getItem("token");
    this.userId = localStorage.getItem("userId");
    this.id = this.$route.params.id;
    this.getImages()
  },
};
</script>

<style scoped>
.bg-body {
  margin-right: 15px;
}
.list-group-item.active {
  background-color: #ff914d !important;
  background: #ff914d !important;
  border-color: gray;
  border-radius: 8px !important;
}

.carousel-inner {
  margin-left: auto;
  margin-right: auto;
}
.account-settings-links .list-group-item.active {
  background: #ff914d !important;
}
.el-segmented {
  --el-segmented-item-selected-bg-color: #ff914d;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
</style>
