import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import jQuery from 'jquery'
import '@fortawesome/fontawesome-free/js/all'
import { Vue3Geolocation } from 'vue3-geolocation'
import axios from 'axios'
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import 'primevue/resources/themes/aura-light-green/theme.css'

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';



const app = createApp(App)

axios.defaults.baseURL = 'https://backend.randevuburada.com/api/v1'; // API'nizin gerçek URL'sini buraya ekleyin


app.use(ElementPlus)
app.use(router)
app.use(BootstrapIconsPlugin);
app.use(jQuery)
app.use(Vue3Geolocation)
app.use(PrimeVue)
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('InputText', InputText);
app.component('Button', Button);
app.component('Tag', Tag);

app.mount('#app')

