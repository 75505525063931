<template>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="card-body">
                    <form @submit.prevent="submitForm" id="hizmetFormu">
                        <div class="mb-3">
                            <label for="mainService" class="form-label">Ana Hizmet <span class="text-danger">*</span></label>
                            <el-select v-model="selectedMainService" filterable placeholder="Lütfen bir hizmet katagorisi seçiniz..." style="min-height: 25px;" :no-data-text="'Veri bulunamadı'">
                                <el-option v-for="item in mainServices" :key="item.id" :label="item.serviceName" :value="item.id" />
                            </el-select>
                        </div>
                        <div class="mb-3">
                            <label for="hizmetAdi" class="form-label">Hizmet Adı <span class="text-danger">*</span></label>
                            <el-input v-model="serviceName" placeholder="Hizmetin Adı" required></el-input>
                        </div>
                        <div class="mb-3">
                            <label for="gender" class="form-label">Cinsiyet <span class="text-danger">*</span></label>
                            <el-select v-model="selectedGender" filterable placeholder="Lütfen bir hizmet katagorisi seçiniz..." style="min-height: 25px;" :no-data-text="'Veri bulunamadı'">
                                <el-option v-for="item in genders" :key="item.id" :label="item.genderName" :value="item.id" />
                            </el-select>
                        </div>
                        <div class="mb-3">
                            <label for="gender" class="form-label">Hizmet Süresi <span class="text-danger">*</span></label>
                            <el-select v-model="selectedIntervalHours" filterable placeholder="Lütfen bir hizmet katagorisi seçiniz..." style="min-height: 25px;" :no-data-text="'Veri bulunamadı'">
                                <el-option v-for="item in intervalHours" :key="item.id" :label="item.intervalTime.slice(0, -3)" :value="item.id" />
                            </el-select>
                        </div>

                        <div class="mb-3">
                            <label for="hizmetFiyati" class="form-label">Hizmet Fiyatı (TL) <span class="text-danger">*</span></label>
                            <el-input v-model="servicePrice" placeholder="Hizmetin Fiyatı" required type="number" min="0"></el-input>
                        </div>

                        <div class="mb-3">
                            <label for="hizmetDetaylari" class="form-label">Hizmet Detayları <span class="text-danger">*</span></label>
                            <el-input type="textarea" v-model="serviceDetails" placeholder="Hizmetin Detayları" rows="3" required></el-input>
                        </div>

                        <div class="mb-3">
                            <label for="hizmetPersonelleri" class="form-label">Hizmetle İlgilenen Personeller <span class="text-danger">*</span></label>
                            <el-select v-model="selectedStaff" multiple placeholder="Personel Seçiniz" filterable required>
                                <el-option v-for="staff in staffList" :key="staff.id" :label="staff.firstName + ' ' + staff.lastName" :value="staff.id" />
                            </el-select>
                        </div>

                        <div class="text-center">
                            <button type="submit" class="btn btn-primary">Gönder</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ElSelect, ElOption, ElInput, ElNotification } from 'element-plus';
import axios from 'axios';

export default {
    name: 'HizmetEkle',
    components: {
        ElSelect,
        ElOption,
        ElInput,

    },
    data() {
        return {
            companyId: '',
            token: '',
            serviceName: '',
            servicePrice: '',
            serviceDetails: '',
            selectedStaff: [],
            selectedIntervalHours: '',
            selectedGender: '',
            selectedMainService: '',
            mainServices: [],
            genders: [],
            intervalHours: [],
            staffList: [],
        };
    },
    mounted() {
        this.token = localStorage.getItem('token');
        this.companyId = localStorage.getItem('companyId');
        if (!this.token || !this.companyId) {
            ElNotification({
                title: "Hata",
                message: "Token alınamadı. Lütfen tekrar giriş yapın.",
                type: "warning",
                position: "top-right",
            });
            this.$router.push('/company/login');
        }
        this.getMainServices();
        this.getGenders();
        this.getIntervalHours();
        this.getStaffs();
    },
    methods: {
        async getMainServices() {
            try {
                var response = await axios.get(`/company/service/main/list`);
                if (response.data.status !== "success") {
                    console.log(response)
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
                this.mainServices = response.data.data;
            } catch (error) {
                if (error.response.status == 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                } else {
                    console.error(error);
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı." + error.response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        },
        async getGenders() {
            try {
                var response = await axios.get(`/gender/list`);
                if (response.data.status !== "success") {
                    console.log(response)
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
                this.genders = response.data.data;
            } catch (error) {
                if (error.response.status == 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                } else {
                    console.error(error);
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı." + error.response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        },
        async getStaffs() {
            try {
                const companyId = localStorage.getItem('companyId');
                const token = localStorage.getItem('token');
                if (!companyId || !token) {
                    ElNotification({
                        title: "Hata",
                        message: "Şirket bilgileri alınamadı. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                }
                var response = await axios.get(`/company/staff/list?companyId=` + companyId, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data.status !== "success") {
                    console.log(response)
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
                this.staffList = response.data.data;
            } catch (error) {
                if (error.response.status == 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                } else {
                    console.error(error);
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı." + error.response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        },
        async getIntervalHours() {
            try {
                var response = await axios.get(`/company/service/interval-hours/list`);
                if (response.data.status !== "success") {
                    console.log(response)
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
                this.intervalHours = response.data.data;
            } catch (error) {
                if (error.response.status == 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                } else {
                    console.error(error);
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı." + error.response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        },
        async saveService() {
            const serviceData = {
                companyId: this.companyId,
                mainServiceId: this.selectedMainService,
                serviceName: this.serviceName,
                serviceDescription: this.serviceDetails,
                price: this.servicePrice,
                genderId: this.selectedGender,
                serviceIntervalHoursId: this.selectedIntervalHours,
                companyStaffIds: this.selectedStaff,
            };

            console.log(serviceData);
            const token = this.token;
            if (!token) {
                ElNotification({
                    title: "Hata",
                    message: "Token alınamadı. Lütfen tekrar giriş yapın.",
                    type: "warning",
                    position: "top-right",
                });
                this.$router.push('/company/login');
            }
            try {
                var response = await axios.post(`/company/service/set`, serviceData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data.status !== "success") {
                    console.log(response)
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                } else {
                    ElNotification({
                        title: "Başarılı",
                        message: response.data.message,
                        type: "success",
                        position: "top-right",
                    });
                    this.$router.push('/company/staffs');
                }
            } catch (error) {
                if (error.response.status == 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                } else {
                    console.error(error);
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı.",
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        },
        async submitForm() {
            if (this.serviceName === '' || this.servicePrice === '' || this.serviceDetails === '' || this.selectedStaff.length === 0 || this.selectedIntervalHours === '' || this.selectedGender === '' || this.selectedMainService === '') {
                ElNotification({
                    title: 'Hata',
                    message: 'Lütfen tüm zorunlu alanları doldurunuz.',
                    type: 'error',
                    position: 'top-right',
                });
                return;
            }

            await this.saveService();
            this.$router.push('/company/services');
        },

    }
};
</script>

<style scoped>
.card {
    margin-top: 20px;
}

.text-center {
    text-align: center;
}

.text-danger {
    color: red;
}
</style>
