<template>
<div>
    <div class="bg-image" style="
        background-image: url('https://img.freepik.com/premium-vector/hairdressing-barbershop-tools-seamless-pattern-beauty-salon_341076-314.jpg');
        min-height: 100vh;
      ">
        <navbar-component v-if="token === null" />
        <navbar-customer v-if="token !== null" />
        <div class="row mx-5 pt-0">
            <div class="sidebar col-lg-2 bg-body rounded rounded-4 shadow bg-body shadow-sm border">
                <h1 class="border-bottom filter-header d-flex d-md-none p-3 mb-0 align-items-center">
                    <span class="mr-2 filter-close-btn"> X </span>
                    Filters
                    <span class="ml-auto text-uppercase">Reset Filters</span>
                </h1>
                <div class="sidebar__inner">
                    <div class="filter-body p-2">
                        <div>
                            <h2 class="border-bottom filter-title">Konum Seçenekleri</h2>
                            <div class="mb-30 filter-options">
                                <el-button type="danger" @click="resetData">Sıfırla</el-button>
                                <el-button type="success" @click="getCompany">Filtrele</el-button>
                            </div>
                            <h2 class="border-bottom filter-title">Konum Seçenekleri</h2>
                            <div class="mb-30 filter-options">
                                <label for="city">Şehir</label>
                                <el-select v-model="selectedCity" filterable placeholder="Lütfen bir il seçiniz..." style="min-height: 25px;" :no-data-text="'Veri bulunamadı'">
                                    <el-option v-for="item in city" :key="item.id" :label="item.cityName" :value="item.id" />
                                </el-select>
                            </div>
                            <div class="mb-30 filter-options">
                                <label for="city">İlçe</label>
                                <el-select v-model="selectedDistrict" filterable placeholder="Lütfen bir ilçe seçiniz..." style="min-height: 25px;" :no-data-text="'Veri bulunamadı'">
                                    <el-option v-for="item in districts" :key="item.id" :label="item.districtName" :value="item.id" />
                                </el-select>
                            </div>
                            <h2 class="border-bottom filter-title">Engelli Bireylere Uygunluk</h2>
                            <div class="mb-30 filter-options">
                                <div class="custom-control custom-checkbox mb-3">
                                    <el-radio v-model="engellihizmet" :label="true">Evet</el-radio>
                                </div>
                                <div class="custom-control custom-checkbox mb-3">
                                    <el-radio v-model="engellihizmet" :label="false">Hayır</el-radio>
                                </div>
                            </div>
                            <!--seating option end-->
                            <h2 class="font-xbold body-font border-bottom filter-title">
                                İşletme Türleri
                            </h2>

                            <div v-for="(tur, index) in isletmeTurleri" :key="index">
                                <div class="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" class="custom-control-input" :id="tur.id" v-model="selectedTurler" :value="tur.id" />
                                    <label class="custom-control-label m-1" :for="tur.id">{{ tur.companyTypeName }}</label>
                                </div>
                            </div>
                        </div>

                        <div class="d-grid ">
                            <h2 class="font-xbold body-font border-bottom filter-title">
                                Değerlendirilme Puanları
                            </h2>
                            <div class="mb-3 filter-options container ">
                                <fieldset class="rating">
                                    <input type="radio" id="star5" name="rating" value="5" />
                                    <label for="star5">5 stars</label>
                                    <input type="radio" id="star4" name="rating" value="4" />
                                    <label for="star4">4 stars</label>
                                    <input type="radio" id="star3" name="rating" value="3" />
                                    <label for="star3">3 stars</label>
                                    <input type="radio" id="star2" name="rating" value="2" />
                                    <label for="star2">2 stars</label>
                                    <input type="radio" id="star1" name="rating" value="1" />
                                    <label for="star1">1 star</label>

                                </fieldset>
                            </div>
                        </div>

                        <h2 class="font-xbold body-font border-bottom filter-title mt-2">
                            Konuma Göre
                        </h2>
                        <div class="mb-3 filter-options">
                            <div class="custom-control custom-checkbox mb-3">
                                <input type="checkbox" class="custom-control-input" id="konumYakinlik" />
                                <label class="custom-control-label m-1" for="konumYakinlik">Konuma Yakınlığına Göre</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" container col-lg-10 rounded rounded-4 shadow bg-body shadow-sm border">
                <div class="bg-body">
                    <div class="overlay" style="display: none"></div>
                    <div class="search-section">
                        <div class="container-fluid container-xl">
                            <div class="row main-content ml-md-0">
                                <div class="content col-md-12">
                                    <div class="d-flex justify-content-between border-bottom align-items-center">
                                        <h2 class="title"></h2>
                                        <div class="filters-actions">
                                            <div class="form-floating">
                                                <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                                    <option selected>En İyiler</option>
                                                    <option value="1">En Uygun Fiyatlılar</option>
                                                    <option value="2">En Pahalılar</option>
                                                    <option value="3">Editörün Tercihi</option>
                                                </select>
                                                <label for="floatingSelect">Sıralama Ölçütü</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-grid">
                                        <div class="col-12 m-2" v-for="(business, index) in businesses" :key="index">
                                            <div class="card mb-4">
                                                <div class="card-header d-flex justify-content-between align-items-center">
                                                    <h4>{{ business.companyName }}</h4>
                                                </div>
                                                <div class="card-body d-flex">
                                                    <img :src="business.companyLogo" class="d-block" style="width: 200px; height: 200px" alt="..." />

                                                    <div class="container w-75">
                                                        <h5 class="h5 m-2 p-2">
                                                            {{ business.companyName }}
                                                        </h5>
                                                        <p class="px-4">
                                                            <span v-if="business.isDisabledAccessiblity" class="accessibility-badge">
                                                                <i class="fas fa-wheelchair"></i> Engelli Bireylere Uygun
                                                            </span>
                                                      
                                                        </p>
                                                        <p class="px-4"> 
                                                            <span v-if="business.phoneNumber" class="accessibility-badge">
                                                                <i class="fas fa-phone"></i> {{ business.phoneNumber }}
                                                            </span>
                                                        </p>
                                                        <p class="px-4"> 
                                                            <span v-if="business.companyAdress" class="accessibility-badge">
                                                                <i class="fas fa-home"></i> {{ business.companyAdress }}
                                                            </span>
                                                        </p>
                                                        <button class="button-63 float-end" @click="goToBusinessPage(business.id)">
                                                            İşletme Sayfası
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import NavbarCustomer from "@/components/NavbarCustomer.vue";
import NavbarComponent from "@/components/NavbarComponent.vue";
import axios from "axios";
import { ElNotification, ElSelect } from "element-plus";

export default {
    name: "ShopsView",
    components: {
        ElSelect,
        NavbarCustomer,
        NavbarComponent
    },
    data() {
        return {
            page: 'isletmebilgileri',
            businesses: [{
                    id: 1,
                    name: "İşletme 1",
                    description: "İşletme 1 açıklaması.",
                    services: ["Hizmet 1", "Hizmet 2", "Hizmet 3"],
                    images: [
                        "https://via.placeholder.com/200x200",
                        "https://via.placeholder.com/200x200",
                        "https://via.placeholder.com/200x200",
                    ],
                },
                {
                    id: 2,
                    name: "İşletme 2",
                    description: "İşletme 2 açıklaması.",
                    services: ["Hizmet 1", "Hizmet 2", "Hizmet 3"],
                    images: [
                        "https://via.placeholder.com/200x200",
                        "https://via.placeholder.com/200x200",
                        "https://via.placeholder.com/200x200",
                    ],
                },
                {
                    id: 2,
                    name: "İşletme 3",
                    description: "İşletme 3 açıklaması.",
                    services: ["Hizmet 1", "Hizmet 2", "Hizmet 3"],
                    images: [
                        "https://via.placeholder.com/200x200",
                        "https://via.placeholder.com/200x200",
                        "https://via.placeholder.com/200x200",
                    ],
                },
            ],
            isletmeTurleri: [],
            selectedTurler: [],
            city: [],
            selectedCity: 0,
            districts: [],
            selectedDistrict: 0,
            engellihizmet: true,
            token: null,
            userId: null,
        };
    },
    watch: {
        selectedCity: function (val) {
            this.getDistricts(val);
        },
    },
    mounted() {
        this.token = localStorage.getItem("token")
        this.userId = localStorage.getItem("userId")
        const selectedCity = localStorage.getItem("cityId");
        console.log(selectedCity);
        const selectedDistrict = localStorage.getItem("districtId");
        if (selectedCity != null && selectedDistrict != null) {
            this.selectedCity = selectedCity;
            this.getDistricts(selectedCity);
            this.selectedDistrict = selectedDistrict;
        }
        this.isletmeTurleriGetir();
        this.getCity();
        this.getCompany();
    },
    methods: {

        async isletmeTurleriGetir() {
            try {
                var response = await axios.get(`/company/type/get`);
                if (response.data.status !== "success") {
                    console.log(response);
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
                this.isletmeTurleri = response.data.data;
            } catch (error) {
                console.error(error);
                ElNotification({
                    title: "Hata",
                    message: "Bir hata ile karşılaşıldı.",
                    type: "warning",
                    position: "top-right",
                });
            }
        },
        async getCity() {
            try {
                var response = await axios.get(`/city/list`);
                if (response.data.status !== "success") {
                    console.log(response)
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                } else {
                    this.city = response.data.data
                }
            } catch (error) {
                if (error.response.status == 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login')
                } else {
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı.",
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        },
        async getDistricts(cityId) {
            try {
                const response = await axios.get(`/district/list`, {
                    params: {
                        cityId: cityId,
                    },
                });
                if (response.data.status !== "success") {
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                } else {
                    this.districts = response.data.data.districts;
                }
            } catch (error) {
                if (error.response.status === 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                } else {
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı.",
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        },
        goToBusinessPage(id) {
            this.$router.push({ path: `/appointments/${id}/isletmebilgileri` });
        },
        async getCompany() {
            try {
                const keysTurler = Array.isArray(this.selectedTurler) ?
                    this.selectedTurler.map((_, index) => index + 1) :
                    [];

                var selectedCity = this.selectedCity;
                var selectedDistrict = this.selectedDistrict;

                const postData = {
                    cityId: selectedCity,
                    districtId: selectedDistrict,
                    companyTypeIds: keysTurler,
                    isDisabledAccessiblity: this.engellihizmet
                }
                const response = await axios.post(`/company/list/byFilter`, postData);

                if (response.data.status !== "success") {
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                } else {

                    this.businesses = response.data.data;
                }
            } catch (error) {
                if (error.response.status === 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                } else {
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı.",
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        },
        resetData() {
            localStorage.removeItem("cityId");
            localStorage.removeItem("districtId");
            this.selectedCity = 0;
            this.selectedDistrict = 0;
            this.selectedTurler = [];
            this.engellihizmet = true;
            this.getCompany();
        }
    },
};
</script>

<style scoped>
.card-body {
    display: flex;
    align-items: center;
}

.carousel-inner {
    width: 200px;
    height: 200px;
}

.content {
    padding: 4px;
}

.arkaplan {
    background: linear-gradient(to bottom right, #eb3c33, #ea9c16);
}

body {
    font-size: 14px;
}

span {
    font-size: 14px;
}

.overlay {
    background: rgb(0 0 0 / 55%);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1030;
}

.search-section {
    padding: 8px 0;
}

.filter-header {
    font-weight: bold;
    font-size: 30px;
}

.filter-title {
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 14px;
    margin-bottom: 15px;
}

.title {
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 0;
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.row-grid img {
    margin-bottom: 30px;
    max-width: 100%;
}

@media (max-width: 767.98px) {
    .filters-actions {
        position: fixed;
        background: #fff;
        display: flex;
        justify-content: center;
        border: 0;
        bottom: 0;
        z-index: 1031;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0px -2px 3px rgb(0 0 0 / 21%);
        -webkit-box-shadow: 0px -2px 3px rgb(0 0 0 / 21%);
        -moz-box-shadow: 0px -2px 3px rgb(0 0 0 / 21%);
        height: 50px;
    }

    .filters-actions>div {
        flex: 1;
        text-align: center;
    }

    .filters-actions>div:first-of-type {
        border-right: 1px solid #d6d1ce;
    }

    .filters-actions>div>* {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    /*.no-border-select.sort-select + .select2-container--default .select2-selection--single{
      background-image: none;
      padding:10px;
    }*/
    .filter-btn,
    .filter-btn:hover,
    .filter-btn:focus,
    .filter-btn:active {
        padding: 14px 20px;
        height: 50px;
        border: 0;
        position: relative;
        z-index: 1;
        background: #fff;
        border-radius: 0;
    }

    .sort-drop .dropdown-menu {
        width: 100%;
        left: 0;
        position: fixed !important;
        transform: translateY(100%) !important;
        bottom: 50px !important;
        top: auto !important;
        text-align: center;
        border-radius: 6px 6px 0 0 !important;
        box-shadow: none !important;
        transition: 0.3s;
        display: block;
        /* border: 0;*/
        z-index: -11;
    }

    .sort-drop .dropdown-menu .dropdown-item {
        padding: 15px 20px !important;
    }

    .sort-drop .dropdown-menu .dropdown-item:first-child {
        border-radius: 6px 6px 0 0 !important;
    }

    .sort-drop.show .dropdown-menu {
        transform: translateY(0) !important;
    }

    .btn.sort-toggle {
        background-image: none;
        padding: 10px !important;
        width: 100%;
        border: 0;
        height: 50px;
        position: relative;
        z-index: 1;
        background: #fff;
        border-radius: 0;
        font-size: 16px;
        line-height: 22px;
    }

    .sidebar {
        position: fixed;
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -o-transform: translateY(100%);
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
        -o-transition: 0.3s;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;

        background: #fff;
    }

    .sidebar.open {
        z-index: 1032;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -o-transform: translateY(0);
    }

    .sidebar__inner {
        padding: 15px;
        height: calc(100% - 58px);
        overflow-y: auto;
    }

    .filter-header {
        height: 58px;
    }

    .filter-body {
        padding-right: 0;
    }
}

.rating {
    float: left;
    border: none;
}

.rating:not(:checked)>input {
    position: absolute;
    top: -9999px;
    clip: rect(0, 0, 0, 0);
}

.rating:not(:checked)>label {
    float: right;
    width: 1em;
    padding: 0 0.1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 150%;
    line-height: 1.2;
    color: #ddd;
}

.rating:not(:checked)>label:before {
    content: "★ ";
}

.rating>input:checked~label {
    color: #f70;
}

.rating:not(:checked)>label:hover,
.rating:not(:checked)>label:hover~label {
    color: gold;
}

.rating>input:checked+label:hover,
.rating>input:checked+label:hover~label,
.rating>input:checked~label:hover,
.rating>input:checked~label:hover~label,
.rating>label:hover~input:checked~label {
    color: #ea0;
}

.rating>label:active {
    position: relative;
}
</style>
