<template>
  <div class="container light-style flex-grow-1 container-p-y">

<h4 class="font-weight-bold py-3 mb-4">
</h4>

<div class="card card2 overflow-hidden" style="min-height: 700px">
  <div class="row no-gutters row-bordered row-border-light">
    <div class="col-md-3 pt-0">
      <div class="list-group list-group-flush account-settings-links">
        <router-link to="" class="list-group-item list-group-item-action" data-bs-toggle="list" @click="goToPage('/company/settings')" :class="{ 'active': $route.path === '/company/settings' }">Genel</router-link>
        <router-link to="" class="list-group-item list-group-item-action" data-bs-toggle="list" @click="goToPage('/company/settings/password')" :class="{ 'active': $route.path === '/company/settings/password' }" >Şifre Değiştirme</router-link>
        <router-link to="" class="list-group-item list-group-item-action" data-bs-toggle="list" @click="goToPage('/company/settings/info')" :class="{ 'active': $route.path === '/company/settings/info' }">Bilgi</router-link>
        <router-link to="" class="list-group-item list-group-item-action" data-bs-toggle="list" @click="goToPage('/company/settings/social-media')" :class="{ 'active': $route.path === '/company/settings/social-media' }">Sosyal Medya</router-link>
        <router-link to="" class="list-group-item list-group-item-action" data-bs-toggle="list" @click="goToPage('/company/settings/notifications')" :class="{ 'active': $route.path === '/company/settings/notifications' }">Bildirimler</router-link>
      </div>
    </div>
    <div class="col-md-9">
      <router-view class="us__content" />
    </div>
  </div>
</div>

<div class="text-center mt-3">
  <button type="button" class="button-63">Değişiklikleri Kaydet</button>&nbsp;
  <button type="button" class="button-62">İptal Et</button>
</div>

</div>
</template>

<script>
import '@/views/companyviews/add/css/CompanyAyarlar.css';

export default {
  name: "CompanyAyarlar",
  data() {
    return {
      
    };
  },
  
  methods: {
      goToPage(e) {
        this.$router.push({ path: e });
      },
  }
};
</script>

<style scoped>
.list-group-item.active {
  background-color: #ff914d !important;
  background: #ff914d !important;
  border-color: gray;
  border-radius: 8px !important;
  
}

</style>
