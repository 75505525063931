<template>
<div>
    <div class="bg-image" style="
        background-image: url('https://img.freepik.com/premium-vector/hairdressing-barbershop-tools-seamless-pattern-beauty-salon_341076-314.jpg');
        min-height: 100vh;
      ">
        <navbar-customer />
        <div class="container bg-body" style="min-height: 500px;">
            <div class="card d-flex justify-content-center">
                <Stepper v-model:activeStep="active">
                    <StepperPanel>
                        <template #header="{ index, clickCallback }">
                            <button class="btn btn-link p-0 d-flex flex-column gap-2" @click="clickCallback">
                                <span :class="['rounded-circle border-2 d-inline-flex align-items-center justify-content-center', { 'text-white': index <= active, 'border-secondary text-secondary': index > active }]" :style="{ backgroundColor: index <= active ? '#ff914d' : 'transparent', borderColor: index <= active ? '#ff914d' : 'currentColor' }" style="width: 3rem; height: 3rem;">
                                    <i class="pi pi-user" />
                                </span>
                            </button>
                        </template>
                        <template #content="{ nextCallback }">
                            <div class="d-flex flex-column gap-2 mx-auto" style="min-height: 16rem; max-width: 20rem">
                                <div class="text-center mt-3 mb-3 h4 font-weight-bold">Randevu Sahibinin Bilgileri</div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" style="height: 38px;"><i class="pi pi-user"></i></span>
                                        </div>
                                        <input id="input" v-model="name" type="text" class="form-control" placeholder="Ad" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" style="height: 38px;"><i class="pi pi-user"></i></span>
                                        </div>
                                        <input id="input" v-model="surname" type="text" class="form-control" placeholder="Soyad" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" style="height: 38px;"><i class="pi pi-mobile"></i></span>
                                        </div>
                                        <el-input size="default" :style="error.user_phone > 0 ? '--el-border-color: red' : ''" style="width: 279px;" v-model="user_phone" v-mask="'+90 (5##) ### ## ##'" placeholder="+90 (5##) ### ## ##" />
                                    </div>
                                </div>
                            
                            </div>
                            <div class="d-flex pt-4 justify-content-end">
                                <button class="button-63" @click="nextCallback">Sonraki Adım <i class="pi pi-arrow-right ml-2 p-2"></i></button>
                            </div>
                        </template>
                    </StepperPanel>
                    <StepperPanel>
                        <template #header="{ index, clickCallback }">
                            <button class="btn btn-link p-0 d-flex flex-column gap-2" @click="clickCallback">
                                <span :class="['rounded-circle border-2 d-inline-flex align-items-center justify-content-center', { 'text-white': index <= active, 'border-secondary text-secondary': index > active }]" :style="{ backgroundColor: index <= active ? '#ff914d' : 'transparent', borderColor: index <= active ? '#ff914d' : 'currentColor' }" style="width: 3rem; height: 3rem;">
                                    <i class="pi pi-star" />
                                </span>
                            </button>
                        </template>
                        <template #content="{ prevCallback }">
                            <div class="d-flex flex-column gap-2 mx-auto" style="min-height: 16rem; max-width: 24rem">
                                <div class="text-center mt-3 mb-3 h4 font-weight-bold">Hizmetleri Seçiniz</div>
                                <div class="d-flex flex-wrap justify-content-center gap-3">
                                    <div class="demo-date-picker" style="margin-top: -18px;">
                                        <div class="block">
                                            <span class="demonstration">Lütfen Randevu Gününüzü Seçiniz</span>
                                            <el-select v-model="selectedDate" placeholder="Randevu Günü" style="width: 100%;">
                                                <el-option v-for="item in currentDate" :key="item.date" :label="item.text" :value="item.date" :disabled="item.status === 'available' ? false : true"></el-option>
                                            </el-select>
                                        </div>

                                        <el-select v-model="selectedService" placeholder="Hizmet Seçiniz" style="width: 100%;">
                                            <el-option v-for="service in services" :key="service.id" :label="service.mainServiceName + ' => ' + service.serviceName" :value="service.id"></el-option>
                                        </el-select>

                                        <el-select v-model="selectedStaff" placeholder="Personel Seçiniz" style="width: 100%;">
                                            <el-option v-for="staff in staffs" :key="staff.id" :label="staff.firstName + ' ' + staff.lastName" :value="staff.id"></el-option>
                                        </el-select>

                                        <el-select v-model="selectedTime" placeholder="Personel Seçiniz" style="width: 100%;">
                                            <el-option v-for="time in times" :key="time.id" :label="time.time " :value="time.date"></el-option>
                                        </el-select>


                                    </div>
                                    

                                </div>
                            </div>
                            <div class="d-flex pt-4 justify-content-between">
                                <button class="button-62" @click="prevCallback"><i class="pi pi-arrow-left mr-2 p-2"></i>Önceki Adım</button>
                                <button class="button-63" @click="getCreateAppointments()">Sonraki Adım <i class="pi pi-arrow-right ml-2 p-2"></i></button>
                            </div>
                        </template>
                    </StepperPanel>
                    <StepperPanel>
                        <template #header="{ index, clickCallback }">
                            <button class="btn btn-link p-0 d-flex flex-column gap-2" @click="clickCallback">
                                <span :class="['rounded-circle border-2 d-inline-flex align-items-center justify-content-center', { 'text-white': index <= active, 'border-secondary text-secondary': index > active }]" :style="{ backgroundColor: index <= active ? '#ff914d' : 'transparent', borderColor: index <= active ? '#ff914d' : 'currentColor' }" style="width: 3rem; height: 3rem;">
                                    <i class="pi pi-id-card" />
                                </span>
                            </button>
                        </template>
                        <template #content="{ prevCallback }">
                            <div class="d-flex flex-column gap-2 mx-auto" style="min-height: 16rem; max-width: 24rem">
                                <div class="text-center mt-3 mb-3 h4 font-weight-bold">Account created successfully</div>
                                <div class="text-center">
                                    <img alt="logo" src="https://primefaces.org/cdn/primevue/images/stepper/content.svg" class="img-fluid" />
                                </div>
                            </div>
                            <div class="d-flex pt-4 justify-content-start">
                                <button class="button-62" @click="prevCallback"><i class="pi pi-arrow-left mr-2 p-2"></i>Önceki Adım</button>
                            </div>
                        </template>
                    </StepperPanel>
                </Stepper>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import NavbarCustomer from "@/components/NavbarCustomer.vue";
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import { ElInput, ElNotification } from "element-plus";
import { mask } from "vue-the-mask";

import axios from "axios";

export default {
    directives: { mask },
    name: "RandevuPage",
    components: {
        NavbarCustomer,
        Stepper,
        StepperPanel,
        ElInput,
    },
    data() {
        return {
            active: 0,
            name: '',
            surname: '',
            user_phone: "",
            tc: '',
            error: {
                user_name: 0,
                user_surname: 0,
                user_email: 0,
                user_phone: 0,
            },
            companyId: null,
            customerId: null,
            currentDate: [],
            selectedDate: null,
            services: [],            
            selectedService: null,
            staffs: [],
            selectedStaff: null,
            times: [],
            selectedTime: null,            
        };
    },
    watch: {
        selectedService(newService) {
            this.getServiceStaffs(newService);
        },
        selectedStaff() {
            this.getTimes();
        },
    },
    mounted() {
        this.companyId = this.$route.params.id;
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');
        if (token == null || userId == null) {
            ElNotification({
                title: 'Uyarı',
                message: 'Yetkisiz işlem tespit edildi. Lütfen giriş yapınız.',
                type: 'warning'
            });
            this.$router.replace('/appointments/login')
        }
        if (this.companyId === null) {
            this.$router.replace('appointments')
        }
        this.getAvailableDates(token, this.companyId);
        this.getAppointmentsCustomerInfo(token, userId);
        this.getCompanyServices(this.companyId);
    },
    methods: {
        toggleInterest(index) {
            const interest = this.interests[index];
            const pos = this.selectedInterests.indexOf(interest);
            if (pos === -1) {
                this.selectedInterests.push(interest);
            } else {
                this.selectedInterests.splice(pos, 1);
            }
        },
        getAppointmentsCustomerInfo(token, userId) {
            axios.get(`customer/info/get/byUserId?userId=` + userId, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.name = response.data.data.firstName;
                        this.surname = response.data.data.lastName;
                        this.user_phone = response.data.data.phone;
                        this.customerId = response.data.data.id;
                    } else [
                        ElNotification({
                            title: 'Hata',
                            message: "Hata: " + response.data.message,
                            type: 'error'
                        })
                    ]
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        ElNotification({
                            title: 'Uyarı',
                            message: 'Yetkisiz işlem tespit edildi. Lütfen giriş yapınız.',
                            type: 'warning'
                        });
                        this.$router.replace('/appointments/login')
                    } else {
                        ElNotification({
                            title: 'Hata',
                            message: "Hata: " + error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },
        nextStep() {
            if (this.name === '' || this.surname === '' || this.user_phone === '' || this.gender === '') {
                ElNotification({
                    title: 'Uyarı',
                    message: 'Lütfen tüm alanları doldurunuz.',
                    type: 'warning'
                });
                if (this.name === '') {
                    this.error.user_name = 1;
                } else {
                    this.error.user_name = 0;
                }
                if (this.surname === '') {
                    this.error.user_surname = 1;
                } else {
                    this.error.user_surname = 0;
                }
                if (this.user_phone === '') {
                    this.error.user_phone = 1;
                } else {
                    this.error.user_phone = 0;
                }
            }
        },
        getAvailableDates(token, companyId) {
            axios.get(`customer/appointment/available/dates?companyId=` + companyId, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    if (response.data.status === "success") {
                        this.currentDate = response.data.data;
                    } else {
                        ElNotification({
                            title: "Hata",
                            message: response.data.message,
                            type: "error",
                            position: "top-right",
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı.",
                        type: "error",
                        position: "top-right",
                    });
                });
        },
        getCompanyServices(companyId){
            axios.get(`company/service/list?companyId=` + companyId)
            .then((response) => {
                if(response.data.status === "success"){
                    this.services = response.data.data;
                }else{
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "error",
                        position: "top-right",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                ElNotification({
                    title: "Hata",
                    message: "Bir hata ile karşılaşıldı.",
                    type: "error",
                    position: "top-right",
                });
            });
        },
        getServiceStaffs(serviceId){
            axios.get(`company/service/list/staff?serviceId=` + serviceId)
            .then((response) => {
                if(response.data.status === "success"){
                    this.staffs = response.data.data;
                }else{
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "error",
                        position: "top-right",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                ElNotification({
                    title: "Hata",
                    message: "Bir hata ile karşılaşıldı.",
                    type: "error",
                    position: "top-right",
                });
            });
        },
        getTimes(){
            const postData = {
                companyId: this.companyId,
                appointmentAvailables: [
                    {
                        serviceId: this.selectedService,
                        staffId: this.selectedStaff,
                    }
                ],
                appointmentDate: this.selectedDate,
            }
            axios.post(`customer/appointment/available/times`, postData)
            .then((response) => {
                if(response.data.status === "success"){
                    this.times = response.data.data[0].appointmentTimes;
                }else{
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "error",
                        position: "top-right",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                ElNotification({
                    title: "Hata",
                    message: "Bir hata ile karşılaşıldı.",
                    type: "error",
                    position: "top-right",
                });
            });
        },
        getCreateAppointments(){
            const postData = {
                companyId: this.companyId,
                customerId: this.customerId,
                appointments: [
                    {
                        serviceId: this.selectedService,
                        staffId: this.selectedStaff,
                        appointmentDate: this.selectedDate,
                    }
                ],
                paymentTypeId: 1,
            }
            axios.post(`customer/appointment/create`, postData)
            .then((response) => {
                if(response.data.status === "success"){
                    ElNotification({
                        title: "Başarılı",
                        message: response.data.message,
                        type: "success",
                        position: "top-right",
                    });
                    this.$router.replace('/appointments')
                }else{
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "error",
                        position: "top-right",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                ElNotification({
                    title: "Hata",
                    message: "Bir hata ile karşılaşıldı.",
                    type: "error",
                    position: "top-right",
                });
            });
        },
        
    },
    
    
};
</script>

<style scoped>
.bg-body {
    background-color: #f8f9fa;
}

.p-stepper {
    flex-basis: 50rem;
}

button.btn-link {
    text-decoration: none;
}

.button-62:not([disabled]):focus {
    box-shadow: 0 0 0.25rem rgba(255, 183, 100, 0.5),
        -0.125rem -0.125rem 1rem rgba(255, 166, 0, 0.5),
        0.125rem 0.125rem 1rem rgba(207, 35, 35, 0.5);
}

.button-62:not([disabled]):hover {
    box-shadow: 0 0 0.75rem rgba(255, 202, 132, 0.5),
        -0.125rem -0.125rem 1rem rgba(255, 209, 124, 0.5),
        0.125rem 0.125rem 1rem rgba(255, 146, 74, 0.5);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
