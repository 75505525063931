<template>
  <div>
    <navbar-component v-if="token===null" />
    <navbar-customer v-if="token!==null"/>
    
    <div
      class="bg-image"
      style="
        background-image: url('https://img.freepik.com/premium-vector/hairdressing-barbershop-tools-seamless-pattern-beauty-salon_341076-314.jpg');
        min-height: 100vh;
      "
    >
      <div class="container" style="min-height: 40px"></div>
      <div class="container rounded rounded-4 p-2 arkaplan">
        <div
          class="bg-body-tertiary rounded rounded-2 shadow-lg d-flex p-4"
          style="height: 100px"
        >
          <div style="height: 100px;" class="row flex-fill mt-1">
            <div class="col-6">
              <el-select
            v-model="selectedCity"
            filterable
            placeholder="Lütfen bir il seçiniz..."
            class="flex-item el-select__wrapper2"
            style="min-height: 25px"
            :no-data-text="'Veri bulunamadı'"
            size="large"
          >
            <el-option
              v-for="item in city"
              :key="item.id"
              :label="item.cityName"
              :value="item.id"
            />
          </el-select>
            </div>
            <div class="col-6">
              <el-select
            v-model="selectedDistrict"
            filterable
            placeholder="Lütfen bir ilçe seçiniz..."
            class="flex-item mx-2 el-select__wrapper2"
            :no-data-text="'Lütfen önce şehir seçiniz...'"
            size="large"
          >
            <el-option
              v-for="item in districts"
              :key="item.id"
              :label="item.districtName"
              :value="item.id"
            />
          </el-select>
            </div>
          
          </div>
          
          <a @click="getLocacation" class="svg" style="margin-top: 10px;margin-left: 4px;">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill=""
              class="bi bi-geo-alt"
              viewBox="0 0 16 16"
            >
              <path
                d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"
              />
              <path
                d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
              />
            </svg>
          </a>
          <router-link
            to="/appointments"
            class="btn btn-dark p-2 text-center align-self-center buton"
          >
          Keşfet
          </router-link>
        </div>
      </div>
      <div class="container" style="min-height: 40px"></div>
      <div
        class="container rounded rounded-5 arkaplan p-2"
        style="min-height: 200px"
      >
        <div class="container rounded rounded-5 bg-body p-5">
          <div class="container text-break">
            <div class="d-lg-flex container text-sm-center text-lg-start">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0CJsTJmfWZINVszP56TgQCTv0QSgS2r6NNg&s"
                class="rounded rounded-3 m-3 my-lg-5"
                style="width: 275px; height: 183px"
                alt=""
              />
              <div
                class="bg-body-tertiary rounded rounded-2 shadow-lg flex-fill p-5 m-3"
                style="min-height: 180px"
              >
                <h5 class="h5">Bizimle Çalışmak İster Misiniz?</h5>
                <p>
                  Binlerce yeni kullanıcıya ulaşabilmek ister misiniz? Sadece
                  kendi semtinizdekiler değil, artık çevre semtlerdeki binlerce
                  kullanıcı da işletmenizden randevu alabilecek. Hizmet vermek
                  istediğiniz kim varsa artık müşteriniz.
                </p>
              </div>
            </div>
          </div>
          <div class="container text-break">
            <div
              class="d-lg-flex container text-sm-center text-lg-start flex-row-reverse"
            >
              <img
                src="https://watermark.lovepik.com/photo/20211202/large/lovepik-female-hairdresser-picture_501431912.jpg"
                class="rounded rounded-3 m-3 my-lg-5 mt-4"
                style="width: 275px; height: 183px"
                alt=""
              />
              <div
                class="bg-body-tertiary rounded rounded-2 shadow-lg flex-fill p-5 m-3"
                style="min-height: 180px"
              >
                <h5 class="h5">İş Ortağımız Olun</h5>
                <p>
                  Artık işletmenizin dijital ortamda reklamını yapmak çok basit.
                  Binlerce müşteriye anında ulaşma şansını yakalamak artık çok
                  basit. Aşağıdaki bağlantıdan hemen işletmenizin kaydını yapın
                  ve fırsatlardan anında yararlanın.
                </p>
              </div>
            </div>
          </div>
          <div class="container text-center">
            <div class="rounded rounded-2 flex-fill mt-3 p-3 mx-auto">
              <router-link
                to="/company/register"
                type="button"
                class="btn mt-3 button-63"
                >Başlayalım</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="container" style="min-height: 40px;"></div> -->
      <!-- <div class="container rounded rounded-4 arkaplan p-3" style="min-height: 200px;"> -->
      <!-- <div class="container rounded rounded-4 bg-body p-5"> -->
      <!-- <HomeCarousel></HomeCarousel> -->
      <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import "@/assets/css/navbar.css";
import NavbarCustomer from "@/components/NavbarCustomer.vue";
import NavbarComponent from "@/components/NavbarComponent.vue";
import axios from "axios";
import { ElSelect, ElNotification } from "element-plus";

export default {
  name: "HomeView",
  components: {
    ElSelect,
    NavbarComponent,
    NavbarCustomer
  },
  data() {
    return {
      token: null,
      userId: null,
      location: null,
      gettingLocation: false,
      errorStr: null,
      city: [],
      selectedCity: null,
      districts: [],
      selectedDistrict: null,
      citiesAndDistricts: null,
    };
  },
  watch: {
    selectedCity(newCity) {
      localStorage.setItem("cityId", newCity);
      this.getDistricts(newCity);
    },
    selectedDistrict(newDistrict) {
      localStorage.setItem("districtId", newDistrict);
    },
  },
  methods: {
    getLocation() {
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available.";
        return;
      }
      this.gettingLocation = true;
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.gettingLocation = false;
          this.location = pos;
        },
        (err) => {
          this.gettingLocation = false;
          this.errorStr = err.message;
        }
      );
    },
    async getCity() {
      try {
        var response = await axios.get(`/city/list`);
        if (response.data.status !== "success") {
          console.log(response);
          ElNotification({
            title: "Hata",
            message: response.data.message,
            type: "warning",
            position: "top-right",
          });
        }
        this.city = response.data.data;
      } catch (error) {
        console.error(error);
        ElNotification({
          title: "Hata",
          message: "Bir hata ile karşılaşıldı.",
          type: "warning",
          position: "top-right",
        });
      }
    },
    async getDistricts(cityId) {
      console.log(cityId);
      try {
        var response = await axios.get(`/district/list?cityId=` + cityId);
        if (response.data.status !== "success") {
          ElNotification({
            title: "Hata",
            message: response.data.message,
            type: "warning",
            position: "top-right",
          });
        }
        this.districts = response.data.data.districts;
      } catch (error) {
        console.error(error);
        ElNotification({
          title: "Hata",
          message: "Bir hata ile karşılaşıldı.",
          type: "warning",
          position: "top-right",
        });
      }
    },
    setValue() {
      localStorage.setItem("cityId", this.selectedCity);
      localStorage.setItem("districtId", this.selectedDistrict);
    },
  },
  mounted() {
    this.token = localStorage.getItem("token");
    this.userId = localStorage.getItem("userId");
    this.getCity();
  },
};
</script>

<style scoped>
.el-select__wrapper2 {
  padding: 3px !important;
}

@import "vue-multiselect/dist/vue-multiselect.css";
.arkaplan {
  background: linear-gradient(to bottom right, #eb3c33, #ea9c16);
}
</style>
