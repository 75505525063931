<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body chat-window" ref="chatWindow">
            <div v-if="loading" class="text-center">
              <i class="fas fa-spinner fa-spin"></i>
              Mesajlar yükleniyor...
            </div>
            <div v-else class="messages">
              <div v-for="message in messages" :key="message.id" :class="{ 'message': true, 'sent': message.isCompanyMessage === true, 'received': message.isCustomerMessage === true }">
                <div class="message-username">{{ message.userName }}</div>
                <div class="message-content">{{ message.messageText }}</div>
                <div class="message-timestamp">{{ message.messageDateText }}</div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="input-group">
              <input v-model="newMessage" @keyup.enter="sendMessage" class="form-control" placeholder="Mesaj yazın" />
              <div class="input-group-append">
                <button @click="sendMessage" class="btn btn-primary">Gönder</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import { ElNotification } from 'element-plus';

export default {
    components: {
    },
    name: "MusterininMesajlari",
    data() {
        return {
            companyId: '',
            token: '',
            chatId: '',
            customerId: '',
            newMessage: '',
            messages: [],
            customer: [],
            loading: true 
        };
    },
    mounted() {
        this.loading = true; 
        const chatId = this.$route.params.id;
        const token = localStorage.getItem('token');
        const companyId = localStorage.getItem('companyId');
        this.companyId = companyId;
        this.chatId = chatId;
        if (!token || !companyId) {
            this.$router.push('company/login');
        }
        this.getMessages(companyId, chatId, token);
        this.$nextTick(() => {
            const chatWindow = this.$refs.chatWindow;
            chatWindow.scrollTop = chatWindow.scrollHeight;
        });
        setInterval(() => {
            this.getMessages(companyId, chatId, token);
        }, 30000);
    },
    methods: {
        async sendMessage() {
            try {
                const token = localStorage.getItem('token');
                const companyId = localStorage.getItem('companyId');
                const postData = {
                    companyId: companyId,
                    customerId: this.customerId,
                    messageText: this.newMessage
                };
                if (!token || !companyId) {
                    this.$router.push('company/login');
                }
                const response = await axios.post(`company/chat/message/send`, postData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                console.log(response)
                if (response['data']['status'] !== "success") {
                    ElNotification({
                        title: "Hata",
                        message: response['data']['message'],
                        type: "warning",
                        position: "top-right",
                    });
                } else {
                    this.$nextTick(() => {
                        const chatWindow = this.$refs.chatWindow;
                        chatWindow.scrollTop = chatWindow.scrollHeight;
                    });
                    ElNotification({
                        title: "Başarılı",
                        message: "Mesajınız başarıyla gönderildi.",
                        type: "success",
                        position: "top-right",
                    });
                    window.location.reload();
                }

            } catch (error) {

                if (error.response.status == 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Yetkisiz erişim.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                } else {

                    ElNotification({
                        title: "Hata",
                        message: error.response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        },
        async getMessages(companyId, chatId, token) {
            try {
                const response = await axios.get(`company/chat/message/list`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        companyId: companyId,
                        chatId: chatId
                    }
                });
                if (response.data.status !== "success") {
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                } else {
                    this.messages = response.data.data;
                    this.customerId = response.data.data[0].customerId;
                    this.loading = false; // Set loading to false after messages are fetched
                }

            } catch (error) {

                if (error.response.status == 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Yetkisiz erişim.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                } else {

                    ElNotification({
                        title: "Hata",
                        message: error.response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        }
    }
};
</script>

<style>
.chat-window {
    height: 600px;
    overflow-y: auto;
}

.messages {
    display: flex;
    flex-direction: column;
}

.message {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 5px;
    max-width: 70%;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
}

.sent {
    align-self: flex-end;
    background-color: #dcf8c6;
    text-align: right;
}

.received {
    align-self: flex-start;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
}

.message-username {
    font-weight: bold;
    margin-bottom: 5px;
}

.message-content {
    margin-bottom: 5px;
}

.message-timestamp {
    font-size: 0.85em;
    color: #999;
}

.input-group-append .btn {
    margin-left: 10px;
}
</style>
