<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submitForm" id="isletmeFormu">
              <div class="mb-3">
                <label for="isletmeAdi" class="form-label">İşletme Türü <span class="text-danger">*</span></label>
                <el-select
                    v-model="selectedCompanyType"
                    filterable
                    placeholder="Lütfen bir işletme türü seçiniz..."
                    style= "min-height: 25px;"
                    :no-data-text="'Veri bulunamadı'"
                  ><el-option
                  v-for="item in companyTypes"
                    :key="item.id"
                    :label="item.companyTypeName"
                    :value="item.id"
                  /></el-select>  
              </div>
              <div class="mb-3">
                <label for="isletmeAdi" class="form-label">İşletme Adı <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="isletmeAdi" v-model="company_name" placeholder="İşletmenizin Adı" required>
              </div>

              <div class="mb-3">
                <label for="isletmeAdi" class="form-label">Banka Bilgileri <span class="text-danger">*</span></label>
                <el-select
                    v-model="selectedBankInfo"
                    filterable
                    placeholder="Lütfen bir banka bilgisi seçiniz..."
                    style= "min-height: 25px;"
                    :no-data-text="'Veri bulunamadı'"
                  ><el-option
                    v-for="item in bankInfos"
                    :key="item.id"
                    :label="'Hesap Sahibi: ' + item.accountHolder + ' - Vergi Dairesi: ' + item.taxOffice + ' - Vergi Ofisi: ' + item.taxOffice"
                    :value="item.id"
                  /></el-select>  
              </div>
              <div class="mb-3">
                <label class="form-label">İşletme Profil Resmi</label><img  :src="imageUrl" alt="Logo" class="d-block rounded-circle ui-w-80">
                <input type="file" id="logo" class="form-control" name="logo" @change="handleFileUpload" accept="image/*">
                
              </div>

              <div class="mb-3">
                <label for="telefonNumarasi" class="form-label">İşletme Telefon Numarası <span class="text-danger">*</span></label>
                  <el-input
                    size="large"
                    v-model="company_phonenumber"
                    v-mask="'+90 (5##) ### ## ##'"
                    placeholder="+90 (5##) ### ## ##"
                  />
              </div>
              <div class="mb-3">
                
                <label for="webSitesi" class="form-label">Web Sitesi</label>              
              <el-input
                v-model="company_website"
                placeholder="İşletmenizin Web Sitesi adresini giriniz... (örn: randevuburada.com)"
              >
                <template #prepend>Https://</template>
              </el-input>
            </div>
            <div class="mb-3">
              <label class="mb-2">E-Posta Adresiniz<span class="text-danger">*</span></label>
              <el-input
                size="large"
                v-model="company_email"
                placeholder="ornek@mail.com"
                autocomplete="off"
              />
            </div>
            <div class="mb-3">
              <label class="mb-2">İşletmeniz engelli bireylere uygun mu ? <span class="text-danger">* </span></label>&nbsp;
              <el-radio v-model="engellihizmet" value="1">Evet</el-radio>
              <el-radio v-model="engellihizmet" value="0">Hayır</el-radio>
            </div>
              <div class="mb-3">
                <label for="adres" class="form-label">Adres <span class="text-danger">*</span></label>
                <textarea class="form-control" id="adres" v-model="company_adres" rows="3" placeholder="İşletmenizin Adresi" required></textarea>
              </div>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="il" class="form-label">İl <span class="text-danger">*</span></label>
                  <el-select
                    v-model="selectedCity"
                    filterable
                    placeholder="Lütfen bir il seçiniz..."
                    style= "min-height: 25px;"
                    :no-data-text="'Veri bulunamadı'"
                  ><el-option
                    v-for="item in city"
                    :key="item.id"
                    :label="item.cityName"
                    :value="item.id"
                  /></el-select>  
                </div>
                <div class="col-md-6 mb-3">
                  <label for="ilce" class="form-label">İlçe <span class="text-danger">*</span></label>
                  <el-select
                    v-model="selectedDistrict"
                    filterable
                    placeholder="Lütfen bir ilçe seçiniz..."
                    :no-data-text="'Lütfen önce şehir seçiniz...'"
                  >
                    <el-option
                      v-for="item in districts"
                      :key="item.id"
                      :label="item.districtName"
                      :value="item.id"
                    />
                  </el-select>
                </div>
              </div>          
              <div class="mb-3">
                <label for="adres" class="form-label">Konum <span class="text-danger">*</span></label>
              <GoogleMap
                api-key="AIzaSyDXHf026LDXzeprtLECEFrHdMRgtl7kvHY"
                style="width: 100%; height: 500px"
                :center="center"
                :zoom="15"
                @click="handleMapClick"
                >
                  <Marker :options="{ position: markerPosition  }" />
                </GoogleMap>
              </div>
              
              <div class="mb-3">
                <label for="adres" class="form-label">Hakkında <span class="text-danger">*</span></label>
                <textarea class="form-control" id="adres" v-model="company_about" rows="3" placeholder="Bu kısımda işletmeniz hakkında açıklamalarda bulunabilirsiniz..." required></textarea>
              </div>
            
              <div class="text-center">
                <button @click = "postCompany" type="submit" class="btn btn-primary">Gönder</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { GoogleMap, Marker } from 'vue3-google-map';
import { ElSelect, ElNotification, ElInput } from "element-plus";
import axios from "axios";
import { mask } from "vue-the-mask";
</script>

<script>
/* eslint-disable */
export default {
  directives: { mask },
  name: "CompanyIsletmem",
  components: {
    ElNotification,
    ElSelect,
    ElInput,
    GoogleMap,
    Marker,
  },
  data() {
    return {
      id: null,
      city: [], 
      selectedCity: null,
      districts: [],
      selectedDistrict: null,
      citiesAndDistricts: null,
      selectedCompanyType: null,
      companyTypes: [],
      selectedBankInfo: null,
      engellihizmet: "1",
      bankInfos: [],
      company_email: "",
      company_name: "",
      company_website: "",
      company_adres: "",
      company_phonenumber: "",
      company_about: "",
      company_logo: "",
      center: { lat: 37.420084740908976, lng: 31.84487848616927 }, // Harita merkezi
      markerPosition: { lat: 37.420084740908976, lng: 31.84487848616927 }, // İşaretçi konumu    
      imageUrl: null,
      selectedFile: null,
      imageShow: null
    };
  },

  watch: {
    selectedCity(newCity) {
      console.log(newCity)
      this.getDistricts(newCity);
    },
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      console.log(file)
      if (!file) return;
      this.createImageUrl(file);
      this.selectedFile = event.target.files[0];
    },
    createImageUrl(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl = reader.result;
      };
    },
    getCompanyBaseData(token){
      this.id = this.$route.params.id;
      try {
        axios.get(`/company/list/byCompanyId?companyId=` + this.id, 
          {
          headers: {
            Authorization: `Bearer ${token}`,
          }, 
        }
        ).then(response => {
          if(response.data.status !== "success"){
            console.log(response)
            ElNotification({
              title: "Hata",
              message: response.data.message,
              type: "warning",
              position: "top-right",
            });
          }
          this.company_name = response.data.data.companyName;
          this.company_phonenumber = response.data.data.phoneNumber;
          this.company_email = response.data.data.email;
          this.company_website = response.data.data.website;
          this.selectedCity = response.data.data.cityId;
          this.selectedDistrict = response.data.data.districtId;
          this.companyLogo = response.data.data.companyLogo;
          this.markerPosition = {
            lat: parseFloat(response.data.data.latitude),
            lng: parseFloat(response.data.data.longitude),
          };
          if(response.data.data.isDisabledAccessiblity == true){
            this.engellihizmet = "1";
          }else{
            this.engellihizmet = "0";
          }
          this.company_about = response.data.data.companyAbout;
          this.company_adres = response.data.data.companyAdress;
          this.selectedCompanyType = response.data.data.companyTypeId;
          this.selectedBankInfo = response.data.data.companyBankingDetailsId;
          this.imageUrl = response.data.data.companyLogo;

        });
      } catch (error) {
        if(error.response.status == 401){
          ElNotification({
            title: "Hata",
            message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
            type: "warning",
            position: "top-right",
          });
          this.$router.push('/company/login');
        }else{
          console.error(error);
        ElNotification({
          title: "Hata",
          message: "Bir hata ile karşılaşıldı.",
          type: "warning",
          position: "top-right",
        });
        }
      }
    },
    async getCompanyTypes(token){
      try {
        var response = await axios.get(`/company/type/get`, 
          {
          headers: {
            Authorization: `Bearer ${token}`,
          }, 
        }
        );
        if(response.data.status !== "success"){
          console.log(response)
          ElNotification({
            title: "Hata",
            message: response.data.message,
            type: "warning",
            position: "top-right",
          });
        }
        this.companyTypes = response.data.data;
      } catch (error) {
        if(error.response.status == 401){
          ElNotification({
            title: "Hata",
            message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
            type: "warning",
            position: "top-right",
          });
          this.$router.push('/company/login');
        }else{
          console.error(error);
        ElNotification({
          title: "Hata",
          message: "Bir hata ile karşılaşıldı.",
          type: "warning",
          position: "top-right",
        });
        }
      }
    },
    async getCity(){
      try {
        var response = await axios.get(`/city/list`);
        if(response.data.status !== "success"){
          console.log(response)
          ElNotification({
            title: "Hata",
            message: response.data.message,
            type: "warning",
            position: "top-right",
          });
        }
        this.city = response.data.data;
      } catch (error) {
        console.error(error);
        ElNotification({
          title: "Hata",
          message: "Bir hata ile karşılaşıldı.",
          type: "warning",
          position: "top-right",
        });
      }
    },
    async getDistricts(cityId) {
      console.log(cityId)
      try {
        var response = await axios.get(`/district/list?cityId=` + cityId);
        if(response.data.status !== "success"){
          ElNotification({
            title: "Hata",
            message: response.data.message,
            type: "warning",
            position: "top-right",
          });
        }
        this.districts = response.data.data.districts;
      } catch (error) {
        console.error(error);
        ElNotification({
          title: "Hata",
          message: "Bir hata ile karşılaşıldı.",
          type: "warning",
          position: "top-right",
        });
      }
    },
    async getBankInfo(userId, token){
      try {
        var response = await axios.get(`/company/bankingDetail/list/byUserId?userId=` + userId, 
          {
          headers: {
            Authorization: `Bearer ${token}`,
          }, 
        }
        );
        if(response.data.status !== "success"){
          console.log(response)
          ElNotification({
            title: "Hata",
            message: response.data.message,
            type: "warning",
            position: "top-right",
          });
        }
        this.bankInfos = response.data.data;
      } catch (error) {
        if(error.response.status == 401){
          ElNotification({
            title: "Hata",
            message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
            type: "warning",
            position: "top-right",
          });
          this.$router.push('/company/login');
        }else{
          console.error(error);
        ElNotification({
          title: "Hata",
          message: "Bir hata ile karşılaşıldı.",
          type: "warning",
          position: "top-right",
        });
        }
      }
    },
    async postCompany(){
      if(this.company_website == ""){
        this.website = "https://randevuburada.com"
      }
      if(this.engellihizmet == "1"){
        this.engellihizmet = true;
      }else{
        this.engellihizmet = false;
      }
      var token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('companyLogo', this.selectedFile);
      formData.append('companyName', this.company_name);
      formData.append('phoneNumber', this.company_phonenumber);
      formData.append('email', this.company_email);
      formData.append('website', 'https://' + this.company_website);
      formData.append('countryId', 1);
      formData.append('cityId', parseInt(this.selectedCity));
      formData.append('districtId', parseInt(this.selectedDistrict));
      formData.append('latitude', this.markerPosition.lat.toString());
      formData.append('longitude', this.markerPosition.lng.toString());
      formData.append('isDisabledAccessiblity', this.engellihizmet);
      formData.append('companyAbout', this.company_about);
      formData.append('companyAdress', this.company_adres);
      formData.append('companyTypeId', parseInt(this.selectedCompanyType));
      formData.append('companyBankingDetailsId', parseInt(this.selectedBankInfo));
      formData.append('userId', parseInt(localStorage.getItem('userId')));
      formData.append('Id', parseInt(this.id));
      formData.append('Files', this.selectedFile);

      console.log(formData)
      try {
        var response = await axios.post(`/company/update`, formData, 
          {
          headers: {
            Authorization: `Bearer ${token}`,
          }, 
        }
        );
        if(response.data.status !== "success"){
          console.log(response)
          ElNotification({
            title: "Hata",
            message: response.data.message,
            type: "warning",
            position: "top-right",
          });
        }else{
          ElNotification({
            title: "Başarılı",
            message: response.data.message,
            type: "success",
            position: "top-right",
          });
          //this.$router.push('/company/companies');
        }
      } catch (error) {
        if(error.response.status == 401){
          ElNotification({
            title: "Hata",
            message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
            type: "warning",
            position: "top-right",
          });
          this.$router.push('/company/login');
        }else{
          console.error(error);
        ElNotification({
          title: "Hata",
          message: "Bir hata ile karşılaşıldı.",
          type: "warning",
          position: "top-right",
        });
        }
      }
    },
    setValue() {
      this.$emit("input", {
        city: this.selectedCity,
        district: this.selectedDistrict,
        companyTypes: this.selectedCompanyType
      });
    },
    handleMapClick(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      console.log(this.markerPosition)
    },
  },
  mounted() {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    if(token == null || userId == null){
      ElNotification({
          title: "Hata",
          message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
          type: "warning",
          position: "top-right",
        });
      this.$router.push('/company/login');
    }
    
    this.getCompanyTypes(token);
    this.getBankInfo(userId, token);
    this.getCity();
    this.getCompanyBaseData(token);
    },

};
</script>

<style scoped>
.card input{
  display: inline-block;
}
#tiklayiniz{
    color: #ff914d;
    margin-left: 2px;
    cursor: pointer;
    transition: 0.4s;
    font-weight: bold;
}
#tiklayiniz:hover{
    opacity: 0.6;
}
.btn:hover{
  background-color: #ff914d;
  color: white;
}
.ui-w-80 {
  width: 80px !important;
  height: 80px;
  margin-bottom: 8px;
}
</style>
