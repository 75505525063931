<template>
  <div class="container">
      <div class="row justify-content-center">
          <div class="col-md-8">
              <div class="card shadow-sm">
                  <div class="card-body">
                      <form @submit.prevent="submitForm" id="personelFormu">
                          <!-- Çalışma Aktiflik Durumu -->
                          <div class="mb-3">
                              <label for="workingStatus" class="form-label">Çalışma Durumu: <span class="text-danger">*</span></label>
                              <el-select v-model="selectedWorkingStatus" filterable placeholder="Lütfen bir çalışma durumu seçiniz..." style="min-height: 25px;" :no-data-text="'Veri bulunamadı'">
                                  <el-option v-for="item in workingStatusList" :key="item.id" :label="item.statusName" :value="item.id" />
                              </el-select>
                          </div>
                          <div class="mb-3">
                              <label for="workingStatus" class="form-label">Pozisyon: <span class="text-danger">*</span></label>
                              <el-select v-model="selectedWorkingPosition" filterable placeholder="Lütfen bir çalışma pozisyonu seçiniz..." style="min-height: 25px;" :no-data-text="'Veri bulunamadı'">
                                  <el-option v-for="item in workingPositionList" :key="item.id" :label="item.positionName" :value="item.id" />
                              </el-select>
                          </div>
                          
                          <div class="mb-3">
                              <label for="firstName" class="form-label p-2">Adı: <span class="text-danger">*</span></label>
                              <el-input v-model="firstName" placeholder="Personelin Adı" required></el-input>
                          </div>
  
                          <div class="mb-3">
                              <label for="lastName" class="form-label p-2">Soyadı: <span class="text-danger">*</span></label>
                              <el-input v-model="lastName" placeholder="Personelin Soyadı" required></el-input>
                          </div>
  
                          <div class="mb-3">
                              <label for="phoneNumber" class="form-label p-2">Telefon Numarası: <span class="text-danger">*</span></label>
                              <el-input v-model="phoneNumber" v-mask="'+90 (5##) ### ## ##'" placeholder="+90 (5##) ### ## ##" required></el-input>
                          </div>
  
                          <div class="mb-3">
                              <label for="email" class="form-label p-2">E-posta Adresi: <span class="text-danger">*</span></label>
                              <el-input v-model="email" placeholder="ornek@mail.com" autocomplete="off" required></el-input>
                          </div>
  
                          <div class="mb-3">
                              <label for="tc" class="form-label p-2">TC Kimlik Numarası: <span class="text-danger">*</span></label>
                              <el-input v-model="tc" v-mask="'###########'" placeholder="Kimlik Numaranızı 11 haneli olacak şekilde giriniz." required></el-input>
                          </div>
                          <div class="mb-3">
                              <label for="birthDate" class="form-label p-2">Doğum Tarihi: <span class="text-danger">*</span></label>
                              <el-date-picker v-model="birthDate" type="date" placeholder="Doğum Tarihi" required></el-date-picker>
                          </div>
  
                          <div class="mb-3">
                              <label for="gender" class="form-label p-2">Cinsiyet: <span class="text-danger">*</span></label>
                              <el-radio-group v-model="gender">
                                  <el-radio class="ms-2 p-2" :label="true">Erkek</el-radio>
                                  <el-radio :label="false">Kadın</el-radio>
                              </el-radio-group>
                          </div>
  
                          <div class="text-center">
                              <el-button type="primary" native-type="submit">Gönder</el-button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
  </template>
  
  <script>
  import axios from "axios";
  import { mask } from "vue-the-mask";
  import { ElNotification, ElInput, ElDatePicker, ElRadioGroup, ElRadio, ElButton, ElSelect, ElOption } from "element-plus";
  
  export default {
      directives: { mask },
      name: 'PersonelEkle',
      components: {
          ElInput,
          ElDatePicker,
          ElRadioGroup,
          ElRadio,
          ElButton,
          ElSelect,
          ElOption,
      },
      data() {
          return {
              id: '',
              userId: '',
              token: '',
              companyId: '',
              firstName: '',
              lastName: '',
              phoneNumber: '',
              email: '',
              tc: '',
              birthDate: null,
              gender: null,
              profilResmi: '',
              workingStatusList: [],
              workingPositionList: [],
              selectedWorkingStatus: '',
              selectedWorkingPosition: ''
          };
      },
      mounted() {
          this.userId = localStorage.getItem('userId');
          this.token = localStorage.getItem('token');
          this.companyId = localStorage.getItem('companyId');
          this.id = this.$route.params.id;
          if (!this.userId || !this.token || !this.companyId) {
              ElNotification({
                  title: "Hata",
                  message: "Kullanıcı bilgileri alınamadı. Lütfen tekrar giriş yapın.",
                  type: "warning",
                  position: "top-right",
              });
              this.$router.push('/company/login');
          } else if (this.userId == null || this.token == null || this.companyId == null) {
              ElNotification({
                  title: "Hata",
                  message: "Kullanıcı bilgileri alınamadı. Lütfen tekrar giriş yapın.",
                  type: "warning",
                  position: "top-right",
              });
              this.$router.push('/company/login');
          }
          this.getWorkingStatus();
          this.getWorkingPosition();
      },
      methods: {
          async getWorkingStatus() {
              try {
                  var response = await axios.get(`/company/staff/workingStatus/list`);
                  if (response.data.status !== "success") {
                      console.log(response)
                      ElNotification({
                          title: "Hata",
                          message: response.data.message,
                          type: "warning",
                          position: "top-right",
                      });
                  }
                  this.workingStatusList = response.data.data;
              } catch (error) {
                  if (error.response.status == 401) {
                      ElNotification({
                          title: "Hata",
                          message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                          type: "warning",
                          position: "top-right",
                      });
                      this.$router.push('/company/login');
                  } else {
                      console.error(error);
                      ElNotification({
                          title: "Hata",
                          message: "Bir hata ile karşılaşıldı." + error.response.data.message,
                          type: "warning",
                          position: "top-right",
                      });
                  }
              }
          },
          async getWorkingPosition() {
              try {
                  var response = await axios.get(`/company/staff/workingPosition/list`);
                  if (response.data.status !== "success") {
                      console.log(response)
                      ElNotification({
                          title: "Hata",
                          message: response.data.message,
                          type: "warning",
                          position: "top-right",
                      });
                  }
                  this.workingPositionList = response.data.data;
                  this.getData();
              } catch (error) {
                  if (error.response.status == 401) {
                      ElNotification({
                          title: "Hata",
                          message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                          type: "warning",
                          position: "top-right",
                      });
                      this.$router.push('/company/login');
                  } else {
                      console.error(error);
                      ElNotification({
                          title: "Hata",
                          message: "Bir hata ile karşılaşıldı." + error.response.data.message,
                          type: "warning",
                          position: "top-right",
                      });
                  }
              }
          },
          async getData() {
              try {
                  const token = this.token;
                  if (!token) {
                      ElNotification({
                          title: "Hata",
                          message: "Token alınamadı. Lütfen tekrar giriş yapın.",
                          type: "warning",
                          position: "top-right",
                      });
                      this.$router.push('/company/login');
                  }
                  var response = await axios.get(`/company/staff/getById?id=` + this.id, {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                  });
                  if (response.data.status !== "success") {
                      console.log(response)
                      ElNotification({
                          title: "Hata",
                          message: response.data.message,
                          type: "warning",
                          position: "top-right",
                      });
                  }
                  this.firstName = response.data.data.firstName;
                  this.lastName = response.data.data.lastName;
                  this.phoneNumber = response.data.data.phoneNumber;
                  this.email = response.data.data.email;
                  this.tc = response.data.data.tc;
                  this.birthDate = response.data.data.birthDate;
                  this.selectedWorkingPosition = response.data.data.staffWorkingPositionId;
                  this.selectedWorkingStatus = response.data.data.staffWorkingStatusId;
                  this.gender = response.data.data.gender;
              }catch (error) {
                  if (error.response.status == 401) {
                      ElNotification({
                          title: "Hata",
                          message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                          type: "warning",
                          position: "top-right",
                      });
                      this.$router.push('/company/login');
                  } else {
                      console.error(error);
                      ElNotification({
                          title: "Hata",
                          message: "Bir hata ile karşılaşıldı." + error.response.data.message,
                          type: "warning",
                          position: "top-right",
                      });
                  }
              }
            },
          async saveStaff() {
              const staffData = {
                  id: this.id,
                  companyId: this.companyId,
                  staffWorkingPositionId: this.selectedWorkingPosition,
                  staffWorkingStatusId: this.selectedWorkingStatus,
                  profilPicture: "string",
                  firstName: this.firstName,
                  lastName: this.lastName,
                  phoneNumber: this.phoneNumber,
                  email: this.email,
                  tc: this.tc,
                  birthDate: this.birthDate,
                  gender: this.gender
              };
  
              console.log(staffData);
              const token = this.token;
              if (!token) {
                  ElNotification({
                      title: "Hata",
                      message: "Token alınamadı. Lütfen tekrar giriş yapın.",
                      type: "warning",
                      position: "top-right",
                  });
                  this.$router.push('/company/login');
              }
              try {
                  var response = await axios.post(`/company/staff/update`, staffData, {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                  });
                  if (response.data.status !== "success") {
                      console.log(response)
                      ElNotification({
                          title: "Hata",
                          message: response.data.message,
                          type: "warning",
                          position: "top-right",
                      });
                  } else {
                      ElNotification({
                          title: "Başarılı",
                          message: response.data.message,
                          type: "success",
                          position: "top-right",
                      });
                      this.$router.push('/company/staffs');
                  }
              } catch (error) {
                  if (error.response.status == 401) {
                      ElNotification({
                          title: "Hata",
                          message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                          type: "warning",
                          position: "top-right",
                      });
                      this.$router.push('/company/login');
                  } else {
                      console.error(error);
                      ElNotification({
                          title: "Hata",
                          message: "Bir hata ile karşılaşıldı.",
                          type: "warning",
                          position: "top-right",
                      });
                  }
              }
          },
          clearForm() {
              this.firstName = '';
              this.lastName = '';
              this.phoneNumber = '';
              this.email = '';
              this.tc = '';
              this.birthDate = null;
              this.gender = null;
              this.workingStatus = true;
              this.profilResmi = '';
              this.pozisyon = '';
          },
          async submitForm() {
              if (!this.firstName || !this.lastName || !this.birthDate || !this.gender) {
                  ElNotification({
                      title: 'Hata',
                      message: 'Lütfen tüm zorunlu alanları doldurunuz.',
                      type: 'error',
                      position: 'top-right',
                  });
                  return;
              }
  
              await this.saveStaff();
          },
          handleUploadSuccess(response, file) {
              this.profilResmi = URL.createObjectURL(file.raw);
          },
          beforeAvatarUpload(file) {
              const isJPG = file.type === 'image/jpeg';
              const isLt2M = file.size / 1024 / 1024 < 2;
  
              if (!isJPG) {
                  ElNotification.error('Sadece JPG formatlı dosyaları yükleyebilirsiniz.');
              }
              if (!isLt2M) {
                  ElNotification.error('Profil resmi en fazla 2MB olmalıdır.');
              }
              return isJPG && isLt2M;
          }
      }
  };
  </script>
  
  <style scoped>
  .btn:hover {
      background-color: #ff914d;
      color: white;
  }
  
  .card {
      margin-top: 20px;
  }
  
  .text-center {
      text-align: center;
  }
  
  .text-danger {
      color: red;
  }
  
  .el-button {
      margin-top: 15px;
  }
  
  .avatar-uploader {
      display: inline-block;
      text-align: center;
      font-size: 0;
  }
  
  .avatar-uploader .el-upload {
      display: inline-block;
      vertical-align: middle;
      width: 178px;
      height: 178px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      overflow: hidden;
  }
  
  .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      transition: color 0.3s;
  }
  
  .avatar {
      width: 178px;
      height: 178px;
      display: block;
  }
  </style>
  