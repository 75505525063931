<template>
  <div class="container">
    <!-- Yorumları Listeleme -->
    <div class="comments-section">
      <div v-for="comment in displayedComments" :key="comment.id" class="comment">
        <!-- Müşteri Avatarı -->
        <div class="card">
          <div class="card-header">
            <img :src="comment.avatar" alt="Avatar" class="avatar img-" />

            <!-- Müşteri Adı -->
            <h4 class="d-inline-block">{{ comment.name }}</h4>
          </div>

          <!-- Yorum Metni -->
          <div class="card-body">
            <p>{{ comment.text }}</p>
          </div>

          <div class="card-footer">
            <div class="rating">
              <i
                v-for="n in 5"
                :key="n"
                :class="['fa', 'fa-star', { checked: n <= comment.rating }]"
                aria-hidden="true"
              ></i
              ><label class="ms-2" for="">{{ comment.rating }}/5</label>
            </div>
          </div>

          <!-- Yıldız Değerlendirme -->
        </div>
      </div>
    </div>
    <div class="p-2">
      <Paginator :rows="3" :totalRecords="comments.length" @onPageChange="onPageChange"></Paginator>
    </div>
    <div class="container text-center">
      <button class="btn button-63" @click="yorumYap = !yorumYap">
        Yorum Penceresi
      </button>
    </div>
    
    <!-- Yorum Oluşturma Bölümü -->
    <div class="add-comment" v-if="yorumYap === true">
      <div class="card p-3">
        <form @submit.prevent="submitComment">
          <!-- Müşteri Adı -->
          <div class="form-group">
            <label class="p-2" for="name">Adınız:</label>
            <input
              type="text"
              class="form-control"
              v-model="newComment.name"
              id="name"
              required
            />
          </div>

          <!-- Yorum Metni -->
          <div class="form-group">
            <label for="comment">Yorumunuz:</label>
            <textarea
              class="form-control"
              v-model="newComment.text"
              id="comment"
              rows="4"
              required
            ></textarea>
          </div>

          <!-- Yıldız Değerlendirme -->
          <div class="form-group">
            <label for="rating">Değerlendirme:</label>
            <select
              v-model="newComment.rating"
              id="rating"
              required
              class="form-control"
            >
              <option value="1">1 Yıldız</option>
              <option value="2">2 Yıldız</option>
              <option value="3">3 Yıldız</option>
              <option value="4">4 Yıldız</option>
              <option value="5">5 Yıldız</option>
            </select>
          </div>

          <!-- Gönder Butonu -->
          <div class="container text-center">
            <button class="btn button-63 mx-auto" type="submit">Gönder</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Paginator from 'primevue/paginator';

export default {
  name: "YorumlarSayfasi",
  components: {
    Paginator
  },
  data() {
    return {
      yorumYap: false,
      comments: [
        {
          id: 1,
          name: "Ahmet",
          text: "Harika bir deneyimdi!",
          rating: 5,
          avatar: "https://randomuser.me/api/portraits/men/1.jpg",
        },
        {
          id: 2,
          name: "Ayşe",
          text: "Çok memnun kaldım, teşekkürler.",
          rating: 4,
          avatar: "https://randomuser.me/api/portraits/women/2.jpg",
        },
        // Diğer yorumlar buraya eklenebilir
      ],
      newComment: { name: "", text: "", rating: null },
      page: 1,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.comments.length / 3);
    },
    displayedComments() {
      const startIndex = (this.page - 1) * 3;
      const endIndex = startIndex + 3;
      return this.comments.slice(startIndex, endIndex);
    }
  },
  methods: {
    onPageChange(event) {
      this.page = event.page + 1;
    },
    submitComment() {
      if (
        this.newComment.name &&
        this.newComment.text &&
        this.newComment.rating
      ) {
        // Yeni yorumu listeye ekle
        this.comments.push({
          id: this.comments.length + 1,
          name: this.newComment.name,
          text: this.newComment.text,
          rating: parseInt(this.newComment.rating),
          avatar: `https://randomuser.me/api/portraits/${
            Math.random() < 0.5 ? "men" : "women"
          }/${Math.floor(Math.random() * 100)}.jpg`,
        });

        // Yorum gönderildikten sonra formu temizle
        this.newComment = { name: "", text: "", rating: null };
      } else {
        alert("Lütfen tüm alanları doldurun.");
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
}

.comment {
  margin-bottom: 20px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.rating {
  color: #ffc107; /* Yıldız rengi */
}

.rating .fa {
  font-size: 20px;
}

.checked {
  color: #ff9900; /* Seçili yıldız rengi */
}

.add-comment {
  margin-top: 40px;
}

.form-group {
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.button-63:not([disabled]):focus {
  box-shadow: none;
}
</style>