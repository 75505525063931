<template>
  <router-view/>
</template>

<style>
.p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
    background-color: #ff914d;
}
</style>

