<template>
   <div class="bg-image" style="background-image: url('https://img.freepik.com/premium-vector/hairdressing-barbershop-tools-seamless-pattern-beauty-salon_341076-314.jpg');min-height: 100%;">
  <div class="container">
    <div class="row">
      <div class="dashboard-panel">

      <div class="card card3 ">
        <div class="card-header card-header3 text-white">
            <h2>Bilgilerim</h2>
          </div>
          <div class="card-body3 p-4">
            <div class="container p-4">
              <table class="table">
                <tbody>
                  <tr>
                    <th scope="row" class="w-75">Kullanıcı Adı:</th>
                    <td>{{ user.firstName + ' ' + user.lastName }}</td>
                  </tr>
                  <tr>
                    <th scope="row">İşletme Paketi:</th>
                    <td>{{ packageName }}</td>
                  </tr>
                  <tr>
                    <th scope="row">Paketin Bitmesine Kalan Süre:</th>
                    <td colspan="2">{{ expirationDate }}</td>
                  </tr>
                </tbody>
               </table>
               <div class="row">
                <div class="col">
                  <div class="card mt-4 card3">
                    <div class="card-header card-header3 text-white text-center">
                      <h4>Günlük</h4>
                    </div>
                    <div class="card-body card-body3">
                      <div class="container">
                        <div class="row">
                          <div class="col ">
                            Kazancım:
                          </div>
                          <div class="col">
                            400
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            Randevu Sayısı:
                          </div>
                          <div class="col">
                            4
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card card3 mt-4">
                    <div class="card-header text-center card-header3 text-white">
                      <h4>Haftalık</h4>
                    </div>
                    <div class="card-body card-body3">
                      <div class="container">
                        <div class="row">
                          <div class="col ">
                            Kazancım:
                          </div>
                          <div class="col">
                            4.0000
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            Randevu Sayısı:
                          </div>
                          <div class="col">
                            40
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card card3 mt-4">
                    <div class="card-header text-center card-header3 text-white">
                      <h4>Aylık</h4>
                    </div>
                    <div class="card-body card-body3">
                      <div class="container">
                        <div class="row">
                          <div class="col ">
                            Kazancım:
                          </div>
                          <div class="col">
                            40.000
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            Randevu Sayısı:
                          </div>
                          <div class="col">
                            400
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="card mt-4 card3">
                    <div class="card-header card-header3 text-white text-center">
                      <h4>Yıllık</h4>
                    </div>
                    <div class="card-body card-body3">
                      <div class="container">
                        <div class="row">
                          <div class="col ">
                            Kazancım:
                          </div>
                          <div class="col">
                            400.000
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            Randevu Sayısı:
                          </div>
                          <div class="col">
                            4.000
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>


               </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>

<!-- asdasd-->

    
    <section class="content">
      <div class="row">
        <div class="col-lg-3 col-xs-6">
          <!-- small box -->
          <div class="small-box bg-aqua">
            <div class="inner">
              <h3>150</h3>
              <p>Yeni Randevular</p>
            </div>
            <div class="icon">
              <i class="ion ion-bag"></i>
            </div>
            <router-link to="/company/appointments" class="small-box-footer">Daha Fazlasını Göster <i class="fa fa-arrow-circle-right"></i></router-link>
          </div>
        </div><!-- ./col -->
        <div class="col-lg-3 col-xs-6">
          <!-- small box -->
          <div class="small-box bg-green">
            <div class="inner">
              <h3>4<sup style="font-size: 20px"></sup></h3>
              <p>İstatistikler</p>
            </div>
            <div class="icon">
              <i class="ion ion-stats-bars"></i>
            </div>
            <router-link to="/company/statistics" class="small-box-footer">Daha Fazlasını Göster <i class="fa fa-arrow-circle-right"></i></router-link>
          </div>
        </div><!-- ./col -->
        <div class="col-lg-3 col-xs-6">
          <!-- small box -->
          <div class="small-box bg-yellow">
            <div class="inner">
              <h3>44</h3>
              <p>Yeni Mesajlar</p>
            </div>
            <div class="icon">
              <i class="ion ion-chatbubbles"></i>
            </div>
            <router-link to="/company/messages" class="small-box-footer">Daha Fazlasını Göster <i class="fa fa-arrow-circle-right"></i></router-link>
          </div>
        </div><!-- ./col -->
        <div class="col-lg-3 col-xs-6">
          <!-- small box -->
          <div class="small-box bg-red">
            <div class="inner">
              <h3>Ayarlar</h3>
              <p>Firma Bilgilerimi Düzenle</p>
            </div>
            <div class="icon">
              <i class="ion ion-settings"></i>
            </div>
            <router-link to="/company/settings" class="small-box-footer">Daha Fazlasını Göster <i class="fa fa-arrow-circle-right"></i></router-link>
          </div>
        </div><!-- ./col -->
      </div>
      <div class="row">
        
      </div>
    </section>
    

  </div>
</div>
</template>


<script>
import axios from 'axios';
import { ElNotification } from "element-plus";

export default {
  name: "CompanyAnasayfa",
  data() {
    return {
      user: "null",
      responseData: "null",
      expirationDate: "30 gün",
      packageName: "Standart Paket",
    };
    
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.token = localStorage.getItem('token');
    this.userId = localStorage.getItem('userId');
    
    if(this.token == null || this.userId == null){
      this.$router.push('/company/login');
    }
    if (this.user) {
      this.fetchUserData(this.userId);
    }
  },
  methods: {
    async fetchUserData(userId) {
      try {
        const jwtToken = localStorage.getItem('token');
        const response = await axios.get(`/company/subscribe/get`, {
          headers: {
              'Authorization': `Bearer ${jwtToken}`,
              'Content-Type': 'application/json',
            },
          params: {
            userId: userId
          }
        });
        if(response.data.status !== "success"){
          ElNotification({
            title: "Hata",
            message: response.data.message,
            type: "warning",
            position: "top-right",
          });
        }
        this.responseData = response.data.data;
        const endDate = new Date(this.responseData.expirationDate);
        const now = new Date();
        const diffTime = Math.abs(endDate - now);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        this.expirationDate = Math.ceil(diffDays) + " gün";
        this.responseData = response.data.data;
        if(response.data.data.expirationDate < now){
          ElNotification({
            title: "Uyarı",
            message: "Paketinizin süresi dolmuştur. Lütfen paketinizi yenileyin.",
            type: "warning",
            position: "top-right",
          });
        }
        if(this.responseData.companyPackageId == 1){
          this.packageName = "Basic Paket";
        } else if(this.responseData.companyPackageId == 2){
          this.packageName = "Grow Paket";
        } else if(this.responseData.companyPackageId == 3){
          this.packageName = "Plus Paket";
        }
      } catch (error) {
        if(error.response.status == 401){
          ElNotification({
            title: "Hata",
            message: "Yetkisiz erişim.",
            type: "warning",
            position: "top-right",
          });
          localStorage.removeItem('token');
          localStorage.removeItem('userId');
          localStorage.removeItem('user');
          this.$router.push('/company/login');
        } else {
        ElNotification({
          title: "Hata",
          message: "Bir hata ile karşılaşıldı.",
          type: "warning",
          position: "top-right",
        });
      }
      }
    }
  }
};
</script>




<style scoped>
@import '../../companyviews/add/css/AdminLTE.css';

</style>
