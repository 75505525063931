<template>
  <div class="">
    <hizmetler-table/>
    
  </div>
</template>

<script>
import HizmetlerTable from '@/components/CustomerCompanyComponents/HİzmetlerTable.vue'

export default {
  name: "HizmetBilgileri",
  components: {
    HizmetlerTable
  },
  data() {
    return {
      
    };
  },
  methods: {},
};
</script>

<style scoped>

</style>
