<template>
  <div  class="row">
    <div class="table-responsive">
      <div
      class="container mt-5 mb-3"
    >
      <label
        for="isletmeAdi"
        class="col-1 col-form-label text-start font-weight-bold ms-0 float-start"
        style="min-width: 80px"
        >İşletmem:</label
      >
      <el-select
        size="large"
        v-model="selectedCompany"
        filterable
        placeholder="Lütfen bir işletme seçiniz..."
        style=""
        :no-data-text="'Veri bulunamadı'"
        class="float-start col-2"
      >
        <el-option
          v-for="item in companies"
          :key="item.id"
          :label="item.companyName"
          :value="item.id"
        />
      </el-select>
    </div>
    </div>
    
    <div class="col-lg-12">
      <div class="table-responsive">
        <div class="container mt-5">
          
          <DataTable :data="appointments" :columns="columns" class="display" :options="options" >
            <thead>
              <tr>
                <th class="text-center">ID</th>
                <th class="text-center" style="min-width: 170px;">Durum</th>
                <th class="text-center" style="min-width: 170px;">Müşteri</th>
                <th class="text-center" style="min-width: 170px;">Telefon Numarası</th>
                <th class="text-center" style="min-width: 170px;">Randevu Tarihi</th>
                <th class="text-center" style="min-width: 170px;">Ödeme Şekli</th>
                <th class="text-center" style="min-width: 120px;">Tutar</th>
                <th class="text-center" style="min-width: 220px;">İşlemler</th>
              </tr>
            </thead>
          </DataTable>
          
        </div>
      </div>
    </div>
  </div>
  <div>
    <el-dialog v-model="dialogFormVisible" title="Randevu İptal" width="500">
      <el-form :model="form">
        <el-form-item :label-width="formLabelWidth" prop="deleteMessage">
          İptal Açıklaması: <span style="color: red"> * </span>
          <el-input v-model="deleteMessage" autocomplete="off" required/>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false">İptal</el-button>
          <el-button type="primary" @click="randevuIptal()">Onayla</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <div>
    <el-dialog v-model="dialogTableVisible" title="Randevu Detayları" width="900">
    <el-table :data="appointmentDetail">
      <el-table-column property="id" label="ID" width="10" />
      <el-table-column property="serviceName" label="Hizmet Adı" width="200" />
      <el-table-column property="staffName" label="Personel Adı" width="200" />
      <el-table-column property="appointmentDateStart" label="Randevu Tarihi" width="200" />
      <el-table-column property="appointmentTime" label="Hizmet Süresi(dakika)" width="200" />
      <el-table-column property="staffName" label="Müşteri" width="200" />
      <el-table-column property="price" label="Ücret" width="200" />
    </el-table>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogTableVisible = false">İptal</el-button>
        <el-button type="primary" @click="randevuOnay()">
          Randevuyu Onayla
        </el-button>
      </div>
    </template>
  </el-dialog>

  </div>
</template>
<script setup>
import '@/assets/js/jquery-global.js';

</script>


<script>
import axios from 'axios'
import DataTable from 'datatables.net-vue3';
import DataTableLib from 'datatables.net-bs5';
import Buttons from 'datatables.net-buttons-bs5';
import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5';
import print from 'datatables.net-buttons/js/buttons.print';
import pdfmake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ElNotification } from 'element-plus';
pdfmake.vfs = pdfFonts.pdfMake.vfs;

window.JSZip = require('jszip');

DataTable.use(DataTableLib);
DataTable.use(pdfmake);
DataTable.use(ButtonsHtml5);
DataTable.use(Buttons);
DataTable.use(print);


function formatDateTime(dateTimeString) {
          const date = new Date(dateTimeString);
          const day = date.getDate().toString().padStart(2, '0');
          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const year = date.getFullYear();
          const hours = date.getHours().toString().padStart(2, '0');
          const minutes = date.getMinutes().toString().padStart(2, '0');
          
          return `${day}.${month}.${year} ${hours}:${minutes}`;
  }

export default {
  name: "CompanyRandevular",
  components: {
    DataTable,
  },
  data() {
    let columns = [
      {
        width: '10px',
        class: 'text-center',
        targets: [0],
        data: 'id',
      },
      {
        width: '40px',
        class: 'text-center',
        targets: [1],
        data: 'appointmentStatus', // CHAT STATUS
      },
      {
        width: '40px',
        class: 'text-center',
        targets: [2],
        data: 'customer', // CHAT START DATE
      },
      {
        width: '40px',
        class: 'text-center',
        targets: [3],
        data: 'customer', // TELEFON
      },
      {
        width: '60px',
        class: 'text-center',
        targets: [4],
        data: 'appointmentDateStart', // CHAT START DATE
      },
      {
        width: '60px',
        class: 'text-center',
        targets: [5],
        data: 'paymentType',
      },
      {
        width: '60px',
        class: 'text-center',
        targets: [6],
        data: 'totalAmount',
      },
      {
                width: '480px',
                class: 'text-center',
                targets: [7],
                data: null,
                orderable: false,
                render: function () { return '<div class="container  text-center">' +
                  '<button id="onayla" class="btn btn-success" >Onayla</button>&nbsp;&nbsp;' + 
                  '<button id="iptalet" class="btn btn-danger" >İptal Et</button>&nbsp;&nbsp;' + 
                  '</div>'; },
            },
      
     
    ];
      let self = this;
    return {
      userId: null,
      token: null,
      personeller: null,
      selectedCompany: null,
      companies: [],
      appointments: [],
      columns: columns,
      clickedRowData: null,
      dialogFormVisible: false,
      deleteMessage: "",
      appointmentDetail: [],
      dialogTableVisible: false,
      options: {

        buttons: [
          {
            title: 'Personellerim',
            extend: 'excelHtml5',
            text: '<i class="fa-solid fa-file-excel"></i> Excel',
            className: 'btn btn-success'
          },

          {
            title: 'Personellerim',
            extend: 'pdfHtml5',
            text: '<i class="fa-solid fa-file-pdf"></i> PDF',
            className: 'btn btn-danger'
          },
          {
            title: 'Personellerim',
            extend: 'print',
            text: '<i class="fa-solid fa-print"></i> Yazdır',
            className: 'btn btn-dark'
          },
          {
            title: 'Personellerim',
            extend: 'copy',
            text: '<i class="fa-solid fa-copy"></i> Kopyala',
            className: 'btn btn-primary'
          },
        ],
        responsive: true,
        autoWidth: false,
        dom: '<"datatable-header"rSfBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
        language: {
          search: '<span>Filtrele:</span> _INPUT_',
          lengthMenu: '<span>Kayıt Sayısı:</span> ',
          paginate: { 'first': 'First', 'last': 'Last', 'next': '&rarr;', 'previous': '&larr;' },
          "zeroRecords": "Kayıt bulunamadı...",
          "info": "Toplam Kayıt: _TOTAL_ | Gösterilen Sayfa: _PAGE_ / _PAGES_",
          "infoEmpty": "Kayıt bulunamadı...",
          "infoFiltered": "",
          searchPlaceholder: "Arama için Enter tuşuna basın",
          processing: "<img src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODdweCIgIGhlaWdodD0iODdweCIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiBjbGFzcz0ibGRzLXJvbGxpbmciIHN0eWxlPSJiYWNrZ3JvdW5kOiBub25lOyI+PGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgZmlsbD0ibm9uZSIgbmctYXR0ci1zdHJva2U9Int7Y29uZmlnLmNvbG9yfX0iIG5nLWF0dHItc3Ryb2tlLXdpZHRoPSJ7e2NvbmZpZy53aWR0aH19IiBuZy1hdHRyLXI9Int7Y29uZmlnLnJhZGl1c319IiBuZy1hdHRyLXN0cm9rZS1kYXNoYXJyYXk9Int7Y29uZmlnLmRhc2hhcnJheX19IiBzdHJva2U9IiMwMDhjZDIiIHN0cm9rZS13aWR0aD0iMTIiIHI9IjM1IiBzdHJva2UtZGFzaGFycmF5PSIxNjQuOTMzNjE0MzEzNDY0MTUgNTYuOTc3ODcxNDM3ODIxMzgiIHRyYW5zZm9ybT0icm90YXRlKDI2OS45NiA1MCA1MCkiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjAgNTAgNTA7MzYwIDUwIDUwIiBrZXlUaW1lcz0iMDsxIiBkdXI9IjFzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIj48L2FuaW1hdGVUcmFuc2Zvcm0+PC9jaXJjbGU+PC9zdmc+'>",
        },
        lengthMenu: [[3, 5, 10, 25, 50, -1], [3, 5, 10, 25, 50]],
        iDisplayLength: 25,

        select: true,
        processing: true,
        stateSave: true,
        bStateSave: true,
        deferRender: true,
        conditionalPaging: true,
        search: { return: true },
        columnDefs: columns,
        orderDate: [[0, "asc"]],

        rowCallback: function (row, data) {
          // rowCallback içinde self bağlamını kullanarak routerPush ve deletePid fonksiyonlarını çağırın
          if (data) {
            var customer = data['customer'] ? data['customer'] : null;
            var appointmentStatus = data['appointmentStatus'] ? data['appointmentStatus'] : null;
            var paymentType = data['paymentType'] ? data['paymentType'] : null;

            if (appointmentStatus) {
                row.cells[1].textContent = appointmentStatus['status'];
            }
            if (customer) {
                row.cells[2].textContent = customer['firstName'] + ' ' + customer['lastName'];
                row.cells[3].textContent = customer['phone'];
            }
            if (paymentType) {
                row.cells[5].textContent = paymentType['typeName'];
            }

            row.cells[4].textContent = formatDateTime(data['appointmentDateStart']);
            row.cells[6].textContent = data['totalAmount'] + ' ₺';

           

         row.querySelector('#onayla').addEventListener('click', function () {
            self.clickedRowData = data;
            self.randevuDetayGetir();
            self.dialogTableVisible = true;

         });


         row.querySelector('#iptalet').addEventListener('click', function () {
            self.deleteMessage = "";
            self.clickedRowData = data
            self.dialogFormVisible = true;
         });

          }

          row.style.cursor = 'pointer';

          return row;
        },

      },
    };
  },
  mounted() {
    this.userId = localStorage.getItem('userId');
    this.token = localStorage.getItem('token');
    if (this.token == null || this.token == "") {
      this.$router.push('/company/login');
    }
    this.getCompanies(this.userId, this.token);    
  },
  watch: {
    selectedCompany(newServiceId) {
      localStorage.setItem('companyId', newServiceId);
      this.getAppointments(newServiceId, this.token);
    },
  },
  methods: {
    async getCompanies(userId, token) {
      try {
      
        const response = await axios.get(`/company/list/byUserId`, {
          headers: {
              Authorization: `Bearer ${token}`,
            },
          params: {
            userId: userId
          }
        });
        if (response.data.status !== "success") {
          ElNotification({
            title: "Hata",
            message: response.data.message,
            type: "warning",
            position: "top-right",
          });
        }
        this.selectedCompany = response.data.data[0].id;
        await this.getAppointments(this.selectedCompany, this.token);
        this.companies = response.data.data;
        console.log(this.responseData);
      } catch (error) {
        if(error.response.status == 401){
          ElNotification({
            title: "Hata",
            message: "Yetkisiz erişim.",
            type: "warning",
            position: "top-right",
          });
          this.$router.push('/company/login');
        }else{

        ElNotification({
          title: "Hata",
          message: error.response.data.message,
          type: "warning",
          position: "top-right",
        });
      }
      }
    },
    async getAppointments(companyId, token) {
      try {
      
        const response = await axios.get(`/company/appointment/list`, {
          headers: {
              Authorization: `Bearer ${token}`,
            },
          params: {
            companyId: companyId
          }
        });
        if (response['data']['status'] !== "success") {
          ElNotification({
            title: "Hata",
            message: response['data']['message'],
            type: "warning",
            position: "top-right",
          });
        }
        console.log(response.data.data, 'test')
        this.appointments = response.data.data;
        console.log(this.responseData);
      } catch (error) {
        if(error.response.status == 401){
          ElNotification({
            title: "Hata",
            message: "Yetkisiz erişim.",
            type: "warning",
            position: "top-right",
          });
          this.$router.push('/company/login');
        }else{

        ElNotification({
          title: "Hata",
          message: error.response.data.message,
          type: "warning",
          position: "top-right",
        });
      }
      }
    },
    async randevuIptal(){
      const token = localStorage.getItem('token');
      const postData = {
        appointmentId: this.clickedRowData.id,
        description: this.deleteMessage,
      };
      if(this.deleteMessage == ""){
        ElNotification({
          title: "Hata",
          message: "Lütfen iptal açıklaması giriniz.",
          type: "warning",
          position: "top-right",
        });
        return;
      }
      if(this.clickedRowData.appointmentStatusId == 3){
        ElNotification({
          title: "Hata",
          message: "Bu randevu zaten iptal edilmiş.",
          type: "warning",
          position: "top-right",
        });
        return;
      }
      if(this.token == null || this.token == "") {
        ElNotification({
          title: "Hata",
          message: "Yetkisiz erişim.",
          type: "warning",
          position: "top-right",
        });
        this.$router.push('/company/login');
      }
      try {
        const response = await axios.post(`/company/appointment/cancel`, postData, 
          {
          headers: {
            Authorization: `Bearer ${token}`,
          }, 
        }
        );
        if (response['data']['status'] !== "success") {
          ElNotification({
            title: "Hata",
            message: response['data']['message'],
            type: "warning",
            position: "top-right",
          });
        }
        this.dialogFormVisible = false;
        this.getAppointments(this.selectedCompany, this.token);
      } catch (error) {
        if(error.response.status == 401){
          ElNotification({
            title: "Hata",
            message: "Yetkisiz erişim.",
            type: "warning",
            position: "top-right",
          });
          this.$router.push('/company/login');
        }else{

        ElNotification({
          title: "Hata",
          message: error.response.data.message,
          type: "warning",
          position: "top-right",
        });
      }
      }
    },
    async randevuDetayGetir(){
      try{
        const response = await axios.get(`/company/appointment/info/detail`, {
          headers: {
              Authorization: `Bearer ${this.token}`,
            },
          params: {
            companyId: this.selectedCompany,
            appointmentId: this.clickedRowData.id
          }
        });
        if (response['data']['status'] !== "success") {
          ElNotification({
            title: "Hata",
            message: response['data']['message'],
            type: "warning",
            position: "top-right",
          });
        }
        this.appointmentDetail = response.data.data;
      }catch(error){
        if(error.response.status == 401){
          ElNotification({
            title: "Hata",
            message: "Yetkisiz erişim.",
            type: "warning",
            position: "top-right",
          });
          this.$router.push('/company/login');
        }else{

        ElNotification({
          title: "Hata",
          message: error.response.data.message,
          type: "warning",
          position: "top-right",
        });
      }
      }
    },
    async randevuOnay(){
      if(this.clickedRowData.appointmentStatusId == 2){
        ElNotification({
          title: "Hata",
          message: "Bu randevu zaten onaylanmış.",
          type: "warning",
          position: "top-right",
        });
        return;
      }else if(this.clickedRowData.appointmentStatusId == 3){
        ElNotification({
          title: "Hata",
          message: "Bu randevu reddedilmiş.",
          type: "warning",
          position: "top-right",
        });
        return;
      }else if(this.clickedRowData.appointmentStatusId == 4){
        ElNotification({
          title: "Hata",
          message: "Bu randevu iptal edilmiş.",
          type: "warning",
          position: "top-right",
        });
        return;
      }else if(this.clickedRowData.appointmentStatusId == 5){
        ElNotification({
          title: "Hata",
          message: "Bu randevu tamamlanmış.",
          type: "warning",
          position: "top-right",
        });
        return;
      }
      const token = localStorage.getItem('token');
    
      if(this.token == null || this.token == "") {
        ElNotification({
          title: "Hata",
          message: "Yetkisiz erişim.",
          type: "warning",
          position: "top-right",
        });
        this.$router.push('/company/login');
      }
      try {
        const response = await axios.get(`/company/appointment/apprrove`,  
          {
          headers: {
            Authorization: `Bearer ${token}`,
          }, 
          params: {
            companyId: this.selectedCompany,
            appointmentId: this.clickedRowData.id
          }
        }
        );
        if (response['data']['status'] !== "success") {
          ElNotification({
            title: "Hata",
            message: response['data']['message'],
            type: "warning",
            position: "top-right",
          });
        }
        this.dialogTableVisible = false;
        ElNotification({
          title: "Başarılı",
          message: "Randevu onaylandı.",
          type: "success",
          position: "top-right",
        });
        this.getAppointments(this.selectedCompany, this.token);
      } catch (error) {
        if(error.response.status == 401){
          ElNotification({
            title: "Hata",
            message: "Yetkisiz erişim.",
            type: "warning",
            position: "top-right",
          });
          this.$router.push('/company/login');
        }else{

        ElNotification({
          title: "Hata",
          message: error.response.data.message,
          type: "warning",
          position: "top-right",
        });
      }
      }
    },
  },
};
</script>


<style>
@charset "UTF-8";
:root {
  --dt-row-selected: 13, 110, 253;
  --dt-row-selected-text: 255, 255, 255;
  --dt-row-selected-link: 9, 10, 11;
  --dt-row-stripe: 0, 0, 0;
  --dt-row-hover: 0, 0, 0;
  --dt-column-ordering: 0, 0, 0;
  --dt-html-background: white;
}
:root.dark {
  --dt-html-background: rgb(33, 37, 41);
}

table.dataTable td.dt-control {
  text-align: center;
  cursor: pointer;
}
table.dataTable td.dt-control:before {
  display: inline-block;
  box-sizing: border-box;
  content: "";
  border-top: 5px solid transparent;
  border-left: 10px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid transparent;
  border-right: 0px solid transparent;
}
table.dataTable tr.dt-hasChild td.dt-control:before {
  border-top: 10px solid rgba(0, 0, 0, 0.5);
  border-left: 5px solid transparent;
  border-bottom: 0px solid transparent;
  border-right: 5px solid transparent;
}

html.dark table.dataTable td.dt-control:before,
:root[data-bs-theme=dark] table.dataTable td.dt-control:before {
  border-left-color: rgba(255, 255, 255, 0.5);
}
html.dark table.dataTable tr.dt-hasChild td.dt-control:before,
:root[data-bs-theme=dark] table.dataTable tr.dt-hasChild td.dt-control:before {
  border-top-color: rgba(255, 255, 255, 0.5);
  border-left-color: transparent;
}

div.dt-scroll-body thead tr,
div.dt-scroll-body tfoot tr {
  height: 0;
}
div.dt-scroll-body thead tr th, div.dt-scroll-body thead tr td,
div.dt-scroll-body tfoot tr th,
div.dt-scroll-body tfoot tr td {
  height: 0 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
}
div.dt-scroll-body thead tr th div.dt-scroll-sizing, div.dt-scroll-body thead tr td div.dt-scroll-sizing,
div.dt-scroll-body tfoot tr th div.dt-scroll-sizing,
div.dt-scroll-body tfoot tr td div.dt-scroll-sizing {
  height: 0 !important;
  overflow: hidden !important;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before {
  position: absolute;
  display: block;
  bottom: 50%;
  content: "▲";
  content: "▲"/"";
}
table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  position: absolute;
  display: block;
  top: 50%;
  content: "▼";
  content: "▼"/"";
}
table.dataTable thead > tr > th.dt-orderable-asc, table.dataTable thead > tr > th.dt-orderable-desc, table.dataTable thead > tr > th.dt-ordering-asc, table.dataTable thead > tr > th.dt-ordering-desc,
table.dataTable thead > tr > td.dt-orderable-asc,
table.dataTable thead > tr > td.dt-orderable-desc,
table.dataTable thead > tr > td.dt-ordering-asc,
table.dataTable thead > tr > td.dt-ordering-desc {
  position: relative;
  padding-right: 30px;
}
table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order, table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order,
table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order,
table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order,
table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order,
table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order {
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  width: 12px;
}
table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-orderable-asc span.dt-column-order:after, table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:before, table.dataTable thead > tr > th.dt-orderable-desc span.dt-column-order:after, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:after, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-orderable-asc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-orderable-desc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  left: 0;
  opacity: 0.125;
  line-height: 9px;
  font-size: 0.8em;
}
table.dataTable thead > tr > th.dt-orderable-asc, table.dataTable thead > tr > th.dt-orderable-desc,
table.dataTable thead > tr > td.dt-orderable-asc,
table.dataTable thead > tr > td.dt-orderable-desc {
  cursor: pointer;
}
table.dataTable thead > tr > th.dt-orderable-asc:hover, table.dataTable thead > tr > th.dt-orderable-desc:hover,
table.dataTable thead > tr > td.dt-orderable-asc:hover,
table.dataTable thead > tr > td.dt-orderable-desc:hover {
  outline: 2px solid rgba(0, 0, 0, 0.05);
  outline-offset: -2px;
}
table.dataTable thead > tr > th.dt-ordering-asc span.dt-column-order:before, table.dataTable thead > tr > th.dt-ordering-desc span.dt-column-order:after,
table.dataTable thead > tr > td.dt-ordering-asc span.dt-column-order:before,
table.dataTable thead > tr > td.dt-ordering-desc span.dt-column-order:after {
  opacity: 0.6;
}
table.dataTable thead > tr > th.sorting_desc_disabled span.dt-column-order:after, table.dataTable thead > tr > th.sorting_asc_disabled span.dt-column-order:before,
table.dataTable thead > tr > td.sorting_desc_disabled span.dt-column-order:after,
table.dataTable thead > tr > td.sorting_asc_disabled span.dt-column-order:before {
  display: none;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}

div.dt-scroll-body > table.dataTable > thead > tr > th,
div.dt-scroll-body > table.dataTable > thead > tr > td {
  overflow: hidden;
}

:root.dark table.dataTable thead > tr > th.dt-orderable-asc:hover, :root.dark table.dataTable thead > tr > th.dt-orderable-desc:hover,
:root.dark table.dataTable thead > tr > td.dt-orderable-asc:hover,
:root.dark table.dataTable thead > tr > td.dt-orderable-desc:hover,
:root[data-bs-theme=dark] table.dataTable thead > tr > th.dt-orderable-asc:hover,
:root[data-bs-theme=dark] table.dataTable thead > tr > th.dt-orderable-desc:hover,
:root[data-bs-theme=dark] table.dataTable thead > tr > td.dt-orderable-asc:hover,
:root[data-bs-theme=dark] table.dataTable thead > tr > td.dt-orderable-desc:hover {
  outline: 2px solid rgba(255, 255, 255, 0.05);
}.dt-search{
  margin-bottom: -30px;
}

</style>