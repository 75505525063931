<template>
  <div>
    <GoogleMap
      api-key="AIzaSyDXHf026LDXzeprtLECEFrHdMRgtl7kvHY"
      style="width: 100%; height: 500px"
      :center="center"
      :zoom="15"
      @click="handleMapClick"
      >
        <Marker :options="{ position: markerPosition  }" />
    </GoogleMap>
  </div>
</template>

<script>
import { GoogleMap, Marker } from 'vue3-google-map';
import { mask } from "vue-the-mask";


export default {
  directives: { mask },

  name: "KonumBilgileri",
  components: {
    GoogleMap,
    Marker,
  },
  data() {
    return {
      
      center: { lat: 37.420084740908976, lng: 31.84487848616927 }, // Harita merkezi
      markerPosition: { lat: 37.420084740908976, lng: 31.84487848616927 }, // İşaretçi konumu    
    
    };
  },
  methods: {
    
    handleMapClick(event) {
      this.markerPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      console.log(this.markerPosition)
    },
  },
};
</script>

<style scoped>

</style>
