<template>
    <div class="row">
      <div class="col-lg-8 offset-lg-2 justify-content-start align-items-center mt-5 mb-3">
              <label for="isletmeAdi" class="col-1 col-form-label text-start font-weight-bold ms-0 ms-lg-4 ms-4 float-start" style="min-width: 80px;">İşletmem:</label>
              <el-select
                size="large"
                v-model="selectedCompany"
                filterable
                placeholder="Lütfen bir işletme seçiniz..."
                style=""
                :no-data-text="'Veri bulunamadı'"
                class="float-start col-10"
                
              >
                <el-option
                  v-for="item in companies"
                  :key="item.id"
                  :label="item.companyName"
                  :value="item.id"
                />
              </el-select> 
            </div>
      <div class="col-lg-8 offset-lg-2">
        
        <div class="table-responsive">
          <div class="container mt-3 ">
            
            <DataTable :data="comments" :columns="columns" class="table table-striped table-bordered display" :options="options">
              <thead>
                <tr>
                  <th class="text-center">#</th>
                  <th class="text-center" style="min-width: 180px;">Hizmetin Adı</th>
                  <th class="text-center" style="min-width: 180px;">Hizmetin Yöntemi</th>
                  <th class="text-center" style="min-width: 190px;">Hizmetin Fiyatı</th>
                  <th class="text-center" style="min-width: 166px;">Hizmetin Durumu</th>
                </tr>
              </thead>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script setup>
  import '@/assets/js/jquery-global.js';
  
  </script>
  
  
  <script>
  import axios from 'axios'
  import DataTable from 'datatables.net-vue3';
  import DataTableLib from 'datatables.net-bs5';
  import Buttons from 'datatables.net-buttons-bs5';
  import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5';
  import print from 'datatables.net-buttons/js/buttons.print';
  import pdfmake from 'pdfmake';
  import pdfFonts from 'pdfmake/build/vfs_fonts';
  import 'datatables.net-responsive-bs5';
  import { ElNotification } from 'element-plus';
  pdfmake.vfs = pdfFonts.pdfMake.vfs;
  
  window.JSZip = require('jszip');
  
  DataTable.use(DataTableLib);
  DataTable.use(pdfmake);
  DataTable.use(ButtonsHtml5);
  DataTable.use(Buttons);
  DataTable.use(print);
  
  export default {
    name: "CompanyYorumlar",
    components: {
      DataTable,
    },
    data() {
      let columns = [
        {
          width: '40px',
          class: 'text-center',
          targets: [0],
          data: 'id',
        },
        {
          width: '40px',
          class: 'text-center',
          targets: [1],
          data: 'isim',
        },
        {
          width: '40px',
          class: 'text-center',
          targets: [2],
          data: 'soyisim',
        },
        {
          width: '40px',
          class: 'text-center',
          targets: [3],
          data: 'unvan',
        },
        {
          width: '40px',
          class: 'text-center',
          targets: [4],
          data: null, orderable: false, render: function () {
            return '<div class="container text-center"> <span class="badge rounded-pill text-bg-success">Aktif</span></div>';
          },
        },
        {
          width: '280px',
          class: 'text-center',
          targets: [5],
          data: null, orderable: false, render: function () {
            return '<div class="container  text-center"> <button style="min-width:100px;min-height:66px;" class="button-63">Hizmetin Detayları</button></div>';
          },
        },
      ];
        let self = this;
        const hizmet = function () {
      const id = self.clickedRowData.id;
      self.$router.push({ name: 'HizmetinDetaylari', params: { id: id } });
    };
      return {
        userId: null,
        token: null,
        personeller: null,
        selectedCompany: null,
        companies: [],
        comments: [],
        columns: columns,
        clickedRowData: null,
        options: {
  
          buttons: [
            {
              title: 'Personellerim',
              extend: 'excelHtml5',
              text: '<i class="fa-solid fa-file-excel"></i> Excel',
              className: 'btn btn-success'
            },
  
            {
              title: 'Personellerim',
              extend: 'pdfHtml5',
              text: '<i class="fa-solid fa-file-pdf"></i> PDF',
              className: 'btn btn-danger'
            },
            {
              title: 'Personellerim',
              extend: 'print',
              text: '<i class="fa-solid fa-print"></i> Yazdır',
              className: 'btn btn-dark'
            },
            {
              title: 'Personellerim',
              extend: 'copy',
              text: '<i class="fa-solid fa-copy"></i> Kopyala',
              className: 'btn btn-primary'
            },
          ],
          responsive: true,
          autoWidth: false,
          dom: '<"datatable-header"rSfBl><"datatable-scroll-wrap"t><"datatable-footer"ip>',
          language: {
            search: '<span>Filtrele:</span> _INPUT_',
            lengthMenu: '<span>Kayıt Sayısı:</span> ',
            paginate: { 'first': 'İlk Sayfa', 'last': 'Son Sayfa', 'next': '&rarr;', 'previous': '&larr;' },
            "zeroRecords": "Kayıt bulunamadı...",
            "info": "Toplam Kayıt: _TOTAL_ | Gösterilen Sayfa: _PAGE_ / _PAGES_",
            "infoEmpty": "Kayıt bulunamadı...",
            "infoFiltered": "",
            searchPlaceholder: "Arama için Enter tuşuna basın",
            processing: "<img src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODdweCIgIGhlaWdodD0iODdweCIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIiBjbGFzcz0ibGRzLXJvbGxpbmciIHN0eWxlPSJiYWNrZ3JvdW5kOiBub25lOyI+PGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgZmlsbD0ibm9uZSIgbmctYXR0ci1zdHJva2U9Int7Y29uZmlnLmNvbG9yfX0iIG5nLWF0dHItc3Ryb2tlLXdpZHRoPSJ7e2NvbmZpZy53aWR0aH19IiBuZy1hdHRyLXI9Int7Y29uZmlnLnJhZGl1c319IiBuZy1hdHRyLXN0cm9rZS1kYXNoYXJyYXk9Int7Y29uZmlnLmRhc2hhcnJheX19IiBzdHJva2U9IiMwMDhjZDIiIHN0cm9rZS13aWR0aD0iMTIiIHI9IjM1IiBzdHJva2UtZGFzaGFycmF5PSIxNjQuOTMzNjE0MzEzNDY0MTUgNTYuOTc3ODcxNDM3ODIxMzgiIHRyYW5zZm9ybT0icm90YXRlKDI2OS45NiA1MCA1MCkiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjAgNTAgNTA7MzYwIDUwIDUwIiBrZXlUaW1lcz0iMDsxIiBkdXI9IjFzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIj48L2FuaW1hdGVUcmFuc2Zvcm0+PC9jaXJjbGU+PC9zdmc+'>",
          },
          lengthMenu: [[3, 5, 10, 25, 50, -1], [3, 5, 10, 25, 50]],
          iDisplayLength: 25,
  
          select: true,
          processing: true,
          stateSave: true,
          bStateSave: true,
          deferRender: true,
          conditionalPaging: true,
          search: { return: true },
          columnDefs: columns,
          orderDate: [[0, "asc"]],
  
          rowCallback: function (row, data) {
            // rowCallback içinde self bağlamını kullanarak routerPush ve deletePid fonksiyonlarını çağırın
            if (data) {
              row.cells[1].textContent = data['isim'];
              row.cells[2].textContent = data['soyisim'];
  
              
              row.querySelector('.button-63').addEventListener('click', function () {
              self.clickedRowData = data;
              hizmet(data.id);
           });
  
            }
  
            row.style.cursor = 'pointer';
  
            return row;
          },
  
        },
      };
    },
    mounted() {
      this.userId = localStorage.getItem('userId');
      this.token = localStorage.getItem('token');
      if (this.token == null || this.token == "") {
        this.$router.push('/company/login');
      }
      this.getCompanies(this.userId, this.token);    
    },
    watch: {
      selectedCompany(newCompanyId) {
        this.getComments(this.userId, newCompanyId, this.token);
      },
    },
    methods: {
      async getCompanies(userId, token) {
        try {
        
          const response = await axios.get(`/company/list/byUserId`, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
            params: {
              userId: userId
            }
          });
          if (response.data.status !== "success") {
            ElNotification({
              title: "Hata",
              message: response.data.message,
              type: "warning",
              position: "top-right",
            });
          }
          this.selectedCompany = response.data.data[0].id;
          localStorage.setItem('companyId', this.selectedCompany);
          await this.getComments(this.selectedCompany, this.token);
          console.log(this.responseData);
        } catch (error) {
          if(error.response.status == 401){
            ElNotification({
              title: "Hata",
              message: "Yetkisiz erişim.",
              type: "warning",
              position: "top-right",
            });
            this.$router.push('/company/login');
          }else{
  
          ElNotification({
            title: "Hata",
            message: error.response.data.message,
            type: "warning",
            position: "top-right",
          });
        }
        }
      },
      async getComments(companyId, token) {
        try {
        
          const response = await axios.get(`/company/cosadsadist`, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
            params: {
              companyId: companyId
            }
          });
          if (response.data.status !== "success") {
            ElNotification({
              title: "Hata",
              message: response.data.message,
              type: "warning",
              position: "top-right",
            });
          }
          this.comments = response.data.data;
          console.log(this.responseData);
        } catch (error) {
          if(error.response.status == 401){
            ElNotification({
              title: "Hata",
              message: "Yetkisiz erişim.",
              type: "warning",
              position: "top-right",
            });
            this.$router.push('/company/login');
          }else{
  
          ElNotification({
            title: "Hata",
            message: error.response.data.message,
            type: "warning",
            position: "top-right",
          });
        }
        }
      }
    },
  };
  </script>
  
  
  <style>
  @import 'datatables.net-bs5';
  .dt-search{
    margin-bottom: -30px;
  }
  
  </style>