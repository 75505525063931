<template>
<div class="w-100 d-flex justify-content-center register-page" style="padding-top: 4vh">
    <div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
        <div class="text-center">
            <router-link to="/"><img src="../../assets/images/Randevu.png" alt="randevuburada.com logo" style="width: 200px" class="m-4" /></router-link>
        </div>
        <el-steps :space="200" :active="activeTab" simple>
            <el-step title="Profil" :icon="User" />
            <el-step title="Ek Bilgiler" :icon="Document" />
        </el-steps>
        <div class="register-card mt-3">
            <div v-if="activeTab == 1">
                <div class="mb-3">
                    <label class="mb-2">Adınız<span class="text-danger">*</span></label>
                    <el-input size="large" :style="error.user_name > 0 ? '--el-border-color: red' : ''" v-model="user_name" placeholder="Ad" @keypress.enter="step(2)" />
                    <label class="mt-1 mb-0 text-danger" v-if="error.user_name > 0">İsim zorunludur!</label>
                </div>
                <div class="mb-3">
                    <label class="mb-2">Soyadınız<span class="text-danger">*</span></label>
                    <el-input size="large" :style="error.user_surname > 0 ? '--el-border-color: red' : ''" v-model="user_surname" placeholder="Soyad" @keypress.enter="step(2)" />
                    <label class="mt-1 mb-0 text-danger" v-if="error.user_surname > 0">Soyisim zorunludur!</label>
                </div>
                <div class="mb-3">
                    <label class="mb-2">Kullanıcı Adınız<span class="text-danger">*</span></label>
                    <el-input size="large" :style="error.user_name > 0 ? '--el-border-color: red' : ''" v-model="user_last_name" placeholder="Kullanıcı Adı" @keypress.enter="step(2)" />
                    <label class="mt-1 mb-0 text-danger" v-if="error.user_name > 0">Kullanıcı Adı zorunludur!</label>
                </div>
                <div class="mb-3">
                    <label class="mb-2">Telefon Numaranız<span class="text-danger">*</span></label>
                    <div class="d-flex">
                        <el-input size="large" :style="error.user_phone > 0 ? '--el-border-color: red' : ''" v-model="user_phone" v-mask="'+90 (5##) ### ## ##'" placeholder="+90 (5##) ### ## ##" @keypress.enter="step(2)" />
                    </div>
                    <label class="mt-1 mb-0 text-danger" v-if="error.user_phone > 0">Telefon no zorunludur!</label>
                </div>

                <div class="mb-3">
                    <label class="mb-2">E-Posta Adresiniz<span class="text-danger">*</span></label>
                    <el-input size="large" :style="error.user_email > 0 ? '--el-border-color: red' : ''" v-model="user_email" placeholder="ornek@mail.com" autocomplete="off" @keypress.enter="step(2)" />
                    <label class="mt-1 mb-0 text-danger" v-if="error.user_email > 0">Eposta adresi zorunludur!</label>
                </div>

                <div class="mb-3">
                    <label class="mb-2">Şifreniz<span class="text-danger">*</span></label>
                    <el-input size="large" :style="error.user_password > 0 ? '--el-border-color: red' : ''" v-model="user_password" type="password" show-password placeholder="Şifre" autocomplete="off" @keypress.enter="step(2)" />

                    <label class="mt-1 mb-0 text-danger" v-if="error.user_password > 0">Şifreniz uyumsuz/güvensiz!</label>
                </div>

                <div class="mb-3">
                    <label class="mb-2">Şifrenizi Doğrulayın<span class="text-danger">*</span></label>
                    <el-input size="large" :style="error.user_password_confirmation > 0 ? '--el-border-color: red' : ''" v-model="user_password_confirmation" type="password" show-password placeholder="Şifre Tekrar" autocomplete="off" @keypress.enter="step(2)" />
                    <label class="mt-1 mb-0 text-danger" v-if="error.user_password_confirmation > 0">Şifreniz uyumsuz/güvensiz!</label>
                </div>
                <div class="d-flex w-100 justify-content-end">
                    <el-button type="primary" style="width: 93.65px;height: 32px;" class="mb-3" @click="step(2)">Devam Et</el-button>
                </div>
            </div>
            <div v-if="activeTab == 2">
                <div class="mb-3">
                    <label class="mb-2">İl<span class="text-danger">*</span></label>
                    <el-select v-model="cityId" filterable placeholder="Lütfen bir il seçiniz..." style="min-height: 25px;" :no-data-text="'Veri bulunamadı'">
                        <el-option v-for="item in city" :key="item.id" :label="item.cityName" :value="item.id" />
                    </el-select>
                    <label class="mt-1 mb-0 text-danger" v-if="error.cityId > 0">İl zorunludur!</label>
                </div>
                <div class="mb-3">
                    <label class="mb-2">İlçe<span class="text-danger">*</span></label>
                    <el-select v-model="districtId" filterable placeholder="Lütfen bir ilçe seçiniz..." :no-data-text="'Lütfen önce şehir seçiniz...'">
                        <el-option v-for="item in districts" :key="item.id" :label="item.districtName" :value="item.id" />
                    </el-select>
                    <label class="mt-1 mb-0 text-danger" v-if="error.districtId > 0">İlçe zorunludur!</label>
                </div>
                <div class="mb-3">
                    <label class="mb-2">Adres <span class="text-danger">*</span></label>
                    <el-input v-model="adress" placeholder="Adres Giriniz..." required></el-input>
                    <label class="mt-1 mb-0 text-danger" v-if="error.adress > 0">Adres bilgileri zorunludur!</label>
                </div>
                <div class="mb-3">
                    <label class="mb-2">TC Kimlik <span class="text-danger">*</span></label>
                    <el-input v-model="tc" v-mask="'###########'" placeholder="Kimlik Numaranızı 11 haneli olacak şekilde giriniz." required></el-input>
                    <label class="mt-1 mb-0 text-danger" v-if="error.tc > 0">Kimlik bilgileri zorunludur!</label>
                </div>
                <div class="mb-3">
                    <label for="gender" class="form-label p-2">Cinsiyet: <span class="text-danger">*</span></label>
                    <el-radio-group v-model="gender">
                        <el-radio class="ms-2 p-2" :label="true">Erkek</el-radio>
                        <el-radio :label="false">Kadın</el-radio>
                    </el-radio-group>
                    <label class="mt-1 mb-0 text-danger" v-if="error.gender == 'E' ">Cinsiyet zorunludur!</label>
                </div>
                <div class="d-flex w-100 justify-content-end">
                    <el-button type="info" style="width: 93.65px;height: 32px;" class="mb-3" @click="activeTab = 1">Geri</el-button>
                    <el-button type="success" class="mb-3" @click="step(3)"> Kayıt ol</el-button>
                </div>
            </div>

            <el-divider> Üyeliğiniz var mı? </el-divider>
            <button class="w-100 my-2 ml-0 button-63" @click="giris()">Giriş Yap</button>
        </div>
    </div>
    <el-dialog v-if="dialogVisible == true" draggable v-model="dialogVisible" width="500px">

        <div class="dangerBg">
            {{ this.user_phone }} numaralı telefona bir doğrulama kodu ilettik. Doğrulama kodunuzu aşağıya giriniz veya kod
            alamadıysanız "Tekrar Gönder" seçeneğine tıklayınız.
        </div>
        <div class="pin-div mt-4">
            <v-otp-input v-model="otp_code" ref="otpInput" input-classes="otp-input" separator="-" :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChange" @on-complete="handleOnComplete" />
        </div>

        <template #footer>
            <span class="dialog-footer">
                <div class="d-flex justify-content-between">
                    <div>
                        <el-button type="warning" plain :disabled="newSend">Tekrar Gönder</el-button>
                        <span class="ms-2 text-primary" v-if="newSend">{{ countDown }}</span>
                    </div>
                    <el-button @click="dialogVisible = false">Kapat</el-button>
                    <el-button type="primary" @click="newRegisterOTP()">Gönder</el-button>
                </div>
            </span>
        </template>
    </el-dialog>
</div>
</template>

<script>
import axios from "axios";
// import md5 from "md5";
import { mask } from "vue-the-mask";
import profiles from "@/info/tables.js";
import VOtpInput from "vue3-otp-input";
import { User, OfficeBuilding, Document } from "@element-plus/icons-vue";
import { ElInput, ElButton, ElNotification, ElDivider } from "element-plus";

export default {
    name: "CustomerRegisters",
    directives: { mask },
    components: {
        ElInput,
        ElButton,
        ElDivider,
        VOtpInput,
    },
    data() {
        return {
            User,
            Document,
            OfficeBuilding,
            profiles,
            activeTab: 1,
            user_name: "",
            user_surname: "",
            user_last_name: "",
            user_email: "",
            user_phone: "",
            user_password: "",
            user_password_confirmation: "",
            user_tc: "",
            otp_code: "",
            comp_id: null,
            cityId: null,
            city: [],
            districtId: null,
            districts: [],
            adress: "",
            gender: "",
            tc: "",
            newSend: false,
            countDown: 120,
            parameters: {},
            token: "",
            userId: "",
            user: {},
            dialogVisible: false,
            error: {
                user_name: 0,
                user_surname: 0,
                user_last_name: 0,
                user_email: 0,
                user_phone: 0,
                user_password: 0,
                user_password_confirmation: 0,
                firma_vergi_dairesi: 0,
                comp_name: 0,
                promocode: 0,
                gidenId: 0,
                cityId: 0,
                districtId: 0,
                adress: 0,
                tc: 0,
                address: 0,
                gender: 0,
            },
        };
    },
    mounted() {
        this.getCity();
    },
    watch: {
        cityId(newCityId) {
            this.getDistricts(newCityId);
        },
    },
    methods: {
        async getCity() {
            try {
                var response = await axios.get(`/city/list`);
                if (response.data.status !== "success") {
                    console.log(response)
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                } else {
                    this.city = response.data.data
                }
            } catch (error) {
                if (error.response.status == 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login')
                } else {
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı.",
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        },
        async getDistricts(cityId) {
            try {
                const response = await axios.get(`/district/list`, {
                    params: {
                        cityId: cityId,
                    },
                });
                if (response.data.status !== "success") {
                    ElNotification({
                        title: "Hata",
                        message: response.data.message,
                        type: "warning",
                        position: "top-right",
                    });
                } else {
                    this.districts = response.data.data.districts;
                }
            } catch (error) {
                if (error.response.status === 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Token geçerlilik süresi dolmuş. Lütfen tekrar giriş yapın.",
                        type: "warning",
                        position: "top-right",
                    });
                    this.$router.push('/company/login');
                } else {
                    ElNotification({
                        title: "Hata",
                        message: "Bir hata ile karşılaşıldı.",
                        type: "warning",
                        position: "top-right",
                    });
                }
            }
        },
        newRegisterOTP() {
            if (this.otp_code != "") {
                ElNotification({
                    title: "Hata",
                    message: "Doğrulama kodu eksik girildi.",
                    type: "error",
                    position: "top-left",
                });
            } else {
                ElNotification({
                    title: "Hata",
                    message: "Telefon doğrulaması tamamlandı!",
                    type: "success",
                    position: "top-left",
                });

                this.dialogVisible = false;
            }
        },
        async postStep1() {
            try {
                let paramsPostStep1 = {
                    userName: this.user_last_name,
                    firstName: this.user_name,
                    lastName: this.user_surname,
                    email: this.user_email,
                    phoneNumber: this.user_phone,
                    password: this.user_password,
                    confirmPassword: this.user_password_confirmation,
                };
                const response = await axios.post("/account/customer/register", paramsPostStep1);
                if (response.status === "200" || response.status === 200) {
                    this.token = response.data.data.token;
                    this.userId = response.data.data.user.userId;
                    this.user = response.data.data.user;
                    localStorage.clear();
                    localStorage.setItem('token', this.token);
                    localStorage.setItem('userId', this.userId);
                    localStorage.setItem('user', JSON.stringify(this.user));
                    this.activeTab = 2;
                    this.dialogVisible = true;
                } else {
                    this.activeTab = 1;
                    ElNotification({
                        title: "Hata",
                        message: response.data.data,
                        type: "error",
                        position: "top-left",
                    });
                }
            } catch (error) {
                this.activeTab = 1;
                console.error(error.response);

                ElNotification({
                    title: "Hata",
                    message: "Beklenmeyen bir hata oluştu.",
                    type: "error",
                    position: "top-left",
                });

            }
        },
        async postStep3() {
            try {
                const jwtToken = localStorage.getItem("token");
                if(!jwtToken || jwtToken == null || jwtToken == "" || jwtToken == undefined) {
                    ElNotification({
                        title: "Hata",
                        message: "Oturumunuzun süresi dolmuş. Lütfen tekrar kayıt işlemlerini yapınız.",
                        type: "error",
                        position: "top-left",
                    });
                    this.$router.push("company/login");
                }
                const config = {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`,
                    },
                };

                const paramsPostStep2 = {
                    userId: localStorage.getItem("userId"),
                    firstName: this.user_name,
                    lastName: this.user_surname,
                    phone: this.user_phone,
                    countryId: 1,
                    cityId: this.cityId,
                    districtId: this.districtId,
                    adress: this.adress,
                    gender: this.gender,
                    tc: this.tc
                  };

                const response1 = await axios.post(
                    "/customer/info/update",
                    paramsPostStep2,
                    config
                );

                if (response1.status === 200 || response1.status === "200") {
                    ElNotification({
                        title: "Başarılı",
                        message: "Kayıt işlemleri başarıyla tamamlandı.",
                        type: "success",
                        position: "top-left",
                    });
                    this.$router.go(-1);
                } else {
                    ElNotification({
                        title: "Hata",
                        message: response1.data.data,
                        type: "error",
                        position: "top-left",
                    });
                }
            } catch (error) {
                if (error.response.status === 401) {
                    ElNotification({
                        title: "Hata",
                        message: "Oturumunuzun süresi dolmuş. Lütfen tekrar kayıt işlemlerini yapınız.",
                        type: "error",
                        position: "top-left",
                    });
                    this.$router.push("company/login");
                }
                this.activeTab = 1;
                if (error.response && error.response.data && error.response.data.data) {
                    ElNotification({
                        title: "Hata",
                        message: "Beklenmeyen bir hata oluştu: " + error.response.data.data,
                        type: "error",
                        position: "top-left",
                    });
                } else {
                    ElNotification({
                        title: "Hata",
                        message: "Beklenmeyen bir hata oluştu.",
                        type: "error",
                        position: "top-left",
                    });
                }
            }
        },
        newRegister() {
            if (this.gidenId.length < 1) {
                ElNotification({
                    title: "Hata",
                    message: "Lütfen bir paket seçiniz",
                    type: "error",
                    position: "top-left",
                });
                this.activeTab = 3;
                this.error.gidenId = 1;
            } else {
                this.error.gidenId = 0;
            }
        },
        bosKontrol1() {
            var say = 1;
            if (this.user_name.length < 3 || this.user_last_name.length < 3) {
                ElNotification({
                    title: "Hata",
                    message: "Lütfen Adınızı ve Kullanıcı Adınızı giriniz.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 1;
                this.error.user_name = 1;
                this.error.user_last_name = 1;
            } else {
                this.error.user_name = 0;
                this.error.user_last_name = 0;
                this.activeTab = 2;
            }
            if (this.user_surname.length < 2) {
                ElNotification({
                    title: "Hata",
                    message: "Lütfen Soyadınızı giriniz.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 1;
                this.error.user_surname = 1;
            } else {
                this.error.user_surname = 0;
            }
            if (this.user_phone.length < 5) {
                ElNotification({
                    title: "Hata",
                    message: "Lütfen Telefon Numaranızı giriniz.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 1;
                this.error.user_phone = 1;
            } else {
                this.error.user_phone = 0;
            }
            if (!this.user_email?.includes("@") || !this.user_email?.includes(".")) {
                ElNotification({
                    title: "Hata",
                    message: "Lütfen Eposta adresinizi kontrol ediniz.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 1;
                this.error.user_email = 1;
            } else {
                this.error.user_email = 0;
            }
            if (this.user_password.length < 8 || this.user_password.length < 3) {
                ElNotification({
                    title: "Hata",
                    message: "Şifreniz en az 8 karakter olmalıdır.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 1;
                this.error.user_password = 1;
                this.error.user_password_confirmation = 1;
            } else if (!/\d/.test(this.user_password)) {
                ElNotification({
                    title: "Hata",
                    message: "Şifreniz en az bir rakam içermelidir.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 1;
                this.error.user_password = 1;
                this.error.user_password_confirmation = 1;
            } else if (!/[^\w\d]/.test(this.user_password)) {
                ElNotification({
                    title: "Hata",
                    message: "Şifreniz en az bir alfasayısal olmayan karakter içermelidir.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 1;
                this.error.user_password = 1;
                this.error.user_password_confirmation = 1;
            } else if (!/[A-Z]/.test(this.user_password)) {
                ElNotification({
                    title: "Hata",
                    message: "Şifreniz en az bir büyük harf içermelidir.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 1;
                this.error.user_password = 1;
                this.error.user_password_confirmation = 1;
            } else if (this.user_password_confirmation != this.user_password) {
                ElNotification({
                    title: "Hata",
                    message: "Şifreler uyuşmuyor!",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 1;
                this.error.user_password_confirmation = 1;
            } else {
                this.error.user_password = 0;
                this.error.user_password_confirmation = 0;
            }
            if (
                this.error.user_name == 0 &&
                this.error.user_surname == 0 &&
                this.error.user_last_name == 0 &&
                this.error.user_email == 0 &&
                this.error.user_phone == 0 &&
                this.error.user_password == 0 &&
                this.error.user_password_confirmation == 0
            ) {
                this.postStep1();
                this.activeTab = 2;
            } else {
                this.activeTab = 1;
            }

        },
        bosKontrol2(){
            var say = 1;
            if (this.cityId == null) {
                ElNotification({
                    title: "Hata",
                    message: "Lütfen bir il seçiniz.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 2;
                this.error.cityId = 1;
            } else {
                this.error.cityId = 0;
            }
            if (this.districtId == null) {
                ElNotification({
                    title: "Hata",
                    message: "Lütfen bir ilçe seçiniz.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 2;
                this.error.districtId = 1;
            } else {
                this.error.districtId = 0;
            }
            if (this.adress.length < 5) {
                ElNotification({
                    title: "Hata",
                    message: "Lütfen adres bilgilerinizi giriniz.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 2;
                this.error.adress = 1;
            } else {
                this.error.adress = 0;
            }
            if (this.tc.length != 11) {
                ElNotification({
                    title: "Hata",
                    message: "Kimlik numaranız 11 haneli olmalıdır.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 2;
                this.error.tc = 1;
            } else {
                this.error.tc = 0;
            }
            if(this.gender == ""){
                ElNotification({
                    title: "Hata",
                    message: "Cinsiyet bilgisi zorunludur.",
                    type: "error",
                    offset: 120 * say++,
                    position: "top-left",
                });
                this.activeTab = 2;
                this.error.gender = 'E';
            } else {
               this.error.gender = 'H';
            }
            if(this.error.cityId == 0 && this.error.districtId == 0 && this.error.adress == 0 && this.error.tc == 0 && this.error.gender == 'H'){
                this.postStep3();
            } else {
                this.activeTab = 2;
            }
        },
        gidenIds(p) {
            if (p == 1) this.gidenId = 1;
            else if (p == 2) this.gidenId = 2;
            else if (p == 3) this.gidenId = 3;
        },
        sifreKontrol() {
            if (this.user_password.length < 8 || this.user_password.length < 3) {
                ElNotification({
                    title: "Hata!",
                    message: "Şifreniz en az 8 karakter olmalıdır.",
                    type: "error",
                    position: "top-left",
                });
                this.activeTab = 1;
                this.error.user_password = 1;
                this.error.user_password_confirmation = 1;
            } else if (this.user_password_confirmation != this.user_password) {
                ElNotification({
                    title: "Hata!",
                    message: "Girilen şifreler aynı değil.",
                    type: "error",
                    position: "top-left",
                });
                this.activeTab = 1;
                this.error.user_password_confirmation = 1;
            } else {
                this.error.user_password = 0;
                this.error.user_password_confirmation = 0;
            }
        },
        step(stepNo) {
            if (stepNo == 2) {
                this.bosKontrol1();
            } else if (stepNo == 3) {
                this.bosKontrol2();
            }
        },
        giris() {
            this.$router.push("login");
        },
    },
    created() {},
};
</script>

<style>
.pin-div {
    width: 100%;
    display: flex;
    justify-content: center;
}

.pin-div input {
    font-size: 26px !important;
    width: 40px;
    text-align: center;
    height: 60px !important;
    border-radius: 4px !important;
    border: 1px solid #000 !important;
}

.pin-div input:focus-visible {
    outline: 0 !important;
    border: 1px solid #000 !important;
    border-radius: 4px !important;
}

.register-page {
    height: 100vh;
}

.register-card {
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
}

.dark .register-page {
    background: #18191b;
}

.dark .register-card {
    background: #1e2022;
}

.dark .el-divider__text {
    background: #1e2022;
}

.light .register-page {
    background: #edf1f3;
}

.light .register-card {
    background: #fff;
}

.light .el-divider__text {
    background: #fff;
}

.itemBadge {
    margin-top: 10px;
    margin-right: 40px;
}

.dangerBg {
    background-color: rgba(245, 108, 108, 0.1);
    padding: 8px 16px;
    border-radius: 4px;
    color: #000;
    border-left: 5px solid #f56c6c;
    margin: 20px 0;
    word-break: break-word;
}

.primaryBg {
    background-color: rgba(64, 158, 255, 0.1);
    padding: 8px 16px;
    border-radius: 4px;
    color: #000;
    border-left: 5px solid #409eff;
    margin: 20px 0;
    word-break: break-word;
}

.el-step__title,
.el-divider__text {
    word-break: normal !important;
}

.el-scrollbar {
    max-width: 800px;
}

.text-danger {
    color: #f56c6c !important;
    font-weight: bold !important;
}

.borderChange {
    border: 1px solid #198754 !important;
    transform: scale(1.05);
}

.el-col {
    margin: 0.6rem 0 0.6rem 0;
}

.el-card:hover {
    border: 1px solid #198754 !important;
}

.el-card__body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.ribbon-2 {
    --f: 10px;
    /* control the folded part*/
    --r: 15px;
    /* control the ribbon shape */
    --t: 10px;
    /* the top offset */

    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 2px 20px var(--f) calc(10px + var(--r));
    clip-path:
        polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%,
            calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)),
            var(--r) calc(50% - var(--f)/2));
    background: #409eff;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    color: #e5efee
}

.box {
    position: relative;
}

.button-63 {
    background: #ff914d !important;
    border: 0 !important;
    border-radius: 12px !important;
    color: #ffffff !important;
    cursor: pointer !important;
    display: inline-block !important;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial,
        sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 2.5 !important;
    outline: transparent !important;
    padding: 0 1rem !important;
    text-align: center !important;
    text-decoration: none !important;
    transition: box-shadow 0.2s ease-in-out !important;
    user-select: none !important;
    -webkit-user-select: none !important;
    touch-action: manipulation !important;
    white-space: nowrap !important;
}

.button-63:not([disabled]):focus {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5),
        -0.125rem -0.125rem 1rem rgba(255, 166, 0, 0.5),
        0.125rem 0.125rem 1rem rgba(207, 35, 35, 0.5);
}

.button-63:not([disabled]):hover {
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.5),
        -0.125rem -0.125rem 1rem rgba(250, 84, 7, 0.5),
        0.125rem 0.125rem 1rem rgba(244, 33, 5, 0.5);
}
</style>
