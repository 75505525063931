import { ref, computed } from 'vue'

export const collapsed = ref(true)
export function toggleSidebar() {
  if (collapsed.value) {
    collapsed.value = false; 
  } else {
    collapsed.value = true; 
  }
}

export const SIDEBAR_WIDTH = 250
export const SIDEBAR_WIDTH_COLLAPSED = 60

export const sidebarWidth = computed(
  () => `${collapsed.value ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH}px`
)