import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import CompanyRegisterView from '@/views/CompanyRegisterView.vue'
import CompanyDashboard from '@/views/CompanyDashboard.vue'
import CompanyIsletmeler from '@/views/companyviews/CompanySidebarLinks/CompanyIsletmeler.vue'
import CompanyRandevular from '@/views/companyviews/CompanySidebarLinks/CompanyRandevular.vue'
import CompanyIsletmem from '@/views/companyviews/Isletmeler/CompanyIsletmem.vue'
import CompanyGorseller from '@/views/companyviews/Isletmeler/CompanyGorseller.vue'
import CalismaSaatleri from '@/views/companyviews/Isletmeler/CalismaSaatleri.vue'
import CompanyHizmetler from '@/views/companyviews/CompanySidebarLinks/CompanyHizmetler.vue'
import CompanyPersoneller from '@/views/companyviews/CompanySidebarLinks/CompanyPersoneller.vue'
import CompanyRaporlar from '@/views/companyviews/CompanySidebarLinks/CompanyRaporlar.vue'
import CompanyAyarlar from '@/views/companyviews/CompanySidebarLinks/CompanyAyarlar.vue'
import CompanyAnasayfa from '@/views/companyviews/CompanySidebarLinks/CompanyAnasayfa.vue'
import IsletmeninPersonelleri from '@/views/companyviews/Personeller/IsletmeninPersonelleri.vue'
import IsletmeninRaporlari from '@/views/companyviews/Raporlar/IsletmeninRaporlari.vue'
import CompanyAyarlarGenel from '@/views/companyviews/Ayarlar/CompanyAyarlarGenel.vue'
import CompanyAyarlarSifre from '@/views/companyviews/Ayarlar/CompanyAyarlarSifre.vue'
import CompanyAyarlarInfo from '@/views/companyviews/Ayarlar/CompanyAyarlarInfo.vue'
import CompanyAyarlarSosyal from '@/views/companyviews/Ayarlar/CompanyAyarlarSosyal.vue'
import CompanyAyarlarBildirimler from '@/views/companyviews/Ayarlar/CompanyAyarlarBildirimler.vue'
import CompanyMesajlar from '@/views/companyviews/CompanySidebarLinks/CompanyMesajlar.vue'
import MusterininMesajlari from '@/views/companyviews/Mesajlar/MusterininMesajlari.vue'
import IsletmeEkle from '@/views/companyviews/Isletmeler/IsletmeEkle.vue'
import BusinessPage from '@/views/CustomerCompany/CompanyPage/BusinessPage.vue'
import IsletmeBilgileri from '@/views/CustomerCompany/CompanyPage/BusinessLinks/IsletmeBilgileri.vue'
import HizmetBilgileri from '@/views/CustomerCompany/CompanyPage/BusinessLinks/HizmetBilgileri.vue'
import KonumBilgileri from '@/views/CustomerCompany/CompanyPage/BusinessLinks/KonumBilgileri.vue'
import PersonelBilgileri from '@/views/CustomerCompany/CompanyPage/BusinessLinks/PersonelBilgileri.vue'
import YorumlarSayfasi from '@/views/CustomerCompany/CompanyPage/BusinessLinks/YorumlarSayfasi.vue'
import HizmetEkle from '@/views/companyviews/Hizmetler/HizmetEkle.vue'
import HizmetinDetaylari from '@/views/companyviews/Hizmetler/HizmetinDetaylari.vue'
import PersonelEkle from '@/views/companyviews/Personeller/PersonelEkle.vue'
import ShopsView from '@/views/CustomerCompany/CompanyPage/ShopsView.vue'
import CompanyYorumlar from '@/views/companyviews/CompanySidebarLinks/CompanyYorumlar.vue'
import IstatistiklerView from '@/views/companyviews/Istatistikler/IstatistiklerView.vue'
import CustomerLogin from '@/views/CustomerLoginSignUp/CustomerLogin.vue'
import CustomerRegisters from '@/views/CustomerLoginSignUp/CustomerRegisters.vue'
import RandevuPage from '@/views/CustomerCompany/CompanyPage/RandevuPage.vue'
let routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/company/login',
    name: 'CompanyLogin',
    component: LoginView
  },
  {
    path: '/appointments/login',
    name: 'CustomerLogin',
    component: CustomerLogin
  },
  {
    path: '/appointments/register',
    name: 'CustomerRegisters',
    component: CustomerRegisters
  },
  {
    path: '/appointments',
    name: 'Shops',
    component: ShopsView
  },
  {
    path: '/appointments/:id/create',
    component: RandevuPage,
    name: 'RandevuPage'
  },
  {
    path: '/appointments/:id',
    name: 'BusinessPage',
    component: BusinessPage,
    children: [
      {
        path: 'isletmebilgileri',
        component: IsletmeBilgileri,
        name: 'IsletmeBilgileri'
      },
      {
        path: 'hizmetbilgileri',
        component: HizmetBilgileri,
        name: 'HizmetBilgileri'
      },
      {
        path: 'konumbilgileri',
        component: KonumBilgileri,
        name: 'KonumBilgileri'
      },
      {
        path: 'personelbilgileri',
        component: PersonelBilgileri,
        name: 'PersonelBilgileri'
      },
      {
        path: 'yorumlar',
        component: YorumlarSayfasi,
        name: 'YorumlarSayfasi'
      },
      
      

    ]
  },
  {
    path: '/company/register',
    name: 'CompanyRegister',
    component: CompanyRegisterView
  },
  {
    path: '/company',
    name: 'CompanyDashboard',
    component: CompanyDashboard,
    children: [
      {
        path: '',
        component: CompanyAnasayfa,
      },
      {
        path: 'statistics',
        component: IstatistiklerView,
        name: 'IstatistiklerView'
      },
      {
        path: 'companies',
        component: CompanyIsletmeler,
      },
      {
        path: 'appointments',
        component: CompanyRandevular,
        name: 'CompanyRandevular'
      },
      {
        path: 'messages',
        component: CompanyMesajlar,
        name: 'CompanyMesajlar'
      },
      {
        path: 'companies/detail/:id',
        component: CompanyIsletmem,
        name: 'CompanyIsletmem'
      },
      {
        path: 'companies/add',
        component: IsletmeEkle,
        name: 'IsletmeEkle'
      },
      {
        path: 'companies/working-hours/:id',
        component: CalismaSaatleri,
        name: 'CalismaSaatleri'
      },
      {
        path: 'companies/media/:id',
        component: CompanyGorseller,
        name: 'CompanyGorseller'
      },
      {
        path: 'services',
        component: CompanyHizmetler,
        name: 'CompanyHizmetler'
      },
      {
        path: 'staffs',
        component: CompanyPersoneller,
        name: 'CompanyPersoneller'
      },
      {
        path: 'staffs/detail/:id',
        component: IsletmeninPersonelleri,
        name: 'IsletmeninPersonelleri'
      },
      {
        path: 'messages/chat/:id',
        component: MusterininMesajlari,
        name: 'MusterininMesajlari'
      },
      {
        path: 'services/add',
        component: HizmetEkle,
        name: 'HizmetEkle',
      },
      {
        path: 'staffs/add',
        component: PersonelEkle,
        name: 'PersonelEkle',
      },
      {
        path: 'services/detail/:id',
        component: HizmetinDetaylari,
        name: 'HizmetinDetaylari'
      },
     
      {
        path: 'personeller/isletmeninraporlari/:id',
        component: IsletmeninRaporlari,
        name: 'IsletmeninRaporlari'
      },
      {
        path: 'comments',
        component: CompanyYorumlar,
        name: 'CompanyYorumlar'
      },
      {
        path: 'reports',
        component: CompanyRaporlar,
        name: 'CompanyRaporlar'
      },
      {
        path: 'settings',
        component: CompanyAyarlar,
        name: 'CompanyAyarlar',
        children:[
          {
            path: '',
            component: CompanyAyarlarGenel,
            name: 'CompanyAyarlarGenel'
          },
          {
            path: 'password',
            component: CompanyAyarlarSifre,
            name: 'CompanyAyarlarSifre'
          },
          {
            path: 'info',
            component: CompanyAyarlarInfo,
            name: 'CompanyAyarlarInfo'
          },
          {
            path: 'social-media',
            component: CompanyAyarlarSosyal,
            name: 'CompanyAyarlarSosyal'
          },
          {
            path: 'notifications',
            component: CompanyAyarlarBildirimler,
            name: 'CompanyAyarlarBildirimler'
          },
        ]
      },
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
