<template>
  <div class="container">
    <div
      class="card cardgorseller"
      :class="{ 'is-dragging': isDragging }"
      @dragover="onDragOver"
      @dragleave="onDragleave"
      @drop="onDrop"
    >
      <div class="top">
        <p>Resim Yükleyin</p>
      </div>
      <input
        type="file"
        @change="onFileChange"
        accept="image/*"
        multiple
        style="display: none;"
        ref="fileInput"
      />
      <button @click="triggerFileInput">Resim Seç</button>
      <div v-for="(image, index) in images" :key="index" class="image-preview">
        <img :src="image.mediaUrl" :alt="(image.index + 1) + '.Görsel'" class="preview-img"/>
        <button @click="removeImage(image.id)">Kaldır</button>
      </div>

      <el-dialog v-model="dialogVisible">
        <img v-if="dialogImageUrl" :src="dialogImageUrl" alt="Preview Image" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ElNotification } from "element-plus";
import axios from "axios";
import '@/views/companyviews/add/css/CompanyGorseller.css';

export default {
  name: 'CompanyGorseller',
  data() {
    return {
      companyId:  this.$route.params.id,
      images: [],
      isDragging: false,
      dialogVisible: false,
      dialogImageUrl: '',
    };
  },
  mounted() {

    this.getImages();
  },
  methods: {
          getImages(){
            if(this.companyId == null){
               ElNotification({
                title: "Hata",
                message: "Şirket bilgileri alınamadı",
                type: "error",
                position: "top-right",
               });
                return;
            }
            axios.get(`/company/media/get?companyId=` + this.companyId, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
    
        }).then(response => {
          this.images = [];
          this.images = response.data.data;
          console.log(this.images);
        }).catch(() => {
          ElNotification({
            title: "Hata",
            message: "Resimler yüklenirken bir hata oluştu",
            type: "error",
            position: "top-right",
          });
        });
},
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      const index = fileList.indexOf(file);
      if (index !== -1) {
        fileList.splice(index, 1);
        // Burada API isteği yapabilirsiniz
        this.deleteImage(file.id); // Örnek bir API çağrısı
      }
    },
    deleteImage(imageId) {
      axios.delete(`/api/deleteimage/${imageId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then(() => {
        ElNotification({
          title: "Başarılı",
          message: "Resim başarıyla silindi",
          type: "success",
          position: "top-right",
        });
      }).catch(() => {
        ElNotification({
          title: "Hata",
          message: "Resim silinirken bir hata oluştu",
          type: "error",
          position: "top-right",
        });
      });
    },
    onFileChange(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.uploadFile(files[i]);
      }
    },
    onDragOver(event) {
      event.preventDefault();
      this.isDragging = true;
      event.dataTransfer.dropEffect = "copy";
    },
    onDragleave(event) {
      event.preventDefault();
      this.isDragging = false;
    },
    onDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      const files = event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        this.uploadFile(files[i]);
      }
    },
    uploadFile(file) {
      if (file.type.split("/")[0] !== "image") {
        ElNotification({
          title: "Hata",
          message: "Yalnızca resim dosyaları yüklenebilir",
          type: "error",
          position: "top-right",
        });
        return;
      }

      const formData = new FormData();
      formData.append('Files', file);
      formData.append('companyId', this.companyId);

      axios.post('/company/media/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        if (response.data.status !== "success") {
          ElNotification({
            title: "Hata",
            message: response.response.data.message,
            type: "error",
            position: "top-right",
          });
        } else {
          this.getImages();
          ElNotification({
            title: "Başarılı",
            message: "Resim başarıyla yüklendi",
            type: "success",
            position: "top-right",
          });
        }
      }).catch(response => {
        ElNotification({
          title: "Hata",
          message: "Resim yükleme sırasında bir hata oluştu" + response.response.data.message,
          type: "error",
          position: "top-right",
        });
      });
    },
    removeImage(index) {
      console.log(index);
      axios.get(`/company/media/delete?id=` + index, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }          
        }).then(response => {
          if(response.data.status === "success"){
            ElNotification({
              title: "Başarılı",
              message: "Resim başarıyla silindi",
              type: "success",
              position: "top-right",
            });
            this.getImages();
          }else{
            ElNotification({
              title: "Hata",
              message: "Resim silinirken bir hata oluştu. " + response.data.message,
              type: "error",
              position: "top-right",
            });
          }
        }).catch(response => {
          ElNotification({
            title: "Hata",
            message: "Resimler yüklenirken bir hata oluştu" + response.data.message,
            type: "error",
            position: "top-right",
          });
        });
    }
  }
};
</script>

<style scoped>
.is-dragging {
  border: 2px dashed #409EFF;
}
.image-preview {
  display: inline-block;
  margin: 5px;
}
.preview-img {
  width: 100px;
  height: 100px;
}
</style>
