<template>
  <div>
      <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo01">

          <router-link class="navbar-brand" to="/">
            <img src="../assets/images/Randevu.png" style="max-height: 40px;margin-bottom: 10px;position: static;"
              class="ms-lg-3 ms-sm-auto " alt="">
          </router-link>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0" style="margin-top: 10px;">
            <li class="nav-item m-auto">
              <router-link to="/" class="nav-link active">Anasayfa</router-link>
            </li>
            <li class="nav-item m-auto">
              <a class="nav-link" href="#">Hakkımızda</a>
            </li>
          </ul>
          <div class="col">
            <form class="d-flex justify-content-end" role="search">
              <router-link
                to="/appointments/login"
                type="button"
                class="ms-auto ms-lg-0 m-1 button-62"
                >Giriş</router-link
              >              
              <router-link
                to="/appointments/login"
                type="button"
                class="me-auto me-lg-0 m-1  button-63"
                >Kayıt Ol</router-link
              >
            </form>
          </div>

        </div>
      </div>
    </nav>
    <div>
      <navbar-modal :data="modalWindow" ></navbar-modal>
    </div>
  </div>
</template>

<script>
import NavbarModal from '@/components/NavbarModal.vue'
// import axios from 'axios'

export default {
  name: "NavbarComponent",
  components: {
    NavbarModal
  },
  data() {
    return {
      modalWindow: null,
      musteriler:[]
    }
  },
  props: [],
  created() {
      // axios.get('test/get')
      //   .then((res)=>{
          
      //     console.log(res.data)
      //   })
  },

};

</script>

<style scoped>

</style>
