<template>
  <div class="card">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-search"></i>
      </span>
      <InputText v-model="search" placeholder="Hizmet Ara" />
      
    </div>
    <DataTable 
      rowHover="true"
      :value="filteredHizmetler" 
      paginator 
      :rows="5" 
      :rowsPerPageOptions="[5, 10, 20, 50]" 
      responsiveLayout="scroll" 
      class="p-mt-3">
      <Column field="name" header="Hizmet" sortable></Column>
      <Column field="price" header="Fiyat" sortable></Column>
    </DataTable>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const search = ref('');
const hizmetler = ref([
  { name: 'Saç Kesimi', price: '50 TL' },
  { name: 'Saç Yıkama', price: '20 TL' },
  { name: 'Sakal Kesimi', price: '30 TL' },
  { name: 'Boyama', price: '100 TL' },
  // Diğer hizmetler buraya eklenebilir
]);

const filteredHizmetler = computed(() => {
  if (!search.value) return hizmetler.value;
  return hizmetler.value.filter((hizmet) =>
    hizmet.name.toLowerCase().includes(search.value.toLowerCase())
  );
});


</script>

<script>
export default{
  name: "HizmetlerTable"
}
</script>

<style scoped>
.p-inputgroup {
  margin-bottom: 1rem;
}
</style>
