<template>
    <div class="w-100 d-flex justify-content-center register-page" style="padding-top: 4vh">
      <div class="col-12 col-sm-9 col-md-6 col-lg-5 col-xl-4">
        <div class="text-center">
          <router-link to="/"><img src="../assets/images/Randevu.png" alt="randevuburada.com logo" style="width: 200px" class="m-4" /></router-link>
        </div>
        
        <div>
          <el-steps :space="200" :active="activeTab" simple>
            <el-step title="Profil" :icon="User" />
            <el-step title="Firma" :icon="OfficeBuilding" />
            <el-step title="Ek Bilgiler" :icon="Document" />
          </el-steps>
        </div>
        <div class="register-card mt-3">
          <div v-if="activeTab == 1">
            <div class="mb-3">
              <label class="mb-2">Adınız<span class="text-danger">*</span></label>
              <el-input
                size="large"
                :style="error.user_name > 0 ? '--el-border-color: red' : ''"
                v-model="user_name"
                placeholder="Ad"
                @keypress.enter="step(2)"
              />
              <label class="mt-1 mb-0 text-danger" v-if="error.user_name > 0">İsim zorunludur!</label>
            </div>
            <div class="mb-3">
              <label class="mb-2">Soyadınız<span class="text-danger">*</span></label>
              <el-input
                size="large"
                :style="error.user_surname > 0 ? '--el-border-color: red' : ''"
                v-model="user_surname"
                placeholder="Soyad"
                @keypress.enter="step(2)"
              />
              <label class="mt-1 mb-0 text-danger" v-if="error.user_surname > 0">Soyisim zorunludur!</label>
            </div>
            <div class="mb-3">
              <label class="mb-2">Kullanıcı Adınız<span class="text-danger">*</span></label>
              <el-input
                size="large"
                :style="error.user_name > 0 ? '--el-border-color: red' : ''"
                v-model="user_last_name"
                placeholder="Kullanıcı Adı"
                @keypress.enter="step(2)"
              />
              <label class="mt-1 mb-0 text-danger" v-if="error.user_name > 0">Kullanıcı Adı zorunludur!</label>
            </div>
            <div class="mb-3">
              <label class="mb-2">Telefon Numaranız<span class="text-danger">*</span></label>
              <div class="d-flex">
                <el-input
                  size="large"
                  :style="error.user_phone > 0 ? '--el-border-color: red' : ''"
                  v-model="user_phone"
                  v-mask="'+90 (5##) ### ## ##'"
                  placeholder="+90 (5##) ### ## ##"
                  @keypress.enter="step(2)"
                />
              </div>
              <label class="mt-1 mb-0 text-danger" v-if="error.user_phone > 0">Telefon no zorunludur!</label>
            </div>
  
            <div class="mb-3">
              <label class="mb-2">E-Posta Adresiniz<span class="text-danger">*</span></label>
              <el-input
                size="large"
                :style="error.user_email > 0 ? '--el-border-color: red' : ''"
                v-model="user_email"
                placeholder="ornek@mail.com"
                autocomplete="off"
                @keypress.enter="step(2)"
              />
              <label class="mt-1 mb-0 text-danger" v-if="error.user_email > 0">Eposta adresi zorunludur!</label>
            </div>
  
            <div class="mb-3">
              <label class="mb-2">Şifreniz<span class="text-danger">*</span></label>
              <el-input
                size="large"
                :style="error.user_password > 0 ? '--el-border-color: red' : ''"
                v-model="user_password"
                type="password"
                show-password
                placeholder="Şifre"
                autocomplete="off"
                @keypress.enter="step(2)"
              />
  
              <label class="mt-1 mb-0 text-danger" v-if="error.user_password > 0">Şifreniz uyumsuz/güvensiz!</label>
            </div>
  
            <div class="mb-3">
              <label class="mb-2">Şifrenizi Doğrulayın<span class="text-danger">*</span></label>
              <el-input
                size="large"
                :style="error.user_password_confirmation > 0 ? '--el-border-color: red' : ''"
                v-model="user_password_confirmation"
                type="password"
                show-password
                placeholder="Şifre Tekrar"
                autocomplete="off"
                @keypress.enter="step(2)"
              />
              <label class="mt-1 mb-0 text-danger" v-if="error.user_password_confirmation > 0"
                >Şifreniz uyumsuz/güvensiz!</label
              >
            </div>
            <div class="d-flex w-100 justify-content-end">
              <el-button type="primary" style="width: 93.65px;height: 32px;" class="mb-3" @click="step(2)">Devam Et</el-button>
            </div>
          </div>
          <div v-if="activeTab == 2">
            <div class="mb-3">
              <label class="">İşletme Adı<span class="text-danger">*</span></label>
              <el-input
                size="large"
                :style="error.comp_name > 0 ? '--el-border-color: red' : ''"
                v-model="comp_name"
                placeholder="Firma Adı"
                @keypress.enter="step(3)"
              />
              <label class="mt-1 mb-0 text-danger" v-if="error.comp_name > 0">Firma adı zorunludur!</label>
            </div>
            <div class="mb-3">
              <label class="">TCKN / VKN<span class="text-danger">*</span></label>
              <el-input
                size="large"
                :style="error.user_tc > 0 ? '--el-border-color: red' : ''"
                v-mask="'###########'"
                v-model="user_tc"
                placeholder="TCKN / VKN"
                @keypress.enter="step(3)"
                autocomplete="off"
              />
              <label class="mt-1 mb-0 text-danger" v-if="error.user_tc > 0">TCKN / VKN zorunludur!</label>
            </div>
            <div class="mb-3">
              <label class="">Vergi Dairesi<span class="text-danger">*</span></label>
              <el-input
                v-model="firma_vergi_dairesi"
                filterable
                remote
                reserve-keyword
                placeholder="Vergi Dairesi Seçiniz"
                :loading="loading"
                clearable
                size="large"
                class="w-100"
                @keypress.enter="step(3)"
                :style="error.firma_vergi_dairesi > 0 ? '--el-border-color: red' : ''"
              >
                <el-option v-if="vergiDaireleri.length <= 0" disabled value="Veri bulunamadı" />
                <el-option v-for="item in vergiDaireleri" :key="item.id" :label="item.vergi_daire" :value="item.id" />
            </el-input>
              <label class="mt-1 mb-0 text-danger" v-if="error.firma_vergi_dairesi > 0">Vergi Dairesi zorunludur!</label>
            </div>
            <div class="d-flex w-100 justify-content-between">
              <el-button type="info" style="width: 93.65px;height: 32px;" class="mb-3" @click="activeTab = 1">Geri</el-button>
              <el-button type="primary" style="width: 93.65px;height: 32px;" class="mb-3" @click="step(3)">Devam Et</el-button>
            </div>
          </div>
          <div v-if="activeTab == 3">
            <div class="mb-4">
              <h4 class="text-primary mb-4">Paketler</h4>
              <!-- <el-radio-group v-model="gidenId" class="d-flex overflow-auto flex-nowrap justify-content-around">
                <el-radio v-for="p in paketSecim" :key="p" :label="p.id" size="large" border class="me-2 mb-3"
                  >{{ p.name_basic }}
                </el-radio>
              </el-radio-group> -->
              <label class="mt-1 mb-0 text-danger" v-if="error.gidenId > 0">Lütfen paket seçiniz.</label>
              <el-row>
                <el-col :span="24">
                  <el-card
                    :body-style="{ padding: '16px' }"
                    class="cursor-pointer"
                    :class="gidenId == 1 ? 'borderChange' : ''"
                    @click="gidenId = 1"
                  >
                  <div class="ribbon-2">Önerilen</div>
                    <img src="../assets/images/basic.png" class="image" width="170" />
                    <div style="padding: 14px">
                      <h6 class="text-center">499.99 ₺</h6>
                      <div class="d-flex flex-column justify-content-center align-items-center">
                        <div class="mb-4 primaryBg">
                          Basi Seviye Paket
                        </div>
                        <a href="https://randevuburada.com/" target="_blank" class="btn btn-secondary">Detaylı bilgi</a>
                      </div>
                    </div>
                  </el-card>
                </el-col>
                <el-col :span="24">
                  <el-card
                    :body-style="{ padding: '16px' }"
                    class="cursor-pointer"
                    :class="gidenId == 3 ? 'borderChange' : ''"
                  >
                    <img src="../assets/images/grow.png" class="image" width="170" />
                    <div style="padding: 14px">
                      <h6 class="text-center">2,999.99 ₺</h6>
                      <div class="d-flex flex-column justify-content-center align-items-center">
                        <div class="mb-4 primaryBg">
                          Yapım aşamasında...
                        </div>
                        <a href="/#" target="_blank" style="pointer-events: none;" class="btn btn-secondary" disabled>Detaylı bilgi</a>
                      </div>
                    </div>
                  </el-card>
                </el-col>
                <el-col :span="24">
                  <el-card
                    :body-style="{ padding: '16px' }"
                    class="cursor-pointer"
                    :class="gidenId == 3 ? 'borderChange' : ''"
                  >
                    <img src="../assets/images/plus.png" class="image" width="170" />
                    <div style="padding: 14px">
                      <h6 class="text-center">4,999.99 ₺</h6>
                      <div class="d-flex flex-column justify-content-center align-items-center">
                        <div class="mb-4 primaryBg">
                          Yapım aşamasında...
                        </div>
                        <a href="/#" target="_blank" style="pointer-events: none;" class="btn btn-secondary" disabled>Detaylı bilgi</a>
                      </div>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </div>
          
            <div class="d-flex w-100 justify-content-between">
              <el-button type="info" style="width: 93.65px;height: 32px;" class="mb-3" @click="activeTab = 2">Geri</el-button>
              <el-button type="success" class="mb-3" @click="postStep3()"> Kayıt ol</el-button>
            </div>
          </div>
          <el-divider> Üyeliğiniz var mı? </el-divider>
          <button class="w-100 my-2 ml-0 button-63" @click="giris()">Giriş Yap</button>
        </div>
      </div>
      <el-dialog v-if="dialogVisible == true" draggable v-model="dialogVisible" width="500px">
        
        <div class="dangerBg">
          {{ this.user_phone }} numaralı telefona bir doğrulama kodu ilettik. Doğrulama kodunuzu aşağıya giriniz veya kod
          alamadıysanız "Tekrar Gönder" seçeneğine tıklayınız.
        </div> 
        <div class="pin-div mt-4">
          <v-otp-input
            v-model="otp_code"
            ref="otpInput"
            input-classes="otp-input"
            separator="-"
            :num-inputs="6"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-change="handleOnChange"
            @on-complete="handleOnComplete"
          />
        </div>
        
        <template #footer>
          <span class="dialog-footer">
            <div class="d-flex justify-content-between">
              <div>
                <el-button type="warning" plain :disabled="newSend">Tekrar Gönder</el-button>
                <span class="ms-2 text-primary" v-if="newSend">{{ countDown }}</span>
              </div>
               <el-button @click="dialogVisible = false">Kapat</el-button> 
              <el-button type="primary" @click="newRegisterOTP()">Gönder</el-button>
            </div>
          </span>
        </template>
      </el-dialog>
    </div>
  </template>
  

  <script>
  import axios from "axios";
  // import md5 from "md5";
    import { mask } from "vue-the-mask";
    import profiles from "@/info/tables.js";
    import VOtpInput from "vue3-otp-input";
    import { User, OfficeBuilding, Document } from "@element-plus/icons-vue";
    import { ElSteps, ElStep, ElInput, ElButton, ElNotification, ElDivider } from "element-plus";
  
  export default {
    directives: { mask },
    components: {
      ElSteps,
      ElStep,
      ElInput,
      ElButton,
      ElDivider,
      VOtpInput,
    },
    data() {
      return {
        User,
        Document,
        OfficeBuilding,
        profiles,
        activeTab: 1,
        user_name: "",
        user_surname: "",
        user_last_name: "",
        user_email: "",
        user_phone: "",
        user_password: "",
        user_password_confirmation: "",
        user_tc: "",
        comp_name: "",
        otp_code: "",
        comp_id: null,
        firma_vergi_dairesi: "",
        firma_adresi: "",
        description: "",
        promocode: "",
        paketSecim: "",
        vergiDaireleri: [],
        gidenId: "",
        newSend: false,
        countDown: 120,
        parameters: {},
        token: "",
        userId: "",
        user: {},
        dialogVisible: false,
        error: {
          user_name: 0,
          user_surname: 0,
          user_last_name: 0,
          user_email: 0,
          user_phone: 0,
          user_password: 0,
          user_password_confirmation: 0,
          firma_vergi_dairesi: 0,
          comp_name: 0,
          promocode: 0,
          gidenId: 0,
        },
      };
    },
    methods: {
      newRegisterOTP() {
        if (this.otp_code != "") {
          ElNotification({
            title: "Hata",
            message: "Doğrulama kodu eksik girildi.",
            type: "error",
            position: "top-left",
          });
        } else {
          ElNotification({
            title: "Hata",
            message: "Telefon doğrulaması tamamlandı!",
            type: "success",
            position: "top-left",
          });
          
          this.dialogVisible = false;
        }
      },
      async postStep1() {
        try {
          let paramsPostStep1 = {
            userName: this.user_last_name,
            firstName: this.user_name,
            lastName: this.user_surname,
            email: this.user_email,
            phoneNumber: this.user_phone,
            password: this.user_password,
            confirmPassword: this.user_password_confirmation,
          };
          const response = await axios.post("/account/company/register", paramsPostStep1);
          if (response.status === "200" || response.status === 200) {
            this.token = response.data.data.token;
            this.userId = response.data.data.user.userId;
            this.user = response.data.data.user;
            localStorage.setItem('token', this.token);
            localStorage.setItem('userId', this.userId);
            localStorage.setItem('user', JSON.stringify(this.user));
            this.activeTab = 2;
            this.dialogVisible = true;
          } else {
            this.activeTab = 1;
            ElNotification({
              title: "Hata",
              message: response.data.data,
              type: "error",
              position: "top-left",
            });
          }
        } catch (error) {
          this.activeTab = 1;
          console.error(error.response);

            ElNotification({
              title: "Hata",
              message: "Beklenmeyen bir hata oluştu.",
              type: "error",
              position: "top-left",
            });
          
        }
      },
      async postStep3() {
        try {
          const jwtToken = localStorage.getItem("token");        

          const config = {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          };

          const paramsPostStep3 = {
            userId: localStorage.getItem("userId"),
            companyPackageId: 1,
          };

          const response1 = await axios.post(
            "/company/subscribe/set",
            paramsPostStep3,
            config
          );

          if (response1.data.status === "success") {
              const paramsPostStep2 = {
              bankName: "Tanımsız",
              branchName: this.comp_name,
              accountNumber: "string",
              iban: "string",
              accountHolder: this.user_name + " " + this.user_last_name,
              ibanNumber: "string",
              taxNumber: this.user_tc,
              taxOffice: this.firma_vergi_dairesi,
              swiftCode: "string",
              userId: localStorage.getItem("userId"),
            };
            const response2 = await axios.post(
              "/company/bankingDetail/set",
              paramsPostStep2,
              config
            );

            if (response2.data.status === "success") {
              ElNotification({
                title: "Başarılı",
                message: "Kayıt işlemi başarılı bir şekilde tamamlandı. Mail adresinize doğrulama bağlantısı gönderildi!",
                type: "success",
                position: "top-left",
              });
              this.$router.push("/company");
              this.dialogVisible = true;
            } else {
              ElNotification({
                title: "Hata",
                message: response2.data.data,
                type: "error",
                position: "top-left",
              });
              this.activeTab = 2;
            }
          } else {
            ElNotification({
              title: "Hata",
              message: response1.data.data,
              type: "error",
              position: "top-left",
            });
            this.activeTab = 2;
          }
        } catch (error) {
          if(error.response.status === 401){
            ElNotification({
              title: "Hata",
              message: "Oturumunuzun süresi dolmuş. Lütfen tekrar kayıt işlemlerini yapınız.",
              type: "error",
              position: "top-left",
            });
            this.$router.push("company/login");
          }
          this.activeTab = 1;
          if (error.response && error.response.data && error.response.data.data) {
            ElNotification({
              title: "Hata",
              message: "Beklenmeyen bir hata oluştu: " + error.response.data.data,
              type: "error",
              position: "top-left",
            });
          } else {
            ElNotification({
              title: "Hata",
              message: "Beklenmeyen bir hata oluştu.",
              type: "error",
              position: "top-left",
            });
          }
        }
      },
      newRegister() {
        if (this.gidenId.length < 1) {
          ElNotification({
            title: "Hata",
            message: "Lütfen bir paket seçiniz",
            type: "error",
            position: "top-left",
          });
          this.activeTab = 3;
          this.error.gidenId = 1;
        } else {
          this.error.gidenId = 0;
        }
      },
      bosKontrol1() {
        var say = 1;
        if (this.user_name.length < 3 || this.user_last_name.length < 3) {
          ElNotification({
            title: "Hata",
            message: "Lütfen Adınızı ve Kullanıcı Adınızı giriniz.",
            type: "error",
            offset: 120 * say++,
            position: "top-left",
          });
          this.activeTab = 1;
          this.error.user_name = 1;
          this.error.user_last_name = 1;
        } else {
          this.error.user_name = 0;
          this.error.user_last_name = 0;
          this.activeTab = 2;
        }
        if (this.user_surname.length < 2) {
          ElNotification({
            title: "Hata",
            message: "Lütfen Soyadınızı giriniz.",
            type: "error",
            offset: 120 * say++,
            position: "top-left",
          });
          this.activeTab = 1;
          this.error.user_surname = 1;
        } else {
          this.error.user_surname = 0;
        }
        if (this.user_phone.length < 5) {
          ElNotification({
            title: "Hata",
            message: "Lütfen Telefon Numaranızı giriniz.",
            type: "error",
            offset: 120 * say++,
            position: "top-left",
          });
          this.activeTab = 1;
          this.error.user_phone = 1;
        } else {
          this.error.user_phone = 0;
        }
        if (!this.user_email?.includes("@") || !this.user_email?.includes(".")) {
          ElNotification({
            title: "Hata",
            message: "Lütfen Eposta adresinizi kontrol ediniz.",
            type: "error",
            offset: 120 * say++,
            position: "top-left",
          });
          this.activeTab = 1;
          this.error.user_email = 1;
        } else {
          this.error.user_email = 0;
        }
        if (this.user_password.length < 8 || this.user_password.length < 3) {
            ElNotification({
              title: "Hata",
              message: "Şifreniz en az 8 karakter olmalıdır.",
              type: "error",
              offset: 120 * say++,
              position: "top-left",
            });
            this.activeTab = 1;
            this.error.user_password = 1;
            this.error.user_password_confirmation = 1;
          } else if (!/\d/.test(this.user_password)) {
            ElNotification({
              title: "Hata",
              message: "Şifreniz en az bir rakam içermelidir.",
              type: "error",
              offset: 120 * say++,
              position: "top-left",
            });
            this.activeTab = 1;
            this.error.user_password = 1;
            this.error.user_password_confirmation = 1;
          } else if (!/[^\w\d]/.test(this.user_password)) {
            ElNotification({
              title: "Hata",
              message: "Şifreniz en az bir alfasayısal olmayan karakter içermelidir.",
              type: "error",
              offset: 120 * say++,
              position: "top-left",
            });
            this.activeTab = 1;
            this.error.user_password = 1;
            this.error.user_password_confirmation = 1;
          } else if (!/[A-Z]/.test(this.user_password)) {
            ElNotification({
              title: "Hata",
              message: "Şifreniz en az bir büyük harf içermelidir.",
              type: "error",
              offset: 120 * say++,
              position: "top-left",
            });
            this.activeTab = 1;
            this.error.user_password = 1;
            this.error.user_password_confirmation = 1;
          } else if (this.user_password_confirmation != this.user_password) {
          ElNotification({
            title: "Hata",
            message: "Şifreler uyuşmuyor!",
            type: "error",
            offset: 120 * say++,
            position: "top-left",
          });
          this.activeTab = 1;
          this.error.user_password_confirmation = 1;
        } else {
          this.error.user_password = 0;
          this.error.user_password_confirmation = 0;
        }
        if (
          this.error.user_name == 0 &&
          this.error.user_surname == 0 &&
          this.error.user_last_name == 0 &&
          this.error.user_email == 0 &&
          this.error.user_phone == 0 &&
          this.error.user_password == 0 &&
          this.error.user_password_confirmation == 0
        ) {
          this.postStep1();
          this.activeTab = 2;
        }
        else{
          this.activeTab = 1;
        }
        
      },
      bosKontrol2() {
        var say = 0;
        if (this.comp_name.length < 3) {
          ElNotification({
            title: "Hata",
            message: "Lütfen Firmanızın ismini giriniz!",
            type: "error",
            offset: 120 * say++,
            position: "top-left",
          });
          this.activeTab = 2;
          this.error.comp_name = 1;
        } else {
          this.error.comp_name = 0;
        }
        if (this.user_tc.length < 10) {
          ElNotification({
            title: "Hata",
            message: "Lütfen TCKN/VKN kontrol ediniz!",
            type: "error",
            offset: 120 * say++,
            position: "top-left",
          });
          this.activeTab = 2;
          this.error.user_tc = 1;
        } else {
          this.error.user_tc = 0;
        }
        if (this.error.user_tc == 0 && this.error.comp_name == 0) {
          this.activeTab = 3;
        }
        if (this.firma_vergi_dairesi == "") {
          ElNotification({
            title: "Hata",
            message: "Lütfen Vergi Dairesi seçiniz!",
            type: "error",
            offset: 120 * say++,
            position: "top-left",
          });
          this.activeTab = 2;
          this.error.firma_vergi_dairesi = 1;
        } else {
          this.error.firma_vergi_dairesi = 0;
        }
      },
      gidenIds(p) {
        if (p == 1) this.gidenId = 1;
        else if (p == 2) this.gidenId = 2;
        else if (p == 3) this.gidenId = 3;
      },
      sifreKontrol() {
        if (this.user_password.length < 8 || this.user_password.length < 3) {
          ElNotification({
            title: "Hata!",
            message: "Şifreniz en az 8 karakter olmalıdır.",
            type: "error",
            position: "top-left",
          });
          this.activeTab = 1;
          this.error.user_password = 1;
          this.error.user_password_confirmation = 1;
        } else if (this.user_password_confirmation != this.user_password) {
          ElNotification({
            title: "Hata!",
            message: "Girilen şifreler aynı değil.",
            type: "error",
            position: "top-left",
          });
          this.activeTab = 1;
          this.error.user_password_confirmation = 1;
        } else {
          this.error.user_password = 0;
          this.error.user_password_confirmation = 0;
        }
      },
      step(stepNo) {
        if (stepNo == 2) {
          this.bosKontrol1();
        } else if (stepNo == 3) {
          this.bosKontrol2();
        }
      },
      giris() {
        this.$router.push("login");
      },
    },
    created() {
    },
  };
</script>

  
  <style>
  .pin-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .pin-div input {
    font-size: 26px !important;
    width: 40px;
    text-align: center;
    height: 60px !important;
    border-radius: 4px !important;
    border: 1px solid #000 !important;
  }
  .pin-div input:focus-visible {
    outline: 0 !important;
    border: 1px solid #000 !important;
    border-radius: 4px !important;
  }
  .register-page {
    height: 100vh;
  }
  .register-card {
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
  }
  
  .dark .register-page {
    background: #18191b;
  }
  .dark .register-card {
    background: #1e2022;
  }
  .dark .el-divider__text {
    background: #1e2022;
  }
  
  .light .register-page {
    background: #edf1f3;
  }
  .light .register-card {
    background: #fff;
  }
  .light .el-divider__text {
    background: #fff;
  }
  .itemBadge {
    margin-top: 10px;
    margin-right: 40px;
  }
  .dangerBg {
    background-color: rgba(245, 108, 108, 0.1);
    padding: 8px 16px;
    border-radius: 4px;
    color: #000;
    border-left: 5px solid #f56c6c;
    margin: 20px 0;
    word-break: break-word;
  }
  .primaryBg {
    background-color: rgba(64, 158, 255, 0.1);
    padding: 8px 16px;
    border-radius: 4px;
    color: #000;
    border-left: 5px solid #409eff;
    margin: 20px 0;
    word-break: break-word;
  }
  .el-step__title,
  .el-divider__text {
    word-break: normal !important;
  }
  .el-scrollbar {
    max-width: 800px;
  }
  .text-danger {
    color: #f56c6c !important;
    font-weight: bold !important;
  }
  .borderChange {
    border: 1px solid #198754 !important;
    transform: scale(1.05);
  }
  .el-col {
    margin: 0.6rem 0 0.6rem 0;
  }
  .el-card:hover {
    border: 1px solid #198754 !important;
  }
  .el-card__body {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: center;
  }
  .ribbon-2 {
    --f: 10px; /* control the folded part*/
    --r: 15px; /* control the ribbon shape */
    --t: 10px; /* the top offset */
    
    position: absolute;
    inset: var(--t) calc(-1*var(--f)) auto auto;
    padding: 2px 20px var(--f) calc(10px + var(--r));
    clip-path: 
      polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 100%,
        calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
        var(--r) calc(50% - var(--f)/2));
    background: #409eff;
    box-shadow: 0 calc(-1*var(--f)) 0 inset #0005;
    color: #e5efee
  }
  
  .box {
    position:relative;
  }
  .button-63 {
    background: #ff914d !important;
    border: 0 !important;
    border-radius: 12px !important;
    color: #ffffff !important;
    cursor: pointer !important;
    display: inline-block !important;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial,
      sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 2.5 !important;
    outline: transparent !important;
    padding: 0 1rem !important;
    text-align: center !important;
    text-decoration: none !important;
    transition: box-shadow 0.2s ease-in-out !important;
    user-select: none !important;
    -webkit-user-select: none !important;
    touch-action: manipulation !important;
    white-space: nowrap !important;
  }

  .button-63:not([disabled]):focus {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5),
      -0.125rem -0.125rem 1rem rgba(255, 166, 0, 0.5),
      0.125rem 0.125rem 1rem rgba(207, 35, 35, 0.5);
  }
  
  .button-63:not([disabled]):hover {
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.5),
      -0.125rem -0.125rem 1rem rgba(250, 84, 7, 0.5),
      0.125rem 0.125rem 1rem rgba(244, 33, 5, 0.5);
  }
  </style>
  