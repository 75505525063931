<template>
  <div class="business-details">
    <el-card class="business-card">
      <template #header>
        <h2 class="business-name">{{ business.name }}</h2>
      </template>
      <div class="row">
        <p><strong>Puan:</strong> 
          <span class="rating">
            <el-rate v-model="business.rating" disabled :border-width="1" :colors="['#99A9BF', '#F7BA2A', '#FF9900']"></el-rate> {{ formatRating(business.rating) }}
          </span>
        </p>
        <p><strong>Açıklama:</strong> {{ business.description }}</p>
        <p><strong>Web Sitesi:</strong> <a :href="business.website" target="_blank" class="website-link">{{ business.website }}</a></p>
        
        <el-divider></el-divider>
        <h5 class="social-media-title d-inline">İşletmenin Sosyal Medya Hesapları</h5>
        <div class="d-inline">
          <div v-if="business.socialMedia.facebook" class="mx-2">
            <a :href="business.socialMedia.facebook" target="_blank" class="social-link">
              <i class="fab fa-facebook-square social-icon"></i> Facebook
            </a>
          </div>
          <div v-if="business.socialMedia.instagram" class="mx-2">
            <a :href="business.socialMedia.instagram" target="_blank" class="social-link">
              <i class="fab fa-instagram social-icon"></i> Instagram
            </a>
          </div>
          <div v-if="business.socialMedia.twitter" class="mx-2">
            <a :href="business.socialMedia.twitter" target="_blank" class="social-link">
              <i class="fab fa-twitter social-icon"></i> Twitter
            </a>
          </div>
        </div>
        <!-- Diğer sosyal medya hesapları buraya eklenecek -->
      </div>
    </el-card>
  </div>
</template>

<script>
import { ElCard, ElRate, ElDivider } from "element-plus";

export default {
  name: "IsletmeBilgileri",
  components: {
    ElCard,
    ElRate,
    ElDivider
  },
  data() {
    return {
      companyId: null,
      business: {
        name: "Erkek Kuaförü Hayri Ceylan",
        description: "İşletmenin açıklaması burada yer alacak.",
        website: "www.isletmeadi.com",
        rating: 4.4,
        socialMedia: {
          facebook: "Facebook Linki",
          instagram: "Instagram Linki",
          twitter: "Twitter Linki"
          // Diğer sosyal medya hesapları buraya eklenecek
        }
      }
    };
  },
  mounted() {
    this.companyId = this.$route.params.id;
  },
  methods: {
    formatRating(rating) {
      return `${rating.toFixed(1)}/5`;
    }
    
  }
};
</script>

<style scoped>
.business-card {
  width: 100%;
}

.business-details .social-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  margin-bottom: 10px;
}

.business-details .social-icon {
  margin-right: 10px;
  font-size: 20px;
}

.business-name {
  font-weight: bold;
  font-size: 24px;
}

.social-media-title {
  font-weight: bold;
  font-size: 18px;
}

.rating {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
}

.website-link {
  color: #ff914d;
  text-decoration: none;
}
.el-card{
  border: 0px !important;
}


</style>
