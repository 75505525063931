<template>
  <div class="card">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-search"></i>
      </span>
      <InputText v-model="search" placeholder="Personel Ara" />
    </div>
    <DataTable 
      row-hover="true"
      :value="filteredPersoneller" 
      paginator 
      :rows="5" 
      :rowsPerPageOptions="[5, 10, 20, 50]" 
      responsiveLayout="scroll" 
      class="p-mt-3">
      <Column field="name" header="Ad" sortable  style="width: 77%;"></Column>
      <Column field="status" header="Durum" bodyClass="status-column"  style="width: 23%;" sortable>
        <template #body="slotProps">
          <Tag :value="slotProps.data.status" :severity="getSeverity(slotProps.data.status)" rounded style="width: 25%;"/>
        </template>
        
      </Column>
      <!-- <Column field="services" header="Verilen Hizmetler" style="width: 33%;" sortable>
        <template #body="slotProps">
          <ul>
            <li v-for="(service, index) in slotProps.data.services" :key="index">{{ service }}</li>
          </ul>
        </template>
      </Column> -->
    </DataTable>
    
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import Tag from 'primevue/tag';

const search = ref('');
const personeller = ref([
  { name: 'Ahmet Yılmaz', status: 'Aktif', services: ['Saç Kesimi', 'Saç Boyama'] },
  { name: 'Mehmet Demir', status: 'Pasif', services: ['Sakal Kesimi'] },
  { name: 'Ayşe Kaya', status: 'Aktif', services: ['Manikür', 'Pedikür'] },
  { name: 'Fatma Öztürk', status: 'Pasif', services: ['Masaj'] },
  // Diğer personeller buraya eklenebilir
]);


const filteredPersoneller = computed(() => {
  if (!search.value) return personeller.value;
  return personeller.value.filter((personel) =>
    personel.name.toLowerCase().includes(search.value.toLowerCase())
  );
});

const getSeverity = (status) => {
  switch (status) {
    case 'Aktif':
      return 'success';
    case 'Pasif':
      return 'danger';
    default:
      return null;
  }
};
</script>

<script>
export default{
  name: "PersonelBilgileri"
}

</script>

<style scoped>
.p-inputgroup {
  margin-bottom: 1rem;
}

.status-column .p-tag {
  width: 100%;
  display: flex;
  justify-content: center;
}

.card ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
</style>
