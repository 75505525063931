<template>
  <div class="container mt-3 mb-3">
    <div class="row justify-content-center">
      <div class="col-md-11">
        <div class="card">
          <div class="card-header text-white" style="background-color: #ff914d">
            Şifre Değiştirme
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="currentPassword" class="form-label">Mevcut Şifre</label>
              <input id="currentPassword" type="password" class="form-control" placeholder="Mevcut şifrenizi girin" />
            </div>

            <div class="form-group">
              <label for="newPassword" class="form-label">Yeni Şifre</label>
              <input id="newPassword" type="password" class="form-control" placeholder="Yeni şifrenizi belirleyin" />
            </div>

            <div class="form-group">
              <label for="confirmNewPassword" class="form-label">Yeni Şifreyi Tekrar Girin</label>
              <input id="confirmNewPassword" type="password" class="form-control" placeholder="Yeni şifreyi tekrar girin" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyAyarlarSifre",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  border-bottom: none;
}
</style>
