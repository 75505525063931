<template>
  <div class="container mt-3 mb-3">
    <div class="row justify-content-center">
      <div class="col-md-11">
        <div class="card">
          <div class="card-header text-white" style="background-color: #ff914d">
            Kişisel Bilgiler
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="bio" class="form-label">Biyografi</label>
              <textarea id="bio" class="form-control" rows="5">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris nunc arcu, dignissim sit amet sollicitudin iaculis, vehicula id urna. Sed luctus urna nunc. Donec fermentum, magna sit amet rutrum pretium, turpis dolor molestie diam, ut lacinia diam risus eleifend sapien. Curabitur ac nibh nulla. Maecenas nec augue placerat, viverra tellus non, pulvinar risus.</textarea>
            </div>
            
          </div>
          <div class="card-header card-header-2 text-white" style="background-color: #ff914d">
            İletişim Bilgileri
          </div>
          <div class="card-body ">
            <div class="form-group">
              <label for="phone" class="form-label">Telefon</label>
              <input id="phone" type="text" class="form-control" value="+0 (123) 456 7891" />
            </div>
            <div class="form-group">
              <label for="website" class="form-label">Web Sitesi</label>
              <input id="website" type="text" class="form-control" placeholder="Web sitenizin adresini girin" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyAyarlarInfo",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  border-bottom: none;
}
.card-header-2 {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}
</style>
