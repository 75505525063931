<template>
  <div>
      <nav class="navbar navbar-expand-lg bg-body-tertiary shadow shadow-sm">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo01" >

          <router-link class="navbar-brand" to="/" >
            <img src="../assets/images/Randevu.png" style="max-height: 40px;margin-bottom: 10px;position: static;"
              class="ms-lg-3 ms-sm-auto " alt="">
          </router-link>
          <div class="d-flex" style="min-width: 100px;">

          </div>
          
          <ul class="navbar-nav me-auto mb-2 mb-lg-0" style="margin-top: 10px;">
            
          </ul>
          <ul class="nav ms-5 me-3">
            <li class="nav-item">
              <div class="m-1">
                <router-link to="" class="button-62 ">Anasayfa</router-link>
              </div>
              
            </li>
            <li class="nav-item">
              <div class="m-1">
                <router-link to="" class="button-62 ">Randevularım</router-link>
              </div>            </li>
            <li class="nav-item">
              <div class="m-1">
                <router-link to="" class="button-62 ">Mesajlarım</router-link>
              </div>            </li>
           
          </ul>
          <div class="d-flex">
            <form class="d-flex justify-content-end" role="search">
              <div class="text-center">
                    <form class="d-flex justify-content-end" role="search">
                        <div class="dropdown-center">
                            <a style="color: black;" class=" dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img :src=avatar class="img-thumbnail rounded rounded-4"  style="max-width: 60px;max-height: 60px" alt="">
                            </a>
                            <ul style="margin-left: -90px;" class="dropdown-menu float-start">
                                <li><a class="dropdown-item" href="#">Bildirimler</a></li>
                                <li><a class="dropdown-item" href="#">Ayarlar</a></li>
                                <li><a @click="cikisYap" class="dropdown-item" href="#">Çıkış</a></li>
                            </ul>
                        </div>

                    </form>
                </div>
            </form>
          </div>

        </div>
      </div>
    </nav>
    <div>
    </div>
  </div>
</template>



<script>

// import axios from 'axios'

export default {
  name: "NavbarCustomer",
  components: {
  },
  data() {
    return {
      modalWindow: null,
      musteriler:[],
      avatar:'https://randomuser.me/api/portraits/men/1.jpg'
    }
  },
  props: [],
  created() {
      // axios.get('test/get')
      //   .then((res)=>{
          
      //     console.log(res.data)
      //   })
  },

};

</script>

<style scoped>
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: black;
  text-decoration: none;
  background: none;
  border: 0;
  }
  .button-62:not([disabled]):focus {
    box-shadow: 0 0 0.25rem rgba(236, 142, 53, 0.5),
      -0.125rem -0.125rem 1rem rgba(255, 196, 88, 0.5),
      0.125rem 0.125rem 1rem rgba(255, 139, 31, 0.5);
  }
  
  .button-62:not([disabled]):hover {
    box-shadow: 0 0 0.25rem rgba(255, 148, 47, 0.5),
      -0.125rem -0.125rem 1rem rgba(224, 155, 26, 0.5),
      0.125rem 0.125rem 1rem rgba(255, 160, 71, 0.5);
  }
  .dropdown-item:active{
    background: white;
    color: black;
  }
  
</style>
