<template>
  <div class="container mt-3 mb-3">
    <div class="row justify-content-center">
      <div class="col-md-11">
        <div class="card genelgenislik">
          <div class="card-header text-white" style="background-color: #ff914d">
            Bildirimler
          </div>
          <div class="card-body pb-2 cardbildirimler">
            
            <div class="list-group">
              <a href="#" class="list-group-item list-group-item-action">
                Yeni bir mesajınız var.
              </a>
              <a href="#" class="list-group-item list-group-item-action">
                E-posta adresinizi onaylayın.
              </a>
              <a href="#" class="list-group-item list-group-item-action">
                Profiliniz güncellendi.
              </a>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyAyarlarBildirimler",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.genelgenislik {
  margin-bottom: 20px;
}

.cardbildirimler{
  max-height: 600px;
  overflow-y: auto;
}
</style>
