<template>
  <div class="container mt-3 mb-3">
    <div class="row justify-content-center">
      <div class="col-md-11">
        <div class="card">
          <div class="card-header text-white" style="background-color: #ff914d">
            Sosyal Bağlantılar
          </div>
          <div class="card-body">
            <div class="form-group">
              <label for="twitter" class="form-label">Twitter</label>
              <input id="twitter" type="text" class="form-control" value="https://twitter.com/user" />
            </div>
            <div class="form-group">
              <label for="facebook" class="form-label">Facebook</label>
              <input id="facebook" type="text" class="form-control" value="https://www.facebook.com/user" />
            </div>
            <div class="form-group">
              <label for="google" class="form-label">Google+</label>
              <input id="google" type="text" class="form-control" placeholder="Google+ profil bağlantınızı girin" />
            </div>
            <div class="form-group">
              <label for="linkedin" class="form-label">LinkedIn</label>
              <input id="linkedin" type="text" class="form-control" placeholder="LinkedIn profil bağlantınızı girin" />
            </div>
            <div class="form-group">
              <label for="instagram" class="form-label">Instagram</label>
              <input id="instagram" type="text" class="form-control" value="https://www.instagram.com/user" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyAyarlarSosyal",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  border-bottom: none;
}
</style>
