<template>
    <div class="w-100 d-flex justify-content-center login-page align-items-center">
      <div class="login-card col-12 col-sm-6 col-md-4 col-xl-3">
        <div class="w-100 d-flex align-items-center justify-content-center mb-4">
          <router-link to="/"><img src="../../assets/images/Randevu.png" alt="randevuburada.com logo" style="width: 200px" class="m-4" /></router-link>
        </div>
        <div class="form-signin w-100 m-auto">
          <div class="form-floating mb-2">
            <input
              type="email"
              class="form-control bE"
              :class="errorControl ? 'is-invalid' : ''"
              id="floatingInput"
              placeholder="Eposta Adresi"
              v-model="email"
              @keypress.enter="giris"
            />
            <label for="floatingInput">E-Posta, Kullanıcı Adı</label>
          </div>
          <div class="form-floating mb-1">
            <input
              :type="hides ? 'password' : 'text'"
              class="form-control bP"
              :class="errorControl ? 'is-invalid' : ''"
              id="floatingPassword"
              placeholder="Şifre"
              v-model="pass"
              @keypress.enter="giris"
            />
            <el-button
              v-if="pass?.length > 0"
              @click="hides = !hides"
              :icon="hides ? Hide : View"
              class="show-hide"
              link
            ></el-button>
            <label for="floatingPassword">Şifre</label>
          </div>
          <button class="w-100 my-2 ml-0 button-63" @click="giris" :loading="buttonLoading">Giriş</button>
          <el-divider class="text-nowrap">Üyeliğiniz yok mu?</el-divider>
          <button class="w-100 my-2 ml-0 button-62" @click="uyeOl">Üye Ol</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ElButton, ElDivider, ElNotification } from "element-plus";
  import { Hide, View } from "@element-plus/icons-vue";
  import axios from "axios";
  
  export default {
    name: "CustomerLogin",
    data() {
      return {
        email: "",
        pass: "",
        errorControl: false,
        hides: true,
        Hide,
        View,
        buttonLoading: false,
      };
    },
    methods: {
      giris() {
        this.buttonLoading = true;
        if (this.email == "" || this.pass == "") {
          ElNotification({
            title: "Uyarı",
            message: "E-Posta/Kullanıcı Adı ve Şifre boş geçilemez!",
            type: "info",
            position: "top-right",
          });
          this.errorControl = true;
          this.buttonLoading = false;
        } else {
          var params = {
            identifier: this.email,
            password: this.pass,
          };
          axios
            .post("/account/customer/login", params)
            .then(response => {
              ElNotification({
                message: "Giriş başarılı. Yönlendiriliyorsunuz...",
                type: "success",
                position: "top-right",
              });
              console.log(response.data.data)
              const token = response.data.data.token;
              const userId = response.data.data.user.userId;
              const user = response.data.data.user;
              localStorage.setItem('token', token);
              localStorage.setItem('userId', userId);
              localStorage.setItem('user', JSON.stringify(user));
              this.$router.push('/appointments');
            })
            .catch(error => {
              console.error(error.response.data.message);
              ElNotification({
                title: "Hata",
                message: error.response.data.message,
                type: "warning",
                position: "top-left",
              });
              this.errorControl = true;
            })
            .finally(() => {
              this.buttonLoading = false;
            });
          }
      },
      uyeOl() {
        this.$router.push("/appointments/register");
      },
    },
    components: {
      ElButton,
      ElDivider,
    },
    watch:{
      email() {
        this.errorControl = false;
      },
      pass() {
        this.errorControl = false;
      },
    },
  };
  </script>
  
  <style>
  .login-page {
    height: 100vh;
  }
  .login-card {
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
    min-width: 383px;
  }
  
  .dark .login-page {
    background: #18191b;
  }
  .dark .login-card {
    background: #1e2022;
  }
  .dark .el-divider__text {
    background: #1e2022;
  }
  
  .light .login-page {
    background: #edf1f3;
  }
  .light .login-card {
    background: #fff;
  }
  .light .el-divider__text {
    background: #fff;
  }
  .form-control:focus {
    box-shadow: none;
  }
  .show-hide {
    position: absolute;
    top: 18px;
    right: 18px;
    font-size: 18px;
  }
  
  .error-shadow {
    box-shadow: 0 0 0 1px var(--el-color-danger) inset;
  }
  
  .button-62 {
      background: #ffffff;
      border: 1px !important;
      border-style: solid !important;
      border-radius: 12px;
      color: #ff914d;
      cursor: pointer;
      display: inline-block;
      font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial,
        sans-serif;
      font-size: 16px !important;
      font-weight: 500 !important;
      line-height: 2.5 !important;
      outline: #ff914d !important;
      padding: 0 1rem !important;
      text-align: center;
      text-decoration: none;
      transition: box-shadow 0.2s ease-in-out;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      white-space: nowrap;
    }
    
    .button-62:not([disabled]):focus {
      box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5),
        -0.125rem -0.125rem 1rem rgba(255, 166, 0, 0.5),
        0.125rem 0.125rem 1rem rgba(207, 35, 35, 0.5);
    }
    
    .button-62:not([disabled]):hover {
      box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.5),
        -0.125rem -0.125rem 1rem rgba(250, 84, 7, 0.5),
        0.125rem 0.125rem 1rem rgba(244, 33, 5, 0.5);
    }
  </style>