<template>
<div>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo01">

                <router-link class="navbar-brand" to="/company">
                    <img src="../../assets/images/Randevu.png" style="max-height: 40px;margin-bottom: 10px;position: static;" class="ms-lg-3 ms-sm-auto " alt="">
                </router-link>
                <ul class="navbar-nav me-auto mb-2 mb-lg-0" style="margin-top: 10px;">

                    <!-- <li class="nav-item m-auto">
                    <div class="form-check d-inline-flex ms-2">
                    <input class="form-check-input p-2" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                    <label class="form-check-label ms-1" for="flexRadioDefault1">
                        Kuaför 
                    </label>
                    </div>
                    <div class="form-check d-inline-flex ms-2 ">
                    <input class="form-check-input p-2" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                    <label class="form-check-label ms-1" for="flexRadioDefault2">
                        Berber 
                    </label>
                    </div>

                </li> -->
                    <!-- <li @mouseleave="leaveButon" class="nav-item m-auto">
                    <button @mouseleave="ekleButon" @mouseover="ekleButon" class="btn btn-primary w-100 ms-4 ekleButon" ><i class="fa-solid fa-plus"></i> <Transition>
                        <span v-if="overEkleButon" o>Yeni İşletme Ekle</span>
                    </Transition></button>
                </li> -->

                </ul>
                <div class="text-center">
                    <form class="d-flex justify-content-end" role="search">
                        <div class="dropdown-center">
                            <a style="color: black;" class=" dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="../../assets/images/avatar/2.jpg" class="img-thumbnail rounded rounded-4" alt="">
                            </a>
                            <ul style="margin-left: -90px;" class="dropdown-menu float-start">
                                <li><router-link to="/company/messages" class="dropdown-item">Mesajlar</router-link></li>
                                <li><router-link to="/company/settings" class="dropdown-item">Ayarlar</router-link></li>
                                <li><router-link to="/company/login" @click="cikisYap" class="dropdown-item" href="#">Çıkış</router-link></li>
                            </ul>
                        </div>

                    </form>
                </div>

            </div>
        </div>
    </nav>

</div>
</template>

  
  
<script>
import { ElNotification } from 'element-plus';

export default {
    name: "CompanyNavbar",
    components: {
      
    },
    data() {
        return {
            overEkleButon: false
        }
    },
    props: {},
    created() {

    },
    methods: {
        ekleButon() {
            this.overEkleButon = true
        },
        leaveButon() {
            this.overEkleButon = false
        },
        cikisYap() {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('companyId');
            ElNotification({
              message: "Çıkış başarılı. Yönlendiriliyorsunuz...",
              type: "success",
              position: "top-right",
            });
            
        }
    }
};
</script>
  
  
<style scoped>
.ekleButon:hover {
    min-width: 100px !important;
    transition: 0.6s all linear !important;
}

.ekleButon {
    transition: 0.6s all linear !important;
    min-width: 30px;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
.dropdown-item:focus{
    background-color: white;
    color: black;
}
</style>
