<template>
  <div class="">
    <div class="">
      <side-bar/>
    </div>
    <company-navbar/>
    <router-view class="us__content" />
  </div>
 
</template>


<script>

import CompanyNavbar from '@/components/sidebar/CompanyNavbar.vue';
import SideBar from '@/components/sidebar/SideBar.vue'




export default {
  components: {
    SideBar,
    CompanyNavbar
  },
  setup() {
    return {  }
  }
}
</script>

<style scoped>

</style>