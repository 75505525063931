export default {
  hesap_bakiye_ozeti:
    "Cariniz ile aranızda gerçekleşen tüm para alış verişinin kaydı burada tutulmaktadır. Carinize özel filtrelenmiştir.",
  cari_hesap: "Alışveriş yaptığınız tüm firmaların ya da kişilerin kaydı burada tutulmaktadır.",
  kobi_siparis: "Satılan ya da alınan ürünlerin kaydı burada listelenmektedir.",
  adres:
    "Carinize ait adres kayıtları burada listelenmektedir. Fatura kesme işlemlerinde buradan seçtiğiniz adres bilgileri kullanılmaktadır!",
  hatirlatici: "Hatırlamak istediğiniz ya da unutmamak için aldığınız notlar özel filtrelemelerle burada listelenmektedir.",
  kobi_kasa: "Kasanıza özel olarak tüm Tahsilatlarınız ve Ödemeleriniz burada listelenmektedir.",
  kobi_banka: "Bankanıza özel olarak tüm Tahsilatlarınız ve Ödemeleriniz burada listelenmektedir.",
  e_fatura: "Resmi olarak kestiğiniz Faturalar burada listelenmektedir.",
  security: "En az 8 en fazla 24 haneli bir şifre oluşturabilirsiniz.",
  promo: "Size gönderilen 9 haneli kodunuzu giriniz.",
};
